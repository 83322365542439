import { service } from "./request-index"

const requestAll = async(instance , { cbSuccess , cbError }) => {
    try {
        const res = await instance
        if( res.status === 200 ) {
            cbSuccess(res)
        } else {
            cbError(res , "Error !")
        }
    } catch( e ) {
        console.log(e.message)
        cbError(null , e)
    }
}

export const requestItemsList = ({ bot_id, formData, cbSuccess , cbError }) => requestAll(
    service.post(`/cms/api/v1/${bot_id}/product/list`, formData) ,
    { cbSuccess , cbError }
)

export const requestItemsShow = ({ bot_id, id, cbSuccess , cbError }) => requestAll(
    service.get(`/cms/api/v1/${bot_id}/product/${id}`) ,
    { cbSuccess , cbError }
)

export const requestItemsCreate = ({ bot_id, formData, cbSuccess , cbError }) => requestAll(
    service.post(`/cms/api/v1/${bot_id}/product`, formData) ,
    { cbSuccess , cbError }
)

export const requestItemsUpdate = ({ bot_id, id, formData, cbSuccess , cbError }) => requestAll(
    service.put(`/cms/api/v1/${bot_id}/product/${id}`, formData) ,
    { cbSuccess , cbError }
)

export const requestItemsDelete = ({ bot_id, id, cbSuccess , cbError }) => requestAll(
    service.delete(`/cms/api/v1/${bot_id}/product/${id}`) ,
    { cbSuccess , cbError }
)

export const requestItemsUpdateSeq = ({ bot_id, id, formData, cbSuccess , cbError }) => requestAll(
    service.put(`/cms/api/v1/${bot_id}/product/sequence/${id}`, formData) ,
    { cbSuccess , cbError }
)