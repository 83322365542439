<template>
    <fluid-content currentPage="จัดการร้านค้า" :hasButton="true">
        <loading v-if="loading"/>
        <content-card title="จัดการร้านค้า" :hasButton="true">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <path d="M12,4.56204994 L7.76822128,9.6401844 C7.4146572,10.0644613 6.7840925,10.1217854 6.3598156,9.76822128 C5.9355387,9.4146572 5.87821464,8.7840925 6.23177872,8.3598156 L11.2317787,2.3598156 C11.6315738,1.88006147 12.3684262,1.88006147 12.7682213,2.3598156 L17.7682213,8.3598156 C18.1217854,8.7840925 18.0644613,9.4146572 17.6401844,9.76822128 C17.2159075,10.1217854 16.5853428,10.0644613 16.2317787,9.6401844 L12,4.56204994 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                        <path d="M3.28077641,9 L20.7192236,9 C21.2715083,9 21.7192236,9.44771525 21.7192236,10 C21.7192236,10.0817618 21.7091962,10.163215 21.6893661,10.2425356 L19.5680983,18.7276069 C19.234223,20.0631079 18.0342737,21 16.6576708,21 L7.34232922,21 C5.96572629,21 4.76577697,20.0631079 4.43190172,18.7276069 L2.31063391,10.2425356 C2.17668518,9.70674072 2.50244587,9.16380623 3.03824078,9.0298575 C3.11756139,9.01002735 3.1990146,9 3.28077641,9 Z M12,12 C11.4477153,12 11,12.4477153 11,13 L11,17 C11,17.5522847 11.4477153,18 12,18 C12.5522847,18 13,17.5522847 13,17 L13,13 C13,12.4477153 12.5522847,12 12,12 Z M6.96472382,12.1362967 C6.43125772,12.2792385 6.11467523,12.8275755 6.25761704,13.3610416 L7.29289322,17.2247449 C7.43583503,17.758211 7.98417199,18.0747935 8.51763809,17.9318517 C9.05110419,17.7889098 9.36768668,17.2405729 9.22474487,16.7071068 L8.18946869,12.8434035 C8.04652688,12.3099374 7.49818992,11.9933549 6.96472382,12.1362967 Z M17.0352762,12.1362967 C16.5018101,11.9933549 15.9534731,12.3099374 15.8105313,12.8434035 L14.7752551,16.7071068 C14.6323133,17.2405729 14.9488958,17.7889098 15.4823619,17.9318517 C16.015828,18.0747935 16.564165,17.758211 16.7071068,17.2247449 L17.742383,13.3610416 C17.8853248,12.8275755 17.5687423,12.2792385 17.0352762,12.1362967 Z" fill="#000000"/>
                    </g>
                </svg>
            </template>
            <template v-slot:right>
                <button type="button" class="btn btn-outline-secondary" @click="showTip">Tips</button>
            </template>
            <div class="card-body">
                <tab
                    navStyleClass="nav-tabs nav-tabs-line nav-bolder nav-tabs-line-2x mb-6"
                    :items="tabMenus"
                    v-model="tabActive"
                />
                <div class="row">
                    <div class="col-sm-12 col-lg-4">
                        <div class="form-group mb-0">
                            <label class="font-weight-medium"><span class="star">*</span>จำนวนเงิน (บาท)</label>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="ระบุจำนวนเงิน ..."
                                v-model="formData.sale_amount"
                                :class="{'is-invalid': formDirty && !formData.sale_amount}"
                            />
                            <div v-if="formDirty && !formData.sale_amount" class="invalid-feedback">*This field require</div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-lg-4">
                        <div class="form-group mb-0">
                            <label class="font-weight-medium">คะแนนที่ได้</label>
                            <div class="input-group">
                                <input
                                    type="number"
                                    class="form-control"
                                    placeholder="0"
                                    v-model="formData.point"
                                    :disabled="true"
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">คะแนน</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-dark-75 font-size-sm"><span class="text-danger mr-1">*</span>หมายเหตุ: ยกตัวอย่างเช่น จำนวนเงิน 100 บาท เท่ากับ 1 คะแนน</div>
                <div class="separator separator-solid mt-8"></div>
                <div class="mt-8">
                    <label class="font-weight-medium text-dark-75">คะแนนพิเศษเมื่อเข้าใช้งานระบบครั้งแรก</label>
                    <div class="row align-items-center">
                        <div class="col-sm-12 col-lg-2 offset-md-1">
                            <label class="checkbox">
                                <input type="checkbox" v-model="formData.is_special_point" @change="onSpecialPointChange"/> สิทธิ์การแลกต่อสมาชิก
                                <span></span>
                            </label>
                        </div>
                        <div class="col-sm-12 col-lg-5">
                            <div class="input-group">
                                <input
                                    onKeyUp="if(this.value<0){this.value='0';}"
                                    type="number"
                                    class="form-control"
                                    placeholder="0"
                                    v-model="formData.first_login_point"
                                    :disabled="!formData.is_special_point"
                                    :class="{'is-invalid': formDirty && formData.is_special_point && !formData.first_login_point ||
                                        formDirty && formData.is_special_point && formData.first_login_point == 0}"
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">คะแนน</span>
                                </div>
                            </div>
                            <div v-if="formDirty && formData.is_special_point && !formData.first_login_point ||
                                formDirty && formData.is_special_point && formData.first_login_point == 0" class="text-danger font-size-sm">*This field require</div>
                        </div>
                        <div class="col-sm-12 col-lg-5 offset-lg-3">
                            <input
                                type="test"
                                class="form-control"
                                placeholder="ระบุข้อความบันทัดที่ 1 ..."
                                v-model="formData.first_login_text_01"
                                :disabled="!formData.is_special_point"
                                :class="{'is-invalid': formDirty && formData.is_special_point && !formData.first_login_text_01 ||
                                    formDirty && formData.is_special_point && formData.first_login_text_01 == 0}"
                            />
                            <div v-if="formDirty && formData.is_special_point && !formData.first_login_text_01 ||
                                formDirty && formData.is_special_point && formData.first_login_text_01 == 0" class="text-danger font-size-sm">*This field require</div>
                        </div>
                        <div class="col-sm-12 col-lg-5 offset-lg-3">
                            <input
                                type="test"
                                class="form-control"
                                placeholder="ระบุข้อความบันทัดที่ 2 ..."
                                v-model="formData.first_login_text_02"
                                :disabled="!formData.is_special_point"
                                :class="{'is-invalid': formDirty && formData.is_special_point && !formData.first_login_text_02 ||
                                    formDirty && formData.is_special_point && formData.first_login_text_02 == 0}"
                            />
                            <div v-if="formDirty && formData.is_special_point && !formData.first_login_text_02 ||
                                formDirty && formData.is_special_point && formData.first_login_text_02 == 0" class="text-danger font-size-sm">*This field require</div>
                        </div>
                    </div>
                </div>
                <div class="text-dark-75 font-size-sm"><span class="text-danger mr-1">*</span>หมายเหตุ: คะแนนพิเศษจะให้ได้เพียงครั้งเดียว เมื่อสมาชิกกรอกข้อมูลและเข้าใช้งานระบบในครั้งแรก</div>
                <div class="separator separator-solid mt-8"></div>
                <div class="mt-8">
                    <label class="font-weight-medium text-dark-75"><span class="text-danger mr-1">*</span>การหมดอายุของคะแนน</label>
                    <div class="ml-10">
                        <radio
                            :list="expireOptions"
                            v-model="formData.expiration"
                            name="expire"
                        />
                    </div>
                </div>
            </div>
            <template v-slot:footer>
                <button type="button" class="btn btn-primary px-12" @click="onSubmit" :disabled="loading">บันทึก</button>
            </template>
        </content-card>
        <!-- Modal-->
        <div class="modal fade" id="tips" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header py-3 px-6">
                        <h5 class="modal-title text-dark-75 font-weight-medium" id="tips">Tips</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <i aria-hidden="true" class="ki ki-close"></i>
                        </button>
                    </div>
                    <div class="modal-body" style="max-height: 660px;">
                        <img class="w-100" src="@/assets/media/project-images/tips/คะแนน-01.png" alt="tips">
                    </div>
                </div>
            </div>
        </div>
    </fluid-content>
</template>

<script>
import { requestShopPointShow, requestShopPointUpdate } from "@/api/shop"

export default {
    data() {
        const profile = $cookies.get("ADMIN_PROFILE_DATA")
        return {
            userProfile : profile || {},
            loading: false,
            formDirty: false,
            tabActive : "คะแนน" ,
            tabMenus: [
                {
                    name: 'ข้อมูลร้านค้า',
                    link: '/loyalty/shop',
                },
                {
                    name: 'แบนเนอร์',
                    link: '/loyalty/shop/banner',
                },
                {
                    name: 'คะแนน',
                    link: '/loyalty/shop/point'
                }
            ],
            formData: {
                point: null,
                sale_amount: null,
                is_special_point: false,
                first_login_point: 1,
                first_login_text_01: '',
                first_login_text_02: '',
                expiration: '2 Year',
            },
            expireOptions: [
                {
                    value: '1 Year',
                    label: '1 ปีหลังจากเป็นสมาชิก',
                },
                {
                    value: '2 Year',
                    label: '2 ปีหลังจากเป็นสมาชิก',
                },
                {
                    value: 'No expiration',
                    label: 'ไม่มีหมดอายุ',
                },
            ]
        }
    },
    mounted() {
        if(this.userProfile.role_id === '1') {
            this.onLoad()
        }
        else {
            this.$router.push('/404')
        }
    },
    methods: {
        async onLoad() {
            this.loading = true
            await requestShopPointShow({
                bot_id: this.userProfile.bot_id,
                cbSuccess : res => {
                    this.formData = {
                        ...res.data.data,
                        first_login_point: res.data.data.first_login_point !== -1 ? res.data.data.first_login_point : 1,
                        is_special_point: res.data.data.first_login_point > 0 ? true : false,
                    }
                    this.loading = false
                } ,
                cbError : (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        } ,
        async onSubmit() {
            this.loading = true
            this.formDirty = true
            if (this.formData.is_special_point && !this.formData.first_login_point ||
                this.formData.is_special_point && this.formData.first_login_point == 0 ||
                this.formData.is_special_point && !this.formData.first_login_text_01 ||
                this.formData.is_special_point && !this.formData.first_login_text_02 ) {
                this.loading = false
                this.$toast.error('กรุณาระบุข้อมูล')
                return false
            }
            else {
                await requestShopPointUpdate({
                    bot_id: this.userProfile.bot_id,
                    formData : {
                        sale_amount: this.formData.sale_amount,
                        point: this.formData.point,
                        first_login_point: this.formData.is_special_point ? this.formData.first_login_point : -1,
                        expiration: this.formData.expiration,
                        first_login_text_01: this.formData.first_login_text_01,
                        first_login_text_02: this.formData.first_login_text_02
                    } ,
                    cbSuccess : res => {
                        this.formData.id = res.data.id
                        this.$toast.success("บันทึกสำเร็จ!")
                        this.onLoad(res.data.id)
                        this.loading = false
                        this.formDirty = false
                    } ,
                    cbError : (e , msg) => {
                        this.loading = false
                        this.$toast.error('ระบบขัดข้อง')
                        // this.$router.push("/error")
                    }
                })
            }
        },
        onSpecialPointChange(event) {
            if(!event.target.checked) {
                this.formData.first_login_point = 1
            }
        },
        showTip() {
            $('#tips').modal('show')
        },
    },
}
</script>