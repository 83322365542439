import { service } from "./request-index"

const requestAll = async(instance , { cbSuccess , cbError }) => {
    try {
        const res = await instance
        if( res.status === 200 ) {
            cbSuccess(res)
        } else {
            cbError(res , "Error !")
        }
    } catch( e ) {
        console.log(e)
        cbError(null , e)
    }
}

export const requestCouponList = ({ bot_id, formData, cbSuccess , cbError }) => requestAll(
    service.post(`/cms/api/v1/${bot_id}/coupon/list`, formData) ,
    { cbSuccess , cbError }
)

export const requestCouponShow = ({ bot_id, id, cbSuccess , cbError }) => requestAll(
    service.get(`/cms/api/v1/${bot_id}/coupon/${id}`) ,
    { cbSuccess , cbError }
)

export const requestCouponCreate = ({ bot_id, formData, cbSuccess , cbError }) => requestAll(
    service.post(`/cms/api/v1/${bot_id}/coupon`, formData) ,
    { cbSuccess , cbError }
)

export const requestCouponUpdate = ({ bot_id, id, formData, cbSuccess , cbError }) => requestAll(
    service.put(`/cms/api/v1/${bot_id}/coupon/${id}`, formData) ,
    { cbSuccess , cbError }
)

export const requestCouponDelete = ({ bot_id, id, cbSuccess , cbError }) => requestAll(
    service.delete(`/cms/api/v1/${bot_id}/coupon/${id}`) ,
    { cbSuccess , cbError }
)

export const requestCouponUpdateSeq = ({ bot_id, id, formData, cbSuccess , cbError }) => requestAll(
    service.put(`/cms/api/v1/${bot_id}/coupon/sequence/${id}`, formData) ,
    { cbSuccess , cbError }
)