<template>
    <div
        class="card-wrap"
        @mousemove="handleMouseMove"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
        ref="card"
        @click="$emit('click')"
    >
        <div class="card" :style="cardStyle">
            <div class="card-bg" :style="[cardBgTransform, cardBgImage]"></div>
            <div class="move-wrap handle" v-if="draggable">
                <i class="fas fa-expand-arrows-alt"></i>
            </div>
            <div class="card-info">
                <div class="line-clamp-1">
                    <slot name="header"></slot>
                </div>
                <div class="line-clamp-8">
                    <slot name="content"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['dataImage', 'draggable', 'cardHeight'],
    data() {
        return {
            width: 0,
            height: 0,
            mouseX: 0,
            mouseY: 0,
            mouseLeaveDelay: null
        }
    },
    mounted() {
        this.width = this.$refs.card.offsetWidth;
        this.height = this.$refs.card.offsetHeight;
    },
    computed: {
        mousePX() {
            return this.mouseX / this.width;
        },
        mousePY() {
            return this.mouseY / this.height;
        },
        cardStyle() {
            const rX = this.mousePX * 5;
            const rY = this.mousePY * -5;
            return {
                transform: `rotateY(${rX}deg) rotateX(${rY}deg)`,
                height: this.cardHeight ? this.cardHeight : '280px',
            };
        },
        cardBgTransform() {
            const tX = this.mousePX;
            const tY = this.mousePY;
            return {
                transform: `translateX(${tX}px) translateY(${tY}px)`
            }
        },
        cardBgImage() {
            return {
                backgroundImage: `url(${this.dataImage})`
            }
        }
    },
    methods: {
        handleMouseMove(e) {
            this.mouseX = e.pageX - this.$refs.card.offsetLeft - this.width/2;
            this.mouseY = e.pageY - this.$refs.card.offsetTop - this.height/2;
        },
        handleMouseEnter() {
            clearTimeout(this.mouseLeaveDelay);
        },
        handleMouseLeave() {
            this.mouseLeaveDelay = setTimeout(()=>{
                this.mouseX = 0;
                this.mouseY = 0;
            }, 1000);
        }
    }
}
</script>

<style lang="scss" scoped>
$hoverEasing: cubic-bezier(0.23, 1, 0.32, 1);
$returnEasing: cubic-bezier(0.445, 0.05, 0.55, 0.95);
.card-wrap {
    margin: 10px;
    transform: perspective(800px);
    transform-style: preserve-3d;
    cursor: pointer;

    // &:first-child {
    //     margin-left: 240px;
    // }
  
    &:hover {
        .card-info {
            transform: translateY(0);
        }
        .card-info p {
            opacity: 1;
        }
        .card-info, .card-info p {
            transition: 0.6s $hoverEasing;
        }
        .card-info:after {
            transition: 5s $hoverEasing;
            opacity: 1;
            transform: translateY(0);
        }
        .card-bg {
            transition: 
                0.6s $hoverEasing,
                opacity 5s $hoverEasing;
            opacity: 0.8;
        }
        .card {
            transition:
                0.6s $hoverEasing,
                box-shadow 2s $hoverEasing;
            box-shadow:
                rgba(white, 0.2) 0 0 40px 5px,
                rgba(white, 1) 0 0 0 1px,
                rgba(black, 0.66) 0 20px 30px 0,
                inset #333 0 0 0 5px,
                inset white 0 0 0 6px;
        }
    }
}

.card {
    position: relative;
    flex: 0 0 200px;
    width: 200px;
    background-color: #333;
    overflow: hidden;
    border-radius: 10px;
    box-shadow:
        rgba(black, 0.66) 0 20px 30px 0,
        inset #333 0 0 0 5px,
        inset rgba(white, 0.5) 0 0 0 6px;
    transition: 1s $returnEasing;
}

.card-bg {
    opacity: 0.5;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition:
        1s $returnEasing,
        opacity 5s 1s $returnEasing;
    pointer-events: none;
}

.card-info {
    padding: 20px;
    position: absolute;
    bottom: 0;
    color: #fff;
    transform: translateY(70%);
    transition: 0.6s 1.6s cubic-bezier(0.215, 0.61, 0.355, 1);
    
    p {
        opacity: 0;
        text-shadow: rgba(black, 1) 0 2px 3px;
        transition: 0.6s 1.6s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    
    * {
        position: relative;
        z-index: 1;
    }
    
    &:after {
        content: '';
        position: absolute;
        top: 0; left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to bottom, transparent 0%, rgba(#000, 0.6) 100%);
        background-blend-mode: overlay;
        opacity: 0;
        transform: translateY(100%);
        transition: 5s 1s $returnEasing;
    }
}

.card-info h1 {
    font-size: 24px;
    font-weight: 700;
    text-shadow: rgba(black, 0.5) 0 10px 10px;
}
.line-clamp-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
}
.line-clamp-8 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;  
}
.move-wrap {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
}
</style>