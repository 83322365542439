<template>
   <fluid-content currentPage="Loyalty" subMenu="สร้างรายการ" :hasButton="true">
        <loading v-if="loading"/>
        <content-card title="สร้างรายการ" :hasButton="true">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <path d="M4,9.67471899 L10.880262,13.6470401 C10.9543486,13.689814 11.0320333,13.7207107 11.1111111,13.740321 L11.1111111,21.4444444 L4.49070127,17.526473 C4.18655139,17.3464765 4,17.0193034 4,16.6658832 L4,9.67471899 Z M20,9.56911707 L20,16.6658832 C20,17.0193034 19.8134486,17.3464765 19.5092987,17.526473 L12.8888889,21.4444444 L12.8888889,13.6728275 C12.9050191,13.6647696 12.9210067,13.6561758 12.9368301,13.6470401 L20,9.56911707 Z" fill="#000000"/>
                        <path d="M4.21611835,7.74669402 C4.30015839,7.64056877 4.40623188,7.55087574 4.5299008,7.48500698 L11.5299008,3.75665466 C11.8237589,3.60013944 12.1762411,3.60013944 12.4700992,3.75665466 L19.4700992,7.48500698 C19.5654307,7.53578262 19.6503066,7.60071528 19.7226939,7.67641889 L12.0479413,12.1074394 C11.9974761,12.1365754 11.9509488,12.1699127 11.9085461,12.2067543 C11.8661433,12.1699127 11.819616,12.1365754 11.7691509,12.1074394 L4.21611835,7.74669402 Z" fill="#000000" opacity="0.3"/>
                    </g>
                </svg>
            </template>
            <template v-slot:right>
                <button type="button" class="btn btn-outline-secondary">Tips</button>
            </template>
            <div class="card-body mb-0">
                <div class="row">
                    <div class="col-sm-12 col-lg-6">
                        <div class="form-group mb-0">
                            <label class="font-weight-medium"><span class="star">*</span>ชื่อสินค้า</label>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="ระบุชื่อสินค้า ..."
                                v-model="formData.name"
                                :class="{'is-invalid': formDirty && !formData.name, 'is-valid': formDirty && formData.name }"
                            />
                            <div v-if="formDirty && !formData.name" class="invalid-feedback">*This field require</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-lg-6">
                        <div class="form-group">
                            <label class="font-weight-medium">สถานะ</label>
                            <div class="ml-10">
                                <radio
                                    :list="enableOptions"
                                    v-model="formData.is_active"
                                    name="homePage"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template v-slot:footer>
                <button type="button" class="btn btn-primary px-12" @click="onSubmit" :disabled="!validate.is_valid || loading">บันทึก</button>
            </template>
        </content-card>
   </fluid-content>
</template>

<script>
import { requestItemsShow, requestItemsCreate, requestItemsUpdate } from "@/api/items"

export default {
    data() {
        const profile = $cookies.get("ADMIN_PROFILE_DATA")
        return {
            userProfile : profile || {},
            formDirty: false,
            loading: false,
            formData: {
                id: 0,
                name: '',
                is_active: '1',
            },
            validate: {
                is_valid: false,
            },
            enableOptions: [
                {
                    value: '1',
                    label: 'ทำงาน (Enable)',
                },
                {
                    value: '0',
                    label: 'ไม่ทำงาน (Disabled)',
                },
            ]
        }
    },
    watch: {
        'formData': {
            handler(val){
                if (!val.name) {
                    this.validate.is_valid = false
                }
                else {
                    this.validate.is_valid = true
                }
            },
            deep: true
        }
    },
    mounted() {
        if(this.userProfile.role_id === '1') {
            if( this.$router.history.current.query.id ) {
                this.onLoad(this.$router.history.current.query.id)
            }
        }
        else {
            this.$router.push('/404')
        }
    },
    methods: {
        async onLoad(id) {
            this.loading = true
            await requestItemsShow({
                bot_id: this.userProfile.bot_id,
                id ,
                cbSuccess : res => {
                    this.formData = res.data.data
                    this.loading = false
                } ,
                cbError : (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        } ,
        async onSubmit() {
            this.loading = true
            this.formDirty = true
            if (!this.formData.name) {
                this.loading = false
                this.$toast.error('กรุณาระบุข้อมูล')
                return false
            }
            else {
                if( this.formData.id === 0 ) {
                    await requestItemsCreate({
                        bot_id: this.userProfile.bot_id,
                        formData : {
                            name: this.formData.name,
                            is_active: this.formData.is_active,
                        } ,
                        cbSuccess : res => {
                            this.formData.id = res.data.id
                            this.$toast.success("บันทึกสำเร็จ!")
                            this.onLoad(res.data.id)
                            this.$router.push(`/loyalty/items`)
                            this.loading = false
                            this.formDirty = false
                        } ,
                        cbError : (e , msg) => {
                            this.loading = false
                            this.$toast.error('ระบบขัดข้อง')
                            // this.$router.push("/error")
                        }
                    })
                }
                else {
                    const id = this.$router.history.current.query.id
                    await requestItemsUpdate({
                        bot_id: this.userProfile.bot_id,
                        id,
                        formData : {
                            name: this.formData.name,
                            is_active: this.formData.is_active,
                        } ,
                        cbSuccess : res => {
                            this.formData.id = res.data.id
                            this.$toast.success("อัพเดทเรียบร้อย!")
                            this.$router.push(`/loyalty/items`)
                            this.onLoad(res.data.id)
                            this.loading = false
                            this.formDirty = false
                        } ,
                        cbError : (e , msg) => {
                            this.loading = false
                            this.$toast.error('ระบบขัดข้อง')
                            // this.$router.push("/error")
                        }
                    })
                }
            }
        },
    },
}
</script>