<template>
    <div class="header-fixed header-mobile-fixed subheader-enabled subheader-fixed" style="height: 100%">
        <div class="d-flex flex-column flex-root" style="height: 100%">
            <div class="d-flex flex-row flex-column-fluid page">
                <div class="d-flex flex-column flex-row-fluid wrapper" :style="`padding-top: ${!hideMenu ? '80.5px' : '0px' }`">
                    <header-bar :hideBrand="hideBrand" v-if="!hideMenu" :hideBarDetail="hideBarDetail"/>
                    <div class="content d-flex flex-column flex-column-fluid pt-0" :style="`padding-bottom: ${!hideMenu ? '80.5px' : '0px' }`">
						<div class="container px-0">
                            <slot />
						</div>
					</div>
                    <div v-if="!hideFooter" class="fixed-bottom d-flex align-items-center py-5" style="border-top: 1px solid #EAEDF3;background-color: #FFF;">
                        <div class="d-flex align-items-center justify-content-center" style="width: 136px;">
                            <img class="w-100" src="@/assets/media/project-images/Yellow-Reward.jpg" />
                        </div>
                        <div class="d-flex align-items-center justify-content-start px-16">
                            <slot name="footer"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['hideMenu', 'hideFooter', 'hideBarDetail', 'hideBrand'],
}
</script>