<template>
    <fluid-content currentPage="จัดการร้านค้า" :hasButton="true">
        <loading v-if="loading"/>
        <content-card title="จัดการร้านค้า" :hasButton="true">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <path d="M12,4.56204994 L7.76822128,9.6401844 C7.4146572,10.0644613 6.7840925,10.1217854 6.3598156,9.76822128 C5.9355387,9.4146572 5.87821464,8.7840925 6.23177872,8.3598156 L11.2317787,2.3598156 C11.6315738,1.88006147 12.3684262,1.88006147 12.7682213,2.3598156 L17.7682213,8.3598156 C18.1217854,8.7840925 18.0644613,9.4146572 17.6401844,9.76822128 C17.2159075,10.1217854 16.5853428,10.0644613 16.2317787,9.6401844 L12,4.56204994 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                        <path d="M3.28077641,9 L20.7192236,9 C21.2715083,9 21.7192236,9.44771525 21.7192236,10 C21.7192236,10.0817618 21.7091962,10.163215 21.6893661,10.2425356 L19.5680983,18.7276069 C19.234223,20.0631079 18.0342737,21 16.6576708,21 L7.34232922,21 C5.96572629,21 4.76577697,20.0631079 4.43190172,18.7276069 L2.31063391,10.2425356 C2.17668518,9.70674072 2.50244587,9.16380623 3.03824078,9.0298575 C3.11756139,9.01002735 3.1990146,9 3.28077641,9 Z M12,12 C11.4477153,12 11,12.4477153 11,13 L11,17 C11,17.5522847 11.4477153,18 12,18 C12.5522847,18 13,17.5522847 13,17 L13,13 C13,12.4477153 12.5522847,12 12,12 Z M6.96472382,12.1362967 C6.43125772,12.2792385 6.11467523,12.8275755 6.25761704,13.3610416 L7.29289322,17.2247449 C7.43583503,17.758211 7.98417199,18.0747935 8.51763809,17.9318517 C9.05110419,17.7889098 9.36768668,17.2405729 9.22474487,16.7071068 L8.18946869,12.8434035 C8.04652688,12.3099374 7.49818992,11.9933549 6.96472382,12.1362967 Z M17.0352762,12.1362967 C16.5018101,11.9933549 15.9534731,12.3099374 15.8105313,12.8434035 L14.7752551,16.7071068 C14.6323133,17.2405729 14.9488958,17.7889098 15.4823619,17.9318517 C16.015828,18.0747935 16.564165,17.758211 16.7071068,17.2247449 L17.742383,13.3610416 C17.8853248,12.8275755 17.5687423,12.2792385 17.0352762,12.1362967 Z" fill="#000000"/>
                    </g>
                </svg>
            </template>
            <template v-slot:right>
                <button type="button" class="btn btn-outline-secondary" @click="showTip">Tips</button>
            </template>
            <div class="card-body">
                <tab
                    navStyleClass="nav-tabs nav-tabs-line nav-bolder nav-tabs-line-2x mb-6"
                    :items="tabMenus"
                    v-model="tabActive"
                />
                <div class="row">
                    <div class="col-sm-12 col-lg-6">
                        <div class="form-group mb-0">
                            <label class="font-weight-medium"><span class="star">*</span>ชื่อแบนเนอร์</label>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="ระบุชื่อแบนเนอร์ ..."
                                v-model="formData.name"
                                :class="{'is-invalid': formDirty && !formData.name, 'is-valid': formDirty && formData.name }"
                            />
                            <div v-if="formDirty && !formData.name" class="invalid-feedback">*This field require</div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-5 col-xl-4">
                        <div class="form-group mb-0">
                            <label class="font-weight-medium"><span class="star">*</span>รูปภาพ</label>
                            <upload-image-preview
                                size="1"
                                v-model="formData.img_url"
                                :previewWidth="isMobile() ? '100%' : '500px'"
                                previewHeight="150px"
                                @remove="formData.img_url = ''"
                            />
                            <div v-if="formDirty && !formData.img_url" class="text-danger font-size-sm">*This field require</div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-3 d-flex flex-column align-items-center ">
                        <!-- <span>Image dimension must be X:X ratio.</span> -->
                        <span>ขนาดแนะนำ 750 * 336 px</span>
                        <span>ขนาดไฟล์ไม่เกิน 1MB .png / .jpg</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-lg-6">
                        <div class="form-group">
                            <label class="font-weight-medium">สถานะ</label>
                            <div class="ml-10">
                                <radio
                                    :list="enableOptions"
                                    v-model="formData.is_active"
                                    name="homePage"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template v-slot:footer>
                <button type="button" class="btn btn-primary px-12" @click="onSubmit" :disabled="!validate.is_valid || loading">บันทึก</button>
            </template>
        </content-card>
        <!-- Modal-->
        <div class="modal fade" id="tips" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header py-3 px-6">
                        <h5 class="modal-title text-dark-75 font-weight-medium" id="tips">Tips</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <i aria-hidden="true" class="ki ki-close"></i>
                        </button>
                    </div>
                    <div class="modal-body" style="max-height: 660px;">
                        <img class="w-100" src="@/assets/media/project-images/tips/แบนเนอร์-01.png" alt="tips">
                    </div>
                </div>
            </div>
        </div>
    </fluid-content>
</template>

<script>
import UploadImagePreview from '@/components/UploadImagePreview'

import { requestBannerShow, requestBannerCreate, requestBannerUpdate } from "@/api/shop"

export default {
    components: {
        UploadImagePreview,
    },
    data() {
        const profile = $cookies.get("ADMIN_PROFILE_DATA")
        return {
            userProfile : profile || {},
            formDirty: false,
            loading: false,
            formData: {
                id: 0,
                name: '',
                img_url: '',
                is_active: '1',
            },
            validate: {
                is_valid: false,
            },
            enableOptions: [
                {
                    value: '1',
                    label: 'ทำงาน (Enable)',
                },
                {
                    value: '0',
                    label: 'ไม่ทำงาน (Disabled)',
                },
            ],
            tabActive : "แบนเนอร์" ,
            tabMenus: [
                {
                    name: 'ข้อมูลร้านค้า',
                    link: '/loyalty/shop',
                },
                {
                    name: 'แบนเนอร์',
                    link: '/loyalty/shop/banner',
                },
                {
                    name: 'คะแนน',
                    link: '/loyalty/shop/point'
                }
            ],
        }
    },
    watch: {
        'formData': {
            handler(val){
                if (!val.name ||
                    !val.img_url
                ) {
                    this.validate.is_valid = false
                }
                else {
                    this.validate.is_valid = true
                }
            },
            deep: true
        }
    },
    mounted() {
        if(this.userProfile.role_id === '1') {
            if( this.$router.history.current.query.id ) {
                this.onLoad(this.$router.history.current.query.id)
            }
        }
        else {
            this.$router.push('/404')
        }
    },
    methods: {
        async onLoad(id) {
            this.loading = true
            await requestBannerShow({
                bot_id: this.userProfile.bot_id,
                id ,
                cbSuccess : res => {
                    this.formData = res.data.data
                    this.loading = false
                } ,
                cbError : (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        } ,
        async onSubmit() {
            this.loading = true
            this.formDirty = true
            if (!this.formData.name ||
                !this.formData.img_url) {
                this.loading = false
                this.$toast.error('กรุณาระบุข้อมูล')
                return false
            }
            else {
                if( this.formData.id === 0 ) {
                    await requestBannerCreate({
                        bot_id: this.userProfile.bot_id,
                        formData : {
                            name: this.formData.name,
                            img_url: this.formData.img_url,
                            is_active: this.formData.is_active,
                        } ,
                        cbSuccess : res => {
                            this.formData.id = res.data.id
                            this.$toast.success("บันทึกสำเร็จ!")
                            this.onLoad(res.data.id)
                            this.$router.push(`/loyalty/shop/banner`)
                            this.loading = false
                            this.formDirty = false
                        } ,
                        cbError : (e , msg) => {
                            this.loading = false
                            this.$toast.error('ระบบขัดข้อง')
                            // this.$router.push("/error")
                        }
                    })
                }
                else {
                    const id = this.$router.history.current.query.id
                    await requestBannerUpdate({
                        bot_id: this.userProfile.bot_id,
                        id,
                        formData : {
                            name: this.formData.name,
                            img_url: this.formData.img_url,
                            is_active: this.formData.is_active,
                        } ,
                        cbSuccess : res => {
                            this.formData.id = res.data.id
                            this.$toast.success("อัพเดทเรียบร้อย!")
                            this.onLoad(res.data.id)
                            this.loading = false
                            this.formDirty = false
                        } ,
                        cbError : (e , msg) => {
                            this.loading = false
                            this.$toast.error('ระบบขัดข้อง')
                            // this.$router.push("/error")
                        }
                    })
                }
            }
        },
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        showTip() {
            $('#tips').modal('show')
        },
    },
}
</script>