<template>
    <div class="input-group" ref="datepickerRange">
        <input
            type="text"
            class="form-control"
            :placeholder="placeholder ? placeholder : 'Select date range'"
            :value="value.length > 0 ? value[0] + ' - ' + value[1] : ''"
            :class="{'is-invalid': formDirty && required && !value[0], 'is-valid': formDirty && required && value[0] }"
        >
        <div class="input-group-append">
            <span class="input-group-text"><i class="la la-calendar-check-o"></i></span>
        </div>
        <div v-if="formDirty && required && !value[0]" class="invalid-feedback">*This field require</div>
    </div>
</template>

<script>
export default {
    props: ['value', "formDirty", "placeholder", "required", "fotmat"],
    mounted() {
        this.init()
    },
    updated() {
        this.init()
    },
    methods: {
        init() {
            const ele =  $(this.$refs.datepickerRange).daterangepicker({
                buttonClasses: ' btn',
                applyClass: 'btn-primary',
                cancelClass: 'btn-secondary',
                timePicker: true,
                timePicker24Hour: true,
            }, (start, end, label) => {
                this.$emit('input', [start.format(this.fotmat ? this.fotmat : 'DD/MM/YYYY'), end.format(this.fotmat ? this.fotmat : 'DD/MM/YYYY')])
            });
        }
    },
}
</script>