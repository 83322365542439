import { requestOAList } from '@/api/apis'
import router from '@/router'

export default async function pageNotFound ({ next, store }){
    let OAList = []
    const profile = $cookies.get("ADMIN_PROFILE_DATA")

    await requestOAList({
        id: profile.mid,
        cbSuccess: res => {
            OAList = res.data.rows
        } ,
        cbError: () => {
            this.$toast.error('ระบบขัดข้อง')
            router.push("/error")
        }
    }) 
    if(OAList.length > 1) {
        router.push('/account')
    }
    else if(OAList.length > 0) {
        router.push('/loyalty/home')
    }
    else {
        return next()
    }
}