import { service } from "./request-index"

const requestAll = async (instance , { cbSuccess , cbError }) => {
    try {
        const res = await instance
        if( res.status === 200 ) {
            cbSuccess(res)
        } else {
            cbError(res , "Error !")
        }
    } catch( e ) {
        console.log(e)
        cbError(null , e)
    }
}

export const requestShopProfileCreate = ({ bot_id, formData, cbSuccess , cbError }) => requestAll(
    service.post(`/cms/api/v1/${bot_id}/shop-profile`, formData) ,
    { cbSuccess , cbError }
)

export const requestShopInfoShow = ({ bot_id, cbSuccess , cbError }) => requestAll(
    service.get(`/cms/api/v1/${bot_id}/setting/info`) ,
    { cbSuccess , cbError }
)

export const requestShopInfoUpdate = ({ bot_id, formData, cbSuccess , cbError }) => requestAll(
    service.put(`/cms/api/v1/${bot_id}/setting/info`, formData) ,
    { cbSuccess , cbError }
)

export const requestBannerList = ({ bot_id, formData, cbSuccess , cbError }) => requestAll(
    service.post(`/cms/api/v1/${bot_id}/setting/banner/list`, formData) ,
    { cbSuccess , cbError }
)

export const requestBannerShow = ({ bot_id, id, cbSuccess , cbError }) => requestAll(
    service.get(`/cms/api/v1/${bot_id}/setting/banner/${id}`) ,
    { cbSuccess , cbError }
)

export const requestBannerCreate = ({ bot_id, formData, cbSuccess , cbError }) => requestAll(
    service.post(`/cms/api/v1/${bot_id}/setting/banner`, formData) ,
    { cbSuccess , cbError }
)

export const requestBannerUpdate = ({ bot_id, id, formData, cbSuccess , cbError }) => requestAll(
    service.put(`/cms/api/v1/${bot_id}/setting/banner/${id}`, formData) ,
    { cbSuccess , cbError }
)

export const requestBannerDelete = ({ bot_id, id, cbSuccess , cbError }) => requestAll(
    service.delete(`/cms/api/v1/${bot_id}/setting/banner/${id}`) ,
    { cbSuccess , cbError }
)

export const requestBannerUpdateSeq = ({ bot_id, id, formData, cbSuccess , cbError }) => requestAll(
    service.put(`/cms/api/v1/${bot_id}/setting/banner/sequence/${id}`, formData) ,
    { cbSuccess , cbError }
)

export const requestShopPointShow = ({ bot_id, cbSuccess , cbError }) => requestAll(
    service.get(`/cms/api/v1/${bot_id}/setting/point`) ,
    { cbSuccess , cbError }
)

export const requestShopPointUpdate = ({ bot_id, formData, cbSuccess , cbError }) => requestAll(
    service.put(`/cms/api/v1/${bot_id}/setting/point`, formData) ,
    { cbSuccess , cbError }
)

export const requestCategoryParentList = ({ cbSuccess , cbError }) => requestAll(
    service.get(`/cms/api/v1/category/parent`) ,
    { cbSuccess , cbError }
)

export const requestCategoryChildList = ({ id, cbSuccess , cbError }) => requestAll(
    service.get(`/cms/api/v1/category/child/${id}`) ,
    { cbSuccess , cbError }
)