<template>
    <div class="header header-fixed" :class="{'header-minimize': sidebar}">
        <div class="container-fluid d-flex align-items-stretch justify-content-between">
            <div class="header-menu-wrapper header-menu-wrapper-left">
                <div class="d-flex align-items-center" v-if="!hideBrand">
                    <router-link to="/loyalty/home" class="symbol symbol-circle mr-5">
                        <img alt="Pic" src="https://cdn.yellow-idea.com/images/line-modular/5214c420-3b05-11eb-a542-036b9cbd1e78.jpeg"/>
                    </router-link>
                    <div class="d-flex flex-column">
                        <span class="font-size-lg text-dark-75 mb-1">LINE Display Name</span>
                    </div>
                </div>
                <router-link to="/account" class="d-flex align-items-center justify-content-center" style="width: 136px;" v-if="hideBarDetail">
                    <img class="w-100" src="@/assets/media/project-images/Yellow-Reward.jpg" />
                </router-link>
            </div>
            <div class="topbar" v-if="!hideBarDetail">
                <div class="topbar-item">
                    <div class="font-weight-normal px-6 mx-6 link-account" @click="selectAccount">เลือกรายชื่อบัญชีอื่น</div>
                </div>
                <div class="topbar-item">
                    <div @click="logout" class="text-dark font-size-lg text-hover-danger logout">Log out</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['showBrand', 'hideBarDetail', 'hideBrand'],
    data() {
        const profile = $cookies.get("ADMIN_PROFILE_DATA")
        return {
            userProfile : profile || {},
        }
    },
    computed: {
        sidebar() {
            return this.$store.state.sidebar;
        },
    },
    methods: {
        logout() {
            this.$cookies.remove("ADMIN_PROFILE_DATA")
            this.$router.push('/logout')
        },
        selectAccount() {
            delete this.userProfile.bot_id
            this.$cookies.set('ADMIN_PROFILE_DATA', JSON.stringify(this.userProfile), "3h")
            this.$router.push('/account')
        }
    },
}
</script>
<style>
.header-minimize {
    left: 70px !important;
}
.topbar-item .logout {
    cursor: pointer;
}
.topbar-item .link-account {
    border-right: 1px solid rgba(0, 0, 0, 0.5);
    cursor: pointer;
}
.topbar-item .link-account:hover {
    color: #27BA08;
}
</style>
