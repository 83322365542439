<template>
    <router-link :to="to" class="d-flex align-items-center">
         <div class="btn btn-icon btn-secondary mr-4">
            <i class="la la-plus-circle icon-lg"></i>
        </div>
        <span class="h5 text-dark mb-0">{{ label }}</span>
    </router-link>
</template>

<script>
export default {
    props: ['to', 'label']
}
</script>