import { serviceNodeRed, service } from "./request-index"

const requestAll = async(instance , { cbSuccess , cbError }) => {
    try {
        const res = await instance
        if( res.status === 200 ) {
            cbSuccess(res)
        } else {
            cbError(res , "Error !")
        }
    } catch( e ) {
        console.log(e)
        cbError(null , e)
    }
}

export const requestDashboardOverallDetail = ({ bot_id, formData, cbSuccess , cbError }) => requestAll(
    service.post(`/cms/api/v1/${bot_id}/dashboard/overall`, formData) ,
    { cbSuccess , cbError }
)

export const requestDashboardPointDetail = ({ bot_id, formData, cbSuccess , cbError }) => requestAll(
    service.post(`/cms/api/v1/${bot_id}/dashboard/point`, formData) ,
    { cbSuccess , cbError }
)

export const requestDashboardItemsDetail = ({ bot_id, formData, cbSuccess , cbError }) => requestAll(
    service.post(`/cms/api/v1/${bot_id}/dashboard/product`, formData) ,
    { cbSuccess , cbError }
)

export const requestDashboardCouponDetail = ({ bot_id, formData, cbSuccess , cbError }) => requestAll(
    service.post(`/cms/api/v1/${bot_id}/dashboard/coupon`, formData) ,
    { cbSuccess , cbError }
)