import { service } from "./request-index"

const requestAll = async(instance , { cbSuccess , cbError }) => {
    try {
        const res = await instance
        if( res.status === 200 ) {
            cbSuccess(res)
        } else {
            cbError(res , "Error !")
        }
    } catch( e ) {
        console.log(e)
        cbError(null , e)
    }
}

export const requestAdminList = ({ bot_id, formData, cbSuccess , cbError }) => requestAll(
    service.post(`/cms/api/v1/${bot_id}/admin/list`, formData) ,
    { cbSuccess , cbError }
)

export const requestAdminShow = ({ bot_id, id, cbSuccess , cbError }) => requestAll(
    service.get(`/cms/api/v1/${bot_id}/admin/${id}`) ,
    { cbSuccess , cbError }
)

export const requestAdminShowByMid = ({ bot_id, id, cbSuccess , cbError }) => requestAll(
    service.get(`/cms/api/v1/${bot_id}/admin/mid/${id}`) ,
    { cbSuccess , cbError }
)

export const requestAdminCreate = ({ bot_id, formData, cbSuccess , cbError }) => requestAll(
    service.post(`/cms/api/v1/${bot_id}/admin`, formData) ,
    { cbSuccess , cbError }
)

export const requestAdminDelete = ({ bot_id, id, cbSuccess , cbError }) => requestAll(
    service.delete(`/cms/api/v1/${bot_id}/admin/${id}`) ,
    { cbSuccess , cbError }
)

export const requestAdminInvite = ({ bot_id, formData, cbSuccess , cbError }) => requestAll(
    service.post(`/cms/api/v1/${bot_id}/admin/invite`, formData) ,
    { cbSuccess , cbError }
)

export const requestAdminInviteConfirm = ({ bot_id, formData, cbSuccess , cbError }) => requestAll(
    service.post(`/cms/api/v1/${bot_id}/admin/invite/confirm`, formData) ,
    { cbSuccess , cbError }
)