<template>
    <fluid-content currentPage="ประวัติการให้คะแนน">
        <loading v-if="loading"/>
        <content-card title="ประวัติการให้คะแนน">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24"/>
                        <path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                        <rect fill="#000000" x="6" y="11" width="9" height="2" rx="1"/>
                        <rect fill="#000000" x="6" y="15" width="5" height="2" rx="1"/>
                    </g>
                </svg>
            </template>
            <div class="p-8">
                <div class="mb-md-7">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-sm-12">
                            <datepicker-range v-model="searchDates" :required="false" />
                        </div>
                        <div class="col-lg-2 col-sm-12">
                            <input
                                type="tel"
                                maxLength="10"
                                class="form-control"
                                placeholder="เบอร์โทรศัพท์"
                                v-model="searchText"
                                @keypress="onNumberInput"
                            />
                        </div>
                        <div class="col-8 col-lg-2 mt-0">
                            <button type="button" class="btn btn-icon btn-light-primary" @click="getAll">
                                <i class="la la-search"></i>
                            </button>
                            <button type="button" class="btn btn-light ml-2" @click="clearFilter">
                                Clear filter
                            </button>
                        </div>
                        <div class="col-4 col-lg-4 text-right">
                            <button type="button" class="btn btn-primary" @click="expoerReport" :disabled="loading">
                                <i class="fas fa-file-download"></i>
                                Export
                            </button>
                        </div>
                    </div>
                </div>
                <div class="mb-7">
                    <data-table
                        :headers="headers"
                        :data="tableData"
                        :filter="formData"
                        @change="onTableChange"
                    >
                        <template v-slot:item.index="{ item }">
                            <span>
                                {{(tableData.rows.map(function(x) {return x.id; }).indexOf(item.id) + 1) + formData.offset}}
                            </span>
                        </template>
                        <template v-slot:item.point="{ item }">
                            <span class="pr-7">{{parseInt(item.point).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span>
                        </template>
                        
                         <template v-slot:item.submitter_name="{ item }">
                            <span>{{item.submitter_name || '-'}}</span>
                        </template>
                        <template v-slot:item.branch="{ item }">
                            <span>{{item.branch || '-'}}</span>
                        </template>
                        <template v-slot:item.phone_number="{ item }">
                            <span>{{item.phone_number || '-'}}</span>
                        </template>
                        <template v-slot:item.total_price="{ item }">
                            <span class="pr-7">{{parseInt(item.total_price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span>
                        </template>
                        <template v-slot:item.products="{ item }">
                            <div v-if="item.products[0].product_id">
                                <div v-for="(product, index) in item.products" :key="index" class="product-list">
                                    <span>- {{product.name}}</span>
                                    <span class="text-muted font-size-sm"> ({{product.price}} บาท)</span>
                                </div>
                            </div>
                            <span v-else>-</span>
                        </template>
                        <template v-slot:item.created_at="{ item }">
                            <span>{{formatDate(item.created_at)}}</span>
                        </template>
                        <template v-slot:item.type="{ item }">
                            <span v-if="item.type === 'Scan QR Code' || item.type === 'employee'">Scan QR Code</span>
                            <span v-else>ระบบหลังบ้าน</span>
                        </template>
                    </data-table>
                </div>
            </div>
        </content-card>
    </fluid-content>
</template>

<script>
import moment from 'moment'

import { requestPointReport, requestPointReportExport } from "@/api/report"

export default {
    data() {
        const profile = $cookies.get("ADMIN_PROFILE_DATA")
        return {
            userProfile : profile || {},
            loading: false,
            defaultImg: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
            searchDates: [],
            searchText: '',
            formData: {
                sort: "",
                order: "asc",
                offset: 0,
                limit: 10,
                filter: {
                    start_date: '',
                    end_date: '',
                }
            },
            tableData: {},
            headers: [
                {
                    text: '#',
                    align: 'center',
                    value: 'index',
                    width: 50,
                    sortable: false,
                },
                {
                    text: 'ชื่อพนักงาน',
                    align: 'start',
                    value: 'submitter_name',
                    width: 150,
                },
                {
                    text: 'สาขา',
                    value: 'branch',
                    width: 150,
                },
                {
                    text: 'ชื่อผู้ได้รับคะแนน',
                    value: 'user_name',
                    width: 150,
                },
                {
                    text: 'เบอร์โทรศัพท์',
                    value: 'phone_number',
                    width: 150,
                    sortable: false,
                },
                {
                    text: 'จำนวนคะแนนที่ให้',
                    value: 'point',
                    width: 150,
                    align: 'right'
                },
                {
                    text: 'จำนวนเงิน(บาท)',
                    value: 'total_price',
                    width: 150,
                    align: 'right'
                },
                {
                    text: 'รายการสินค้าที่ขาย',
                    value: 'products',
                    width: 200,
                },
                {
                    text: 'เลขที่ใบเสร็จอ้างอิง',
                    value: 'receipt_no',
                    width: 200,
                },
                {
                    text: 'ช่องทางที่ได้คะแนน',
                    value: 'type',
                    width: 160,
                },
                {
                    text: 'วันที่ / เวลา',
                    value: 'created_at',
                    width: 180,
                },
            ],
        }
    },
    async mounted() {
        if(this.userProfile.role_id === '1' || this.userProfile.role_id === '3') {
            await this.getAll()
        }
        else {
            this.$router.push('/404')
        }
    },
    methods: {
        onTableChange(offset, limit) {
            this.formData.offset = offset
            this.formData.limit = limit
            this.getAll()
        },
        clearFilter() {
            this.searchText = ''
            this.searchDates = []
            this.getAll()
        },
        async getAll() {
            this.loading = true
            this.formData.filter.phone_number = this.searchText
            this.formData.filter.start_date = this.searchDates[0] || ''
            this.formData.filter.end_date = this.searchDates[1] || ''
            await requestPointReport({
                bot_id: this.userProfile.bot_id,
                formData: this.formData,
                cbSuccess: res => {
                    this.tableData = res.data
                    this.loading = false
                } ,
                cbError: (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        },
        async expoerReport() {
            this.loading = true
            this.formData.filter.phone_number = this.searchText
            this.formData.filter.start_date = this.searchDates[0] || ''
            this.formData.filter.end_date = this.searchDates[1] || ''
            await requestPointReportExport({
                bot_id: this.userProfile.bot_id,
                formData: this.formData,
                cbSuccess: res => {
                    if(res.data.status === 'EXPORT_SUCCESS') {
                        window.open(res.data.url, '_blank')
                    }
                    this.loading = false
                } ,
                cbError: (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        },
        onNumberInput(event) {
            const key = window.event ? event.keyCode : event.which
            if (event.keyCode === 8 || event.keyCode === 46) {
		        return true
            }
            else if ( key < 48 || key > 57 ) {
               event.preventDefault()
            }
            else {
                return true
		    }
        },
        formatDate(date, type) {
            const newDate = moment(date.substring(0, 10)).format("DD/MM/YYYY")
            const newTime = date.substring(11, 16)
            if(type === 'date') {
                return newDate
            }
            else if (type === 'time') {
                return newTime
            }
            else {
                return newDate + ' ' + newTime
            }
        }
    }
}
</script>

<style scoped>
.product-list {
    margin-bottom: 8px;
}
.product-list:last-child {
    margin-bottom: 0
}
</style>