<template>
    <div>
        <div>
            <canvas ref="chart" height="400px"></canvas>
        </div>
        <div class="d-flex align-items-center pt-8">
            <div class="d-flex align-items-center mr-6" v-for="(data, index) in chartData.datasets" :key="index">
                <i class="fas fa-square pr-2" :style="`color: ${data.backgroundColor}; font-size: 15px;`"></i>
                <span>{{data.label}}</span>
            </div>
        </div>
    </div>
</template>

<script>
import Chart from 'chart.js'

export default {
    props: ['chartData', 'options'],
    mounted () {
        var ctx = this.$refs.chart
        var myChart = new Chart(ctx, {
            type: 'bar',
            data: this.chartData,
            options: this.options
        })
    },
}
</script>