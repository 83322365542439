import queryString from 'query-string'

import router from '@/router'

import { requestAdminInviteConfirm, requestAdminShowByMid } from '@/api/admin'

export default async function lineLogin ({ next }){
    const userProfile = queryString.parse(location.search.replace("?", ""))

    if(Object.keys(userProfile).length > 0) {
        $cookies.set('ADMIN_PROFILE_DATA', JSON.stringify(userProfile), "3h")
        checkProfile(userProfile)
    }
    else{
        const cookieProfile = $cookies.get("ADMIN_PROFILE_DATA")
        if(cookieProfile){
            checkProfile(cookieProfile)
        }
        else{
            window.location.href = `${process.env.VUE_APP_API_END_POINT}/cms/api/line/login?url=${process.env.VUE_APP_REDIRECT_URL}/&is_cms=1`
        }
    }
}

async function getAdminInfo (profile) {
    await requestAdminShowByMid({
        bot_id: profile.bot_id,
        id: profile.mid,
        cbSuccess: res => {
            $cookies.set('ADMIN_PROFILE_DATA', JSON.stringify({
                ...profile,
                role_id: res.data.data.role_id,
                name: res.data.data.name,
            }), "3h")
            if(res.data.data.role_id === '1') {
                router.push(`/loyalty/home`)
            }
            else if (res.data.data.role_id === '2') {
                router.push(`/loyalty/point`)
            }
            else if (res.data.data.role_id === '3') {
                router.push(`/loyalty/report/user`)
            }
        } ,
        cbError: (e , msg) => {
            router.push("/error")
        }
    })
}

async function checkProfile(profile) {
    if(profile.is_new === '1') {
        router.push('/register')
    }
    else {
        if (profile.flag === 'invite') {
            await requestAdminInviteConfirm({
                bot_id: profile.bot_id,
                formData: {
                    mid: profile.mid,
                    id: profile.code,
                },
                cbSuccess: res => {
                    if(res.data.status === "UPDATE_SUCCESS" || (res.data.status === "UPDATE_ERROR" && res.data.reason === "Duplicate mid")) {
                        delete profile.flag
                        $cookies.set('ADMIN_PROFILE_DATA', JSON.stringify(profile), "3h")
                        router.push('/account')
                    }
                    else {
                        router.push('/error')
                    }
                } ,
                cbError: (e , msg) => {
                    router.push("/error")
                }
            })
        }
        else {
            if (profile.bot_id) {
                getAdminInfo(profile)
            }
            else {
                $cookies.set('ADMIN_PROFILE_DATA', JSON.stringify(profile), "3h")
                router.push('/account')
            }
        }
    }
}