<template>
    <fluid-content currentPage="Loyalty" subMenu="หน้าหลัก" :hasButton="true">
        <loading v-if="loading"/>
        <content-card title="ข้อมูลร้านค้าของท่าน" :hasButton="true">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "/>
                        <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"/>
                    </g>
                </svg>
            </template>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-lg-4">
                        <div class="form-group mb-0">
                            <label class="font-weight-medium"><span class="star">*</span>จำนวนเงิน (บาท)</label>
                            <input
                                type="tel"
                                maxLength="10"
                                class="form-control"
                                placeholder="ระบุจำนวนเงิน ..."
                                v-model="formData.sale_amount"
                                :class="{'is-invalid': formDirty && !formData.sale_amount}"
                                @keypress="onNumberInput"
                            />
                            <div v-if="formDirty && !formData.sale_amount" class="invalid-feedback">*This field require</div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-lg-4">
                        <div class="form-group mb-0">
                            <label class="font-weight-medium">คะแนนที่ได้</label>
                            <div class="input-group">
                                <input
                                    type="number"
                                    class="form-control"
                                    placeholder="0"
                                    v-model="formData.point"
                                    :disabled="true"
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">คะแนน</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-dark-75 font-size-sm"><span class="text-danger mr-1">*</span>หมายเหตุ: ยกตัวอย่างเช่น จำนวนเงิน 100 บาท เท่ากับ 1 คะแนน</div>
                <div class="separator separator-solid mt-8"></div>
                <div class="mt-8">
                    <label class="font-weight-medium text-dark-75">คะแนนพิเศษเมื่อเข้าใช้งานระบบครั้งแรก</label>
                    <div class="row align-items-center">
                        <div class="col-sm-12 col-lg-2 offset-md-1">
                            <label class="checkbox">
                                <input type="checkbox" v-model="formData.is_special_point" @change="onSpecialPointChange"/> สิทธิ์การแลกต่อสมาชิก
                                <span></span>
                            </label>
                        </div>
                        <div class="col-sm-12 col-lg-5">
                            <div class="input-group">
                                <input
                                    onKeyUp="if(this.value<0){this.value='0';}"
                                    type="number"
                                    class="form-control"
                                    placeholder="0"
                                    v-model="formData.first_login_point"
                                    :disabled="!formData.is_special_point"
                                    :class="{'is-invalid': formDirty && formData.is_special_point && !formData.first_login_point ||
                                        formDirty && formData.is_special_point && formData.first_login_point == 0}"
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">คะแนน</span>
                                </div>
                            </div>
                            <div v-if="formDirty && formData.is_special_point && !formData.first_login_point ||
                                formDirty && formData.is_special_point && formData.first_login_point == 0" class="text-danger font-size-sm">*This field require</div>
                        </div>
                        <div class="col-sm-12 col-lg-5 offset-lg-3">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="ระบุข้อความบันทัดที่ 1 ..."
                                v-model="formData.first_login_text_01"
                                :disabled="!formData.is_special_point"
                                :class="{'is-invalid': formDirty && formData.is_special_point && !formData.first_login_text_01 ||
                                    formDirty && formData.is_special_point && formData.first_login_text_01 == 0}"
                            />
                            <div v-if="formDirty && formData.is_special_point && !formData.first_login_text_01 ||
                                formDirty && formData.is_special_point && formData.first_login_text_01 == 0" class="text-danger font-size-sm">*This field require</div>
                        </div>
                        <div class="col-sm-12 col-lg-5 offset-lg-3">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="ระบุข้อความบันทัดที่ 2 ..."
                                v-model="formData.first_login_text_02"
                                :disabled="!formData.is_special_point"
                                :class="{'is-invalid': formDirty && formData.is_special_point && !formData.first_login_text_02 ||
                                    formDirty && formData.is_special_point && formData.first_login_text_02 == 0}"
                            />
                            <div v-if="formDirty && formData.is_special_point && !formData.first_login_text_02 ||
                                formDirty && formData.is_special_point && formData.first_login_text_02 == 0" class="text-danger font-size-sm">*This field require</div>
                        </div>
                    </div>
                </div>
                <div class="text-dark-75 font-size-sm"><span class="text-danger mr-1">*</span>หมายเหตุ: คะแนนพิเศษจะให้ได้เพียงครั้งเดียว เมื่อสมาชิกกรอกข้อมูลและเข้าใช้งานระบบในครั้งแรก</div>
                <div class="separator separator-solid mt-8"></div>
                <div class="mt-8">
                    <label class="font-weight-medium text-dark-75"><span class="text-danger mr-1">*</span>การหมดอายุของคะแนน</label>
                    <div class="ml-10">
                        <radio
                            :list="expireOptions"
                            v-model="formData.expiration"
                            name="expire"
                        />
                    </div>
                </div>
            </div>
            <template v-slot:footer>
                <button type="button" class="btn btn-primary px-12" @click="onSubmit" :disabled="!validate.is_valid || loading">ถัดไป</button>
            </template>
        </content-card>
    </fluid-content>
</template>

<script>
import { requestShopPointUpdate } from "@/api/shop"

export default {
    data() {
        const profile = $cookies.get("ADMIN_PROFILE_DATA")
        return {
            userProfile : profile || {},
            loading: false,
            formDirty: false,
            formData: {
                point: 1,
                sale_amount: null,
                is_special_point: false,
                first_login_point: 1,
                first_login_text_01: 'ยินดีต้อนรับสมาชิกใหม่',
                first_login_text_02: 'คุณได้รับ คะแนนพิเศษ',
                expiration: '1 Year',
            },
            validate: {
                is_valid: false,
            },
            expireOptions: [
                {
                    value: '1 Year',
                    label: '1 ปีหลังจากเป็นสมาชิก',
                },
                {
                    value: '2 Year',
                    label: '2 ปีหลังจากเป็นสมาชิก',
                },
                {
                    value: 'No expiration',
                    label: 'ไม่มีหมดอายุ',
                },
            ]
        }
    },
    watch: {
        'formData': {
            handler(val){
                if (!val.sale_amount ||
                    val.is_special_point && !val.first_login_point ||
                    val.is_special_point && val.first_login_point == 0 ||
                    val.is_special_point && !val.first_login_text_01 ||
                    val.is_special_point && !val.first_login_text_02
                ) {
                    this.validate.is_valid = false
                }
                else {
                    this.validate.is_valid = true
                }
            },
            deep: true
        }
    },
    methods: {
        async onSubmit() {
            this.loading = true
            this.formDirty = true
            if (this.formData.is_special_point && !this.formData.first_login_point ||
                this.formData.is_special_point && this.formData.first_login_point == 0 ||
                this.formData.is_special_point && !this.formData.first_login_text_01 ||
                this.formData.is_special_point && !this.formData.first_login_text_02 ||
                !this.formData.sale_amount) {
                this.loading = false
                this.$toast.error('กรุณาระบุข้อมูล')
                return false
            }
            else {
                await requestShopPointUpdate({
                    bot_id: this.userProfile.bot_id,
                    formData : {
                        sale_amount: this.formData.sale_amount,
                        point: this.formData.point,
                        first_login_point: this.formData.is_special_point ? this.formData.first_login_point : -1,
                        expiration: this.formData.expiration,
                        first_login_text_01: this.formData.first_login_text_01,
                        first_login_text_02: this.formData.first_login_text_02
                    } ,
                    cbSuccess : res => {
                        this.formData.id = res.data.id
                        this.$toast.success("บันทึกสำเร็จ!")
                        this.$router.push(`/setting/info`)
                        this.loading = false
                        this.formDirty = false
                    } ,
                    cbError : (e , msg) => {
                        this.loading = false
                        this.$toast.error('ระบบขัดข้อง')
                        // this.$router.push("/error")
                    }
                })
            }
        },
        onSpecialPointChange(event) {
            if(!event.target.checked) {
                this.formData.first_login_point = 1
            }
        },
        onNumberInput(event) {
            const key = window.event ? event.keyCode : event.which
            if (event.keyCode === 8 || event.keyCode === 46) {
		        return true
            }
            else if ( key < 48 || key > 57 ) {
               event.preventDefault()
            }
            else {
                return true
		    }
        },
    },
}
</script>