<template>
    <ul :class="`nav ${navStyleClass}`">
        <li
            class="nav-item"
            v-for="(item, index) in items"
            :key="index + item.name"
            @click="onItemClick(item.name)"
        >
            <router-link
                v-if="item.link"
                :to="item.link"
                class="nav-link"
                :class="{ active: item.name === value }"
            >
                {{ item.name }}
            </router-link>
            <span
                v-else
                class="nav-link"
                :class="{ active: item.name === value }"
                data-toggle="tab"
            >
                {{ item.name }}
            </span>
        </li>
    </ul>
</template>

<script>
export default {
    props: ['items', 'value', 'navStyleClass'],
    methods: {
        onItemClick(item) {
            this.$emit('input', item)
        },
    },
}
</script>