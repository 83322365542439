<template>
   <fluid-content currentPage="Loyalty" subMenu="ให้คะแนนสมาชิก" :hasButton="true">
        <loading v-if="loading"/>
        <content-card title="ให้คะแนนสมาชิก" :hasButton="true">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <path d="M4,6 L20,6 C20.5522847,6 21,6.44771525 21,7 L21,8 C21,8.55228475 20.5522847,9 20,9 L4,9 C3.44771525,9 3,8.55228475 3,8 L3,7 C3,6.44771525 3.44771525,6 4,6 Z M5,11 L10,11 C10.5522847,11 11,11.4477153 11,12 L11,19 C11,19.5522847 10.5522847,20 10,20 L5,20 C4.44771525,20 4,19.5522847 4,19 L4,12 C4,11.4477153 4.44771525,11 5,11 Z M14,11 L19,11 C19.5522847,11 20,11.4477153 20,12 L20,19 C20,19.5522847 19.5522847,20 19,20 L14,20 C13.4477153,20 13,19.5522847 13,19 L13,12 C13,11.4477153 13.4477153,11 14,11 Z" fill="#000000"/>
                        <path d="M14.4452998,2.16794971 C14.9048285,1.86159725 15.5256978,1.98577112 15.8320503,2.4452998 C16.1384028,2.90482849 16.0142289,3.52569784 15.5547002,3.83205029 L12,6.20185043 L8.4452998,3.83205029 C7.98577112,3.52569784 7.86159725,2.90482849 8.16794971,2.4452998 C8.47430216,1.98577112 9.09517151,1.86159725 9.5547002,2.16794971 L12,3.79814957 L14.4452998,2.16794971 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                    </g>
                </svg>
            </template>
            <template v-slot:right>
                <button type="button" class="btn btn-outline-secondary" @click="showTip">Tips</button>
            </template>
            <div class="card-body mb-0">
                <div class="row">
                    <div class="col-sm-12 col-lg-8">
                        <div class="form-group mb-0">
                            <label class="font-weight-medium">ค้นหาสมาชิกด้วยเบอร์โทรศัพท์</label>
                            <div class="d-flex align-items-center">
                                <input
                                    type="tel"
                                    maxLength="10"
                                    class="form-control mr-2"
                                    placeholder="เบอร์โทรศัพท์"
                                    v-model="formData.phone_number"
                                    :class="{'is-invalid': formDirty && !formData.phone_number}"
                                    @keyup.enter="searchUser"
                                    @keypress="onNumberInput"
                                />
                                <button type="button" class="btn btn-icon btn-light-primary" @click="searchUser">
                                    <i class="la la-search"></i>
                                </button>
                            </div>
                            <div v-if="formDirty && !formData.phone_number" class="invalid-feedback">*This field require</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-lg-8">
                        <div class="user-info-empty text-dark-75 font-size-h3 font-weight-bolder" v-if="!userInfo">กรุณาค้นหาข้อมูลด้วยเบอร์โทรศัพท์</div>
                        <div class="user-info-empty text-dark-75 font-size-h3 font-weight-bolder" v-else-if="userInfo === 'Not found'">ไม่พบข้อมูลการเป็นสมาชิก</div>
                        <div class="user-info" v-else>
                            <div class="row">
                                <div class="col-sm-12 col-lg-3 align-items-center justify-content-center d-flex py-0 mb-4">
                                    <div class="symbol symbol-120 symbol-circle">
                                        <img alt="Pic" :src="userInfo.img_url || defaultImg"/>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-lg-4 py-0">
                                    <div class="row">
                                        <div class="col-6 font-weight-bolder">ชื่อ</div>
                                        <div class="col-6 text-info font-weight-bolder">{{userInfo.first_name || '-'}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 font-weight-bolder">นามสกุล</div>
                                        <div class="col-6 text-info font-weight-bolder">{{userInfo.last_name || '-'}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 font-weight-bolder">เบอร์โทรศัพท์</div>
                                        <div class="col-6 text-info font-weight-bolder">{{userInfo.phone_number}}</div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-lg-4 py-0">
                                    <div class="row">
                                        <div class="col-6 font-weight-bolder">คะแนนคงเหลือ</div>
                                        <div class="col-6 text-info font-weight-bolder">{{parseInt(userInfo.point_total).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 font-weight-bolder">คะแนนทั้งหมด</div>
                                        <div class="col-6 text-info font-weight-bolder">{{parseInt(userInfo.point_remaining).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 font-weight-bolder">สถานะ</div>
                                        <div class="col-6">
                                            <span class="label label-xl font-weight-boldest label-inline label-primary" v-if="userInfo.status === 'follow'">Follow</span>
                                            <span class="label label-xl font-weight-boldest label-inline label-danger" v-else>Blocked</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-lg-8">
                        <div class="row">
                            <div class="col-sm-12 col-lg-6">
                                <div class="form-group mb-0">
                                    <label class="font-weight-medium"><span class="star">*</span>จำนวนเงิน (บาท)</label>
                                    <input
                                        type="tel"
                                        maxLength="10"
                                        class="form-control"
                                        placeholder="ระบุจำนวนเงิน ..."
                                        v-model="formData.total_price"
                                        :class="{'is-invalid': formDirty && !formData.total_price, 'is-valid': formDirty && formData.total_price }"
                                        @keypress="onNumberInput"
                                    />
                                    <div v-if="formDirty && !formData.total_price" class="invalid-feedback">*This field require</div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-lg-6">
                                <div class="form-group mb-0">
                                    <label class="font-weight-medium">คะแนนที่ได้</label>
                                    <div class="input-group">
                                        <input
                                            type="number"
                                            class="form-control"
                                            placeholder="0"
                                            v-model="formData.point"
                                            :disabled="true"
                                        />
                                        <div class="input-group-append">
                                            <span class="input-group-text">คะแนน</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-lg-8">
                        <div class="row">
                            <div class="col-sm-12 col-lg-6">
                                <div class="form-group mb-0">
                                    <label class="font-weight-medium"><span class="star">*</span>เลขที่ใบเสร็จอ้างอิง</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="ระบุเลขที่ใบเสร็จ ..."
                                        v-model="formData.receipt_no"
                                        @input="onReceiptNoInput"
                                        :class="{'is-invalid': (formDirty && !formData.receipt_no) || (verifyDirty && !formData.verified) , 'is-valid': verifyDirty && formData.verified && formData.receipt_no }"
                                    />
                                    <div v-if="formDirty && !formData.receipt_no" class="invalid-feedback">*This field require</div>
                                    <div class="text-danger mt-1" v-if="formData.receipt_no && verifyDirty && !formData.verified">เลขที่ใบเสร็จซ้ำ</div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-lg-6">
                                <div class="form-group mb-0">
                                    <label class="font-weight-medium" style="opacity: 0">เช็คเลขที่ใบเสร็จ</label>
                                    <div>
                                        <button type="button" class="btn btn-primary" @click="verifyReceiptNumber">เช็คเลขที่ใบเสร็จ</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-lg-2">
                        <label class="font-weight-medium text-dark-75 mb-4">รายการสินค้าที่ขาย</label>
                        <button type="button" class="btn btn-outline-primary w-100" @click="addItem">
                            <i class="fas fa-plus"></i>
                            เพิ่มรายการ
                        </button>
                    </div>
                    <div class="col-sm-12 col-lg-6">
                        <div class="row" v-for="(product, index) in formData.products" :key="index">
                            <div class="col-6">
                                <label class="font-weight-medium text-dark-75">รายการที่ {{index + 1}}</label>
                                <select-option
                                    v-model="product.product_id"
                                    placeholder="ระบุชื่อสินค้า"
                                    :options="items.map(item => {
                                        return {
                                            label: item.name,
                                            value: item.id,
                                        }
                                    })"
                                />
                            </div>
                            <div class="col-4">
                                <label class="font-weight-medium text-dark-75">ยอด</label>
                                <div class="input-group">
                                    <input
                                        type="tel"
                                        maxLength="10"
                                        class="form-control"
                                        placeholder="0"
                                        v-model="product.price"
                                        @keypress="onNumberInput"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text">บาท</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2" v-if="index > 0">
                                <label class="font-weight-medium text-dark-75" style="opacity: 0">delete</label>
                                <div>
                                    <button type="button" class="btn btn-icon btn-outline-danger" @click="deleteProduct(product.id)">
                                        <i class="far fa-trash-alt"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="separator separator-solid"></div>
                <div class="mt-8">
                    <label class="font-weight-medium text-dark-75 mb-4">รายการให้คะแนนล่าสุด</label>
                    <data-table
                        :headers="headers"
                        :data="tableData"
                        :filter="filterData"
                        @change="onTableChange"
                    >
                        <template v-slot:item.index="{ item }">
                            <span>
                                {{(tableData.rows.map(function(x) {return x.id; }).indexOf(item.id) + 1) + filterData.offset}}
                            </span>
                        </template>
                        <template v-slot:item.line_img_url="{ item }">
                            <div class="symbol">
                                <img alt="Pic" :src="item.line_img_url || defaultImg" @error="replaceByDefault"/>
                            </div>
                        </template>
                        <template v-slot:item.line_display_name="{ item }">
                            <span>{{item.line_display_name || '-'}}</span>
                        </template>
                        <template v-slot:item.first_name="{ item }">
                            <span>{{item.first_name || '-'}}</span>
                        </template>
                        <template v-slot:item.phone_number="{ item }">
                            <span>{{item.phone_number || '-'}}</span>
                        </template>
                        <template v-slot:item.point="{ item }">
                            <span>{{parseInt(item.point).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span>
                        </template>
                        <template v-slot:item.submitter_name="{ item }">
                            <span>{{item.submitter_name || '-'}}</span>
                        </template>
                        <template v-slot:item.created_at="{ item }">
                            <span>{{formatDate(item.created_at)}}</span>
                        </template>
                        <template v-slot:item.total_price="{ item }">
                            <span>{{parseInt(item.total_price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span>
                        </template>
                    </data-table>
                </div>
            </div>
            <template v-slot:footer>
                <button type="button" class="btn btn-primary px-12" @click="onSubmit" :disabled="!validate.is_valid">ให้คะแนน</button>
            </template>
        </content-card>
        <!-- Modal-->
        <div class="modal fade" id="confirmPoint" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true" v-if="userInfo">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="confirmPointLabel">ยืนยันการให้คะแนน</h5>
                    </div>
                    <div class="modal-body" style="height: 300px;">
                        <div class="row align-items-center">
                            <div class="col-3 offset-1 text-dark-50">ชื่อ</div>
                            <div class="col-7">
                                <input
                                    type="text"
                                    class="form-control mr-2"
                                    placeholder="ชื่อ"
                                    :disabled="true"
                                    :value="userInfo.first_name || '-'"
                                />
                            </div>
                        </div>
                        <div class="row align-items-center">
                            <div class="col-3 offset-1 text-dark-50">นามสกุล</div>
                            <div class="col-7">
                                <input
                                    type="text"
                                    class="form-control mr-2"
                                    placeholder="นามสกุล"
                                    :disabled="true"
                                    :value="userInfo.last_name || '-'"
                                />
                            </div>
                        </div>
                        <div class="row align-items-center">
                            <div class="col-3 offset-1 text-dark-50">เบอร์โทรศัพท์</div>
                            <div class="col-7">
                                <input
                                    type="text"
                                    class="form-control mr-2"
                                    placeholder="เบอร์โทรศัพท์"
                                    :disabled="true"
                                    :value="userInfo.phone_number"
                                />
                            </div>
                        </div>
                        <div class="row align-items-center">
                            <div class="col-3 offset-1 text-dark-50">คะแนน</div>
                            <div class="col-7">
                                <div class="input-group">
                                    <input
                                        type="number"
                                        class="form-control"
                                        placeholder="0"
                                        :disabled="true"
                                        :value="formData.point"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text">คะแนน</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light-primary" data-dismiss="modal">ยกเลิก</button>
                        <button type="button" class="btn btn-primary" @click="savePoint" :disabled="loading">ให้คะแนน</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal-->
        <div class="modal fade" id="tips" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header py-3 px-6">
                        <h5 class="modal-title text-dark-75 font-weight-medium" id="tips">Tips</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <i aria-hidden="true" class="ki ki-close"></i>
                        </button>
                    </div>
                    <div class="modal-body" style="max-height: 660px;">
                        <img class="w-100" src="@/assets/media/project-images/tips/ให้คะแนนสมาชิก-01.png" alt="tips">
                    </div>
                </div>
            </div>
        </div>
   </fluid-content>
</template>

<script>
import { uuid } from "vue-uuid"
import moment from 'moment'

import { requestUserSearch, requestReceiptVerify } from "@/api/apis"
import { requestShopPointShow } from "@/api/shop"
import { requestItemsList } from "@/api/items"
import { requestUserPointList, requestUserPointCreate } from "@/api/point"

export default {
    data() {
        const profile = $cookies.get("ADMIN_PROFILE_DATA")
        return {
            userProfile : profile || {},
            defaultImg: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
            formDirty: false,
            verifyDirty: false,
            loading: false,
            filterData: {
                sort: "",
                order: "asc",
                offset: 0,
                limit: 10,
                filter: {},
            },
            formData: {
                id: 0,
                total_price: null,
                point: null,
                receipt_no: '',
                verified: false,
                phone_number: '',
                products: [
                    {
                        id : uuid.v4(),
                        product_id: '',
                        price: null,
                    }
                ],
            },
            validate: {
                is_valid: false,
            },
            userInfo: null,
            pointInfo: null,
            staffInfo: null,
            items: [],
            tableData: {},
            headers: [
                {
                    text: '#',
                    align: 'center',
                    value: 'index',
                    width: 50,
                    sortable: false,
                },
                {
                    text: 'รูป LINE',
                    align: 'start',
                    value: 'line_img_url',
                    width: 100,
                    sortable: false,
                },
                {
                    text: 'ชื่อ LINE',
                    align: 'start',
                    value: 'line_display_name',
                    width:  150,
                },
                {
                    text: 'ชื่อ',
                    value: 'first_name',
                    width: 100,
                },
                {
                    text: 'เบอร์โทรศัพท์',
                    align: 'start',
                    value: 'phone_number',
                    width: 140,
                },
                {
                    text: 'คะแนน',
                    align: 'start',
                    value: 'point',
                    width: 140,
                },
                {
                    text: 'ให้คะแนนโดย',
                    align: 'start',
                    value: 'submitter_name',
                    width: 140,
                },
                {
                    text: 'วันที่ / เวลา',
                    value: 'created_at',
                    width: 170,
                },
            ],
        }
    },
    watch: {
        'formData.total_price': function() {
            const point = this.formData.total_price / this.pointInfo.sale_amount
            this.formData.point = Math.floor(point)
        },
        'formData': {
            handler(val){
                if (!val.total_price ||
                    !val.receipt_no ||
                    !val.verified ||
                    val.products.find(item => item.product_id && !item.price) ||
                    !val.point || val.point < 1 ||
                    !val.phone_number
                ) {
                    this.validate.is_valid = false
                }
                else {
                    this.validate.is_valid = true
                }
            },
            deep: true
        }
    },
    mounted() {
        if(this.userProfile.role_id === '1' || this.userProfile.role_id === '2') {
            this.onLoad()
        }
        else {
            this.$router.push('/404')
        }
    },
    methods: {
        async onLoad() {
            this.loading = true
            await requestShopPointShow({
                bot_id: this.userProfile.bot_id,
                cbSuccess : res => {
                    this.pointInfo = res.data.data
                    this.loading = false
                } ,
                cbError : (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
            await requestItemsList({
                bot_id: this.userProfile.bot_id,
                formData: {
                    sort: "",
                    order: "asc",
                    offset: 0,
                    limit: 10000,
                    filter: {},
                },
                cbSuccess : res => {
                    this.items = res.data.rows
                    this.loading = false
                } ,
                cbError : (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
            await requestUserPointList({
                bot_id: this.userProfile.bot_id,
                formData: this.filterData,
                cbSuccess : res => {
                    this.tableData = res.data
                    this.loading = false
                } ,
                cbError : (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        } ,
        onSubmit() {
            this.formDirty = true
            if (!this.formData.total_price ||
                !this.formData.receipt_no ||
                !this.formData.phone_number) {
                this.$toast.error('กรุณาระบุข้อมูล')
                return false
            }
            else if (!this.userInfo) {
                this.$toast.error('กรุณาระบุผู้รับคะแนน')
                return false
            }
            else if (this.userInfo === 'Not found') {
                this.$toast.error('กรุณาระบุผู้รับคะแนน')
                return false
            }
            else if (!this.formData.verified) {
                this.$toast.error('กรุณาตรวจสอบเลขที่ใบเสร็จอ้างอิง')
                return false
            }
            else if (this.formData.products.find(item => item.product_id && !item.price)) {
                this.$toast.error('กรุณาระบุยอดของรายการสินค้าที่ขาย')
                return false
            }
            else if (!this.formData.point || this.formData.point < 1) {
                this.$toast.error('ไม่สามารถให้คะแนนได้')
                return false
            }
            else {
                $('#confirmPoint').modal('show')
            }
        },
        async savePoint() {
            this.loading = true
            await requestUserPointCreate({
                bot_id: this.userProfile.bot_id,
                formData : {
                    mid: this.userInfo.mid,
                    submitter_id: this.userProfile.mid,
                    submitter_type: 'backend',
                    submitter_name: this.userProfile.display_name,
                    total_price: this.formData.total_price,
                    point: this.formData.point,
                    receipt_no: this.formData.receipt_no,
                    products: this.formData.products.filter(product => product.product_id && product.price),
                } ,
                cbSuccess : res => {
                    if(res.data.status === "CREATE_SUCCESS") {
                        this.$toast.success("บันทึกสำเร็จ!")
                        this.onLoad()
                        this.loading = false
                        this.formDirty = false
                        this.verifyDirty = false
                        this.formData = {
                            id: 0,
                            total_price: null,
                            point: null,
                            receipt_no: '',
                            verified: false,
                            products: [
                                {
                                    id : uuid.v4(),
                                    product_id: '',
                                    price: null,
                                }
                            ],
                        }
                    }
                    else if(res.data.status === "CREATE_ERROR" && res.data.reason === "receipt_no already exists") {
                        this.$toast.error('เลขที่ใบเสร็จอ้างอิงซ้ำ')
                        this.loading = false
                        this.formDirty = false
                        this.verifyDirty = false
                    }
                    this.userInfo = null
                    this.formData.phone_number = ''
                    $('#confirmPoint').modal('hide')
                } ,
                cbError : (e , msg) => {
                    this.loading = false
                    $('#confirmPoint').modal('hide')
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        },
        onTableChange(offset, limit) {
            this.filterData.offset = offset
            this.filterData.limit = limit
            this.onLoad()
        },
        replaceByDefault(event) {
            event.target.src = this.defaultImg
        },
        async searchUser() {
            this.loading = true
            await requestUserSearch({
                bot_id: this.userProfile.bot_id,
                formData : {
                    phone_number: this.formData.phone_number,
                } ,
                cbSuccess : res => {
                    this.userInfo = res.data.data
                    this.loading = false
                    this.formDirty = false
                } ,
                cbError : (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        },
        async verifyReceiptNumber() {
            this.loading = true
            this.verifyDirty = true
            if (!this.formData.receipt_no) {
                this.loading = false
                this.$toast.error('Please input receipt nunber.')
                return false
            }
            else {
                await requestReceiptVerify({
                    bot_id: this.userProfile.bot_id,
                    formData : {
                        receipt_no: this.formData.receipt_no,
                    } ,
                    cbSuccess : res => {
                        this.formData.verified = res.data.data.result
                        this.loading = false
                    } ,
                    cbError : (e , msg) => {
                        this.loading = false
                        this.$toast.error('ระบบขัดข้อง')
                        // this.$router.push("/error")
                    }
                })
            }
        },
        addItem() {
            const itemDefault = {
                id : uuid.v4(),
                product_id: '',
                price: null,
            }
            this.formData.products.push(itemDefault)
        },
        deleteProduct(id) {
            this.formData.products = [...this.formData.products].filter(product => product.id !== id)
        },
        onReceiptNoInput() {
            this.formData.verified = false
            this.verifyDirty = false
        },
        onNumberInput(event) {
            const key = window.event ? event.keyCode : event.which
            if (event.keyCode === 8 || event.keyCode === 46) {
		        return true
            }
            else if ( key < 48 || key > 57 ) {
               event.preventDefault()
            }
            else {
                return true
		    }
        },
        formatDate(date, type) {
            const newDate = moment(date.substring(0, 10)).format("DD/MM/YYYY")
            const newTime = date.substring(11, 16)
            if(type === 'date') {
                return newDate
            }
            else if (type === 'time') {
                return newTime
            }
            else {
                return newDate + ' ' + newTime
            }
        },
        showTip() {
            $('#tips').modal('show')
        },
    },
}
</script>

<style scoped>
.user-info {
    background-color: rgba(196, 196, 196, 0.3);
    border-radius: 10px;
    padding: 12px;
}
.user-info-empty {
    background-color: rgba(196, 196, 196, 0.3);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 162px;
}
</style>