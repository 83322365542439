<template>
    <fluid-content currentPage="Loyalty" subMenu="หน้าหลัก" :hasButton="true">
        <loading v-if="loading"/>
        <content-card title="วิธีการใช้งาน" :hasButton="true">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>
                        <rect fill="#000000" x="11" y="10" width="2" height="7" rx="1"/>
                        <rect fill="#000000" x="11" y="7" width="2" height="2" rx="1"/>
                    </g>
                </svg>
            </template>
            <template v-slot:right>
                <button type="button" class="btn btn-outline-secondary" @click="showTip">Tips</button>
            </template>
            <div class="p-8">
                <div class="info-card mb-6">
                    <div class="text-dark-75 font-weight-bolder font-size-lg mb-8">เมนูลัด</div>
                    <div class="row">
                        <div class="col-lg-3">
                            <router-link to="/loyalty/point">
                                <div class="shortcut-menu">
                                    <div class="text-center svg-icon svg-icon-primary svg-icon-5x mb-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <rect x="0" y="0" width="24" height="24"/>
                                                <path d="M4,6 L20,6 C20.5522847,6 21,6.44771525 21,7 L21,8 C21,8.55228475 20.5522847,9 20,9 L4,9 C3.44771525,9 3,8.55228475 3,8 L3,7 C3,6.44771525 3.44771525,6 4,6 Z M5,11 L10,11 C10.5522847,11 11,11.4477153 11,12 L11,19 C11,19.5522847 10.5522847,20 10,20 L5,20 C4.44771525,20 4,19.5522847 4,19 L4,12 C4,11.4477153 4.44771525,11 5,11 Z M14,11 L19,11 C19.5522847,11 20,11.4477153 20,12 L20,19 C20,19.5522847 19.5522847,20 19,20 L14,20 C13.4477153,20 13,19.5522847 13,19 L13,12 C13,11.4477153 13.4477153,11 14,11 Z" fill="#000000"/>
                                                <path d="M14.4452998,2.16794971 C14.9048285,1.86159725 15.5256978,1.98577112 15.8320503,2.4452998 C16.1384028,2.90482849 16.0142289,3.52569784 15.5547002,3.83205029 L12,6.20185043 L8.4452998,3.83205029 C7.98577112,3.52569784 7.86159725,2.90482849 8.16794971,2.4452998 C8.47430216,1.98577112 9.09517151,1.86159725 9.5547002,2.16794971 L12,3.79814957 L14.4452998,2.16794971 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="text-dark-75 font-weight-bolder font-size-lg text-center">ให้คะแนนสมาชิก</div>
                                </div>
                            </router-link>
                        </div>
                        <div class="col-lg-3">
                            <router-link to="/loyalty/coupons">
                                <div class="shortcut-menu">
                                    <div class="text-center svg-icon svg-icon-primary svg-icon-5x mb-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <rect x="0" y="0" width="24" height="24"/>
                                                <path d="M3,10.0500091 L3,8 C3,7.44771525 3.44771525,7 4,7 L9,7 L9,9 C9,9.55228475 9.44771525,10 10,10 C10.5522847,10 11,9.55228475 11,9 L11,7 L21,7 C21.5522847,7 22,7.44771525 22,8 L22,10.0500091 C20.8588798,10.2816442 20,11.290521 20,12.5 C20,13.709479 20.8588798,14.7183558 22,14.9499909 L22,17 C22,17.5522847 21.5522847,18 21,18 L11,18 L11,16 C11,15.4477153 10.5522847,15 10,15 C9.44771525,15 9,15.4477153 9,16 L9,18 L4,18 C3.44771525,18 3,17.5522847 3,17 L3,14.9499909 C4.14112016,14.7183558 5,13.709479 5,12.5 C5,11.290521 4.14112016,10.2816442 3,10.0500091 Z M10,11 C9.44771525,11 9,11.4477153 9,12 L9,13 C9,13.5522847 9.44771525,14 10,14 C10.5522847,14 11,13.5522847 11,13 L11,12 C11,11.4477153 10.5522847,11 10,11 Z" fill="#000000" opacity="0.3" transform="translate(12.500000, 12.500000) rotate(-45.000000) translate(-12.500000, -12.500000) "/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="text-dark-75 font-weight-bolder font-size-lg text-center">สร้างคูปอง</div>
                                </div>
                            </router-link>
                        </div>
                        <div class="col-lg-3">
                            <router-link to="/loyalty/dashboard">
                                <div class="shortcut-menu">
                                    <div class="text-center svg-icon svg-icon-primary svg-icon-5x mb-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <rect x="0" y="0" width="24" height="24"/>
                                                <rect fill="#000000" x="4" y="4" width="7" height="7" rx="1.5"/>
                                                <path d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z" fill="#000000" opacity="0.3"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="text-dark-75 font-weight-bolder font-size-lg text-center">สรุปภาพรวม</div>
                                </div>
                            </router-link>
                        </div>
                        <div class="col-lg-3">
                            <router-link to="/loyalty/report/coupon-history">
                                <div class="shortcut-menu">
                                    <div class="text-center svg-icon svg-icon-primary svg-icon-5x mb-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <polygon points="0 0 24 0 24 24 0 24"/>
                                                <path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                                                <rect fill="#000000" x="6" y="11" width="9" height="2" rx="1"/>
                                                <rect fill="#000000" x="6" y="15" width="5" height="2" rx="1"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="text-dark-75 font-weight-bolder font-size-lg text-center">ประวัติการใช้คูปอง</div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="info-card mb-6">
                    <div class="text-dark-75 font-weight-bolder font-size-lg mb-8">รายละเอียดการใช้งานเบื้องต้น</div>
                    <div class="w-100">
                        <img class="w-100" src="@/assets/media/project-images/2.Main Visual _Demonstration.jpg" alt="Demonstration">
                    </div>
                </div>
            </div>
            <template v-slot:footer>
                <div class="row align-items-center pl-8">
                    <div class="col-lg-6 col-sm-12 p-0">
                        <div class="font-weight-bolder mb-4">แจ้งปัญหาการใช้งาน / ข้อเสนอแนะ</div>
                        <img class="mr-3" src="@/assets/media/project-images/LINE_SOCIAL.png" alt="line" style="width: 37px">
                        <a class="text-dark-75" href="https://lin.ee/edopWvU" target="_blank">@yellow-idea</a>
                    </div>
                </div>
            </template>
        </content-card>
        <!-- Modal-->
        <div class="modal fade" id="tips" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header py-3 px-6">
                        <h5 class="modal-title text-dark-75 font-weight-medium" id="tips">Tips</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <i aria-hidden="true" class="ki ki-close"></i>
                        </button>
                    </div>
                    <div class="modal-body" style="max-height: 660px;">
                        <img class="w-100" src="@/assets/media/project-images/tips/วิธีการใช้งาน-01.png" alt="tips">
                    </div>
                </div>
            </div>
        </div>
    </fluid-content>
</template>

<script>
export default {
    data() {
        const profile = $cookies.get("ADMIN_PROFILE_DATA")
        return {
            loading: false,
            userProfile : profile || {},
        }
    },
    mounted() {
        if(this.userProfile.role_id !== '1') {
            this.$router.push('/404')
        }
    },
    methods: {
        showTip() {
            $('#tips').modal('show')
        },
    },
}
</script>

<style scoped>
.info-card {
    border-radius: 14px;
    box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.05);
    padding: 20px;
    position: relative;
}
.shortcut-menu {
    background-color: rgba(39, 186, 8, 0.1);
    box-shadow: 2px 2px 14px 0px rgba(0, 0, 0, 0.06);
    border-radius: 14px;
    padding: 12px 24px;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;
}
.shortcut-menu:hover {
    background-color: rgba(39, 186, 8, 0.2);
}

</style>