<template>
    <fluid-content currentPage="จัดการพนักงาน" :hasButton="true">
        <loading v-if="loading"/>
        <content-card title="จัดการพนักงาน" :hasButton="true">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24"/>
                        <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                        <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fill-rule="nonzero"/>
                    </g>
                </svg>
            </template>
            <template v-slot:right>
                <button type="button" class="btn btn-outline-secondary" @click="showTip">Tips</button>
            </template>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-lg-6">
                        <div class="form-group mb-0">
                            <label class="font-weight-medium"><span class="star">*</span>ชื่อพนักงาน</label>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="ระบุชื่อพนักงาน ..."
                                v-model="formData.first_name"
                                :class="{'is-invalid': formDirty && !formData.first_name, 'is-valid': formDirty && formData.first_name }"
                                :disabled="formData.id !== 0"
                            />
                            <div v-if="formDirty && !formData.first_name" class="invalid-feedback">*This field require</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-lg-6">
                        <div class="form-group mb-0">
                            <label class="font-weight-medium"><span class="star">*</span>นามสกุลพนักงาน</label>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="ระบุนามสกุลพนักงาน ..."
                                v-model="formData.last_name"
                                :class="{'is-invalid': formDirty && !formData.last_name, 'is-valid': formDirty && formData.last_name }"
                                :disabled="formData.id !== 0"
                            />
                            <div v-if="formDirty && !formData.last_name" class="invalid-feedback">*This field require</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-lg-6">
                        <div class="form-group mb-0">
                            <label class="font-weight-medium"><span class="star">*</span>สาขา</label>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="ระบุสาขา ..."
                                v-model="formData.branch"
                                :class="{'is-invalid': formDirty && !formData.branch, 'is-valid': formDirty && formData.branch }"
                                :disabled="formData.id !== 0"
                            />
                            <div v-if="formDirty && !formData.branch" class="invalid-feedback">*This field require</div>
                        </div>
                    </div>
                </div>
                <div class="separator separator-solid my-6"></div>
                <div class="text-dark-75 mb-3">กรุณาคัดลอกข้อมูลนี้ให้พนักงานของท่าน</div>
                <div class="row">
                    <div class="col-sm-12 col-lg-6 pb-0">
                        <div class="form-group mb-0">
                            <label class="font-weight-medium">ลิงค์</label>
                            <clipboard :value="formData.url" placeholder="ลิงค์จะหมดอายุภายใน 24 ชม และสามารถใช้ได้ครั้งเดียว"/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-lg-6 pt-0">
                        <div class="row">
                            <div class="col-sm-12 col-lg-6">
                                <label class="font-weight-medium">Username</label>
                                <clipboard :value="formData.username" placeholder="Username"/>
                            </div>
                            <div class="col-sm-12 col-lg-6">
                                <label class="font-weight-medium">Password</label>
                                <clipboard :value="formData.password" placeholder="Password"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template v-slot:footer>
                <button type="button" class="btn btn-primary px-12" @click="onSubmit" :disabled="!validate.is_valid || loading" v-if="formData.id === 0">บันทึก</button>
            </template>
        </content-card>
        <!-- Modal-->
        <div class="modal fade" id="tips" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header py-3 px-6">
                        <h5 class="modal-title text-dark-75 font-weight-medium" id="tips">Tips</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <i aria-hidden="true" class="ki ki-close"></i>
                        </button>
                    </div>
                    <div class="modal-body" style="max-height: 660px;">
                        <img class="w-100" src="@/assets/media/project-images/tips/จัดการพนักงาน-01.png" alt="tips">
                    </div>
                </div>
            </div>
        </div>
    </fluid-content>
</template>

<script>
import { requestStaffShow, requestStaffCreate } from "@/api/staff"

export default {
    data() {
        const profile = $cookies.get("ADMIN_PROFILE_DATA")
        return {
            userProfile : profile || {},
            formDirty: false,
            loading: false,
            userType: 'line',
            formData: {
                id: 0,
                first_name: '',
                last_name: '',
                branch: '',
                url: '',
                username: '',
                password: '',
            },
            validate: {
                is_valid: false,
            },
        }
    },
    mounted() {
        if(this.userProfile.role_id === '1') {
            if( this.$router.history.current.query.id ) {
                this.onLoad(this.$router.history.current.query.id)
            }
        }
        else {
            this.$router.push('/404')
        }
    },
    watch: {
        'formData': {
            handler(val){
                if (!val.first_name ||
                    !val.last_name ||
                    !val.branch
                ) {
                    this.validate.is_valid = false
                }
                else {
                    this.validate.is_valid = true
                }
            },
            deep: true
        }
    },
    methods: {
        async onLoad(id) {
            this.loading = true
            await requestStaffShow({
                bot_id: this.userProfile.bot_id,
                id ,
                cbSuccess : res => {
                    this.formData = {
                        ...res.data.data,
                        url: `https://liff.line.me/${process.env.VUE_APP_LIFF_ID}/staff/invite?bot_id=${this.userProfile.bot_id}&code=${res.data.data.url}`
                    }
                    this.loading = false
                } ,
                cbError : (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        } ,
        async onSubmit() {
            this.loading = true
            this.formDirty = true
            if (!this.formData.first_name ||
                !this.formData.last_name ||
                !this.formData.branch) {
                this.loading = false
                this.$toast.error('กรุณาระบุข้อมูล')
                return false
            }
            else {
                if( this.formData.id === 0 ) {
                    await requestStaffCreate({
                        bot_id: this.userProfile.bot_id,
                        formData : {
                            first_name: this.formData.first_name,
                            last_name: this.formData.last_name,
                            branch: this.formData.branch,
                        } ,
                        cbSuccess : res => {
                            this.formData.id = res.data.id
                            this.formData.url = `https://liff.line.me/${process.env.VUE_APP_LIFF_ID}/staff/invite?bot_id=${this.userProfile.bot_id}&code=${res.data.url}`
                            this.formData.username = res.data.username
                            this.formData.password = res.data.password
                            this.$toast.success("บันทึกสำเร็จ!")
                            this.onLoad(res.data.id)
                            this.$router.push(`/loyalty/staff/form?id=${res.data.id}`)
                            this.loading = false
                            this.formDirty = false
                        } ,
                        cbError : (e , msg) => {
                            this.loading = false
                            this.$toast.error('ระบบขัดข้อง')
                            // this.$router.push("/error")
                        }
                    })
                }
            }
        },
        showTip() {
            $('#tips').modal('show')
        },
    },
}
</script>