import Vue from 'vue'
import VueRouter from 'vue-router'

import Index from '../views/Home'
import NotFound from '../views/NotFound'
import Invite from '../views/Invite'
import Logout from '../views/Logout'
import ErrorPage from '../views/Error'
import LinkError from '../views/LinkError'
import LoginError from '../views/LoginError'
import SettingShopPoint from '../views/setting/Point'
import SettingShopInfo from '../views/setting/Info'
import LoyaltyHome from '../views/loyalty/home/Index'
import LoyaltyDashboard from '../views/loyalty/dashboard/Index'
import LoyaltyItems from '../views/loyalty/items/Index'
import LoyaltyItemsForm from '../views/loyalty/items/Form'
import LoyaltyCoupons from '../views/loyalty/coupons/Index'
import LoyaltyCouponsForm from '../views/loyalty/coupons/Form'
import LoyaltyPoint from '../views/loyalty/point/Index'
import LoyaltyShop from '../views/loyalty/shop-setting/Index'
import LoyaltyShopBannerList from '../views/loyalty/shop-setting/BannerList'
import LoyaltyShopBannerForm from '../views/loyalty/shop-setting/BannerForm'
import LoyaltyShopPoint from '../views/loyalty/shop-setting/Point'
import LoyaltyAdmin from '../views/loyalty/admin/Index'
import LoyaltyAdminForm from '../views/loyalty/admin/Form'
import LoyaltyStaff from '../views/loyalty/staff/Index'
import LoyaltyStaffForm from '../views/loyalty/staff/Form'
import LoyaltyUserReport from '../views/loyalty/report/UserReport'
import LoyaltyCouponReport from '../views/loyalty/report/CouponReport'
import LoyaltyCouponHistoryReport from '../views/loyalty/report/CouponHistoryReport'
import LoyaltyPointReport from '../views/loyalty/report/PointReport'

import inviteAdmin from './middleware/inviteAdmin'
import lineLogin from './middleware/lineLogin'
import isLoggedIn from './middleware/isLoggedIn'
import pageNotFound from './middleware/pageNotFound'
import accountSelect from './middleware/accountSelect'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Index',
        component: Index,
        meta: {
            middleware: [
                lineLogin
            ]
        },
    },
    {
        path: '/register',
        name: 'Register',
        component: Index,
        meta: {
            middleware: [
                isLoggedIn
            ]
        },
    },
    {
        path: '/account',
        name: 'Account',
        component: Index,
        meta: {
            middleware: [
                accountSelect
            ]
        },
    },
    {
        path: '/404',
        name: 'Not Found',
        component: NotFound,
        meta: {
            middleware: [
                pageNotFound
            ]
        },
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout,
    },
    {
        path: '/error',
        name: 'Error',
        component: ErrorPage,
    },
    {
        path: '/login-error',
        name: 'Login Error',
        component: LoginError,
    },
    {
        path: '/link-error',
        name: 'Link Error',
        component: LinkError,
    },
    {
        path: '/invite',
        name: 'Invite',
        component: Invite,
        meta: {
            middleware: [
                inviteAdmin
            ]
        },
    },
    {
        path: '/setting/point',
        name: 'Setting Point',
        component: SettingShopPoint,
        meta: {
            middleware: [
                isLoggedIn
            ]
        },
    },
    {
        path: '/setting/info',
        name: 'Setting Info',
        component: SettingShopInfo,
        meta: {
            middleware: [
                isLoggedIn
            ]
        },
    },
    {
        path: '/loyalty/home',
        name: 'Loyalty Home',
        component: LoyaltyHome,
        meta: {
            middleware: [
                isLoggedIn,
            ]
        },
    },
    {
        path: '/loyalty/dashboard',
        name: 'Loyalty Dashboard',
        component: LoyaltyDashboard,
        meta: {
            middleware: [
                isLoggedIn,
            ]
        },
    },
    {
        path: '/loyalty/items',
        name: 'Loyalty Items',
        component: LoyaltyItems,
        meta: {
            middleware: [
                isLoggedIn,
            ]
        },
    },
    {
        path: '/loyalty/items/form',
        name: 'Loyalty Items Form',
        component: LoyaltyItemsForm,
        meta: {
            middleware: [
                isLoggedIn,
            ]
        },
    },
    {
        path: '/loyalty/coupons',
        name: 'Loyalty Coupons',
        component: LoyaltyCoupons,
        meta: {
            middleware: [
                isLoggedIn,
            ]
        },
    },
    {
        path: '/loyalty/coupons/form',
        name: 'Loyalty Coupons Form',
        component: LoyaltyCouponsForm,
        meta: {
            middleware: [
                isLoggedIn,
            ]
        },
    },
    {
        path: '/loyalty/point',
        name: 'Loyalty Point Form',
        component: LoyaltyPoint,
        meta: {
            middleware: [
                isLoggedIn,
            ]
        },
    },
    {
        path: '/loyalty/shop',
        name: 'Loyalty Shop Setting',
        component: LoyaltyShop,
        meta: {
            middleware: [
                isLoggedIn,
            ]
        },
    },
    {
        path: '/loyalty/shop/banner',
        name: 'Loyalty Shop Banner List',
        component: LoyaltyShopBannerList,
        meta: {
            middleware: [
                isLoggedIn,
            ]
        },
    },
    {
        path: '/loyalty/shop/banner/form',
        name: 'Loyalty Shop Banner Form',
        component: LoyaltyShopBannerForm,
        meta: {
            middleware: [
                isLoggedIn,
            ]
        },
    },
    {
        path: '/loyalty/shop/point',
        name: 'Loyalty Shop Point',
        component: LoyaltyShopPoint,
        meta: {
            middleware: [
                isLoggedIn,
            ]
        },
    },
    {
        path: '/loyalty/admin',
        name: 'Loyalty Admin',
        component: LoyaltyAdmin,
        meta: {
            middleware: [
                isLoggedIn,
            ]
        },
    },
    {
        path: '/loyalty/admin/form',
        name: 'Loyalty Admin Form',
        component: LoyaltyAdminForm,
        meta: {
            middleware: [
                isLoggedIn,
            ]
        },
    },
    {
        path: '/loyalty/staff',
        name: 'Loyalty Staff',
        component: LoyaltyStaff,
        meta: {
            middleware: [
                isLoggedIn,
            ]
        },
    },
    {
        path: '/loyalty/staff/form',
        name: 'Loyalty Staff Form',
        component: LoyaltyStaffForm,
        meta: {
            middleware: [
                isLoggedIn,
            ]
        },
    },
    {
        path: '/loyalty/report/user',
        name: 'Loyalty User Report',
        component: LoyaltyUserReport,
        meta: {
            middleware: [
                isLoggedIn,
            ]
        },
    },
    {
        path: '/loyalty/report/coupon-history',
        name: 'Loyalty Coupon history Report',
        component: LoyaltyCouponHistoryReport,
        meta: {
            middleware: [
                isLoggedIn,
            ]
        },
    },
    {
        path: '/loyalty/report/coupon',
        name: 'Loyalty Coupon Report',
        component: LoyaltyCouponReport,
        meta: {
            middleware: [
                isLoggedIn,
            ]
        },
    },
    {
        path: '/loyalty/report/point',
        name: 'Loyalty Point Report',
        component: LoyaltyPointReport,
        meta: {
            middleware: [
                isLoggedIn,
            ]
        },
    },
    {
        path: '*',
        component: NotFound,
        meta: {
            middleware: [
                pageNotFound
            ]
        },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
