<template>
    <div class="card card-custom"  style="height: 100%; box-shadow: none">
        <div class="card-header flex-wrap border-0 pt-3 pb-3 align-items-center">
            <div class="card-title">
                <span class="svg-icon mr-2">
                    <slot name="icon" />
                    {{ icon }}
                </span>
                <h3 class="card-label">{{ title }}</h3>
            </div>
            <div>
                <slot name="center"></slot>
            </div>
            <div>
                <slot name="right"></slot>
            </div>
        </div>
        <div class="separator separator-solid"></div>
        <slot />
        <div
            v-if="!isMobile()"
            class="fixed-bottom d-flex align-items-center py-5"
            :style="`border-top: 1px solid #EAEDF3;background-color: #FFF;width: ${hasButton ? '100%' : '264px'}; z-index: 100`"
        >
            <div class="d-flex align-items-center justify-content-center" style="width: 158px;">
                <img class="w-100" src="@/assets/media/project-images/Yellow-Reward.jpg" style="margin-left: 95px;" />
            </div>
            <div v-if="hasButton" class="d-flex align-items-center justify-content-start px-16 w-100" style="margin-left: 66px;">
                <slot name="footer"></slot>
            </div>
        </div>
        <div
            v-else
            class="d-flex align-items-center justify-content-center py-2"
            style="background-color: #FFF"
        >
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: ['title', 'icon', 'hasButton'],
    methods: {
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
}
</script>