<template>
    <div class="input-icon">
        <input
            type="text"
            class="form-control"
            placeholder="Name"
            :value="value"
            @input="$emit('input', $event.target.value)"
        />
        <span>
            <i class="flaticon2-search-1 text-muted"></i>
        </span>
    </div>
</template>

<script>
export default {
    props: ['value']
}
</script>