<template>
   <fluid-content currentPage="Loyalty" subMenu="สร้างคูปอง" :hasButton="true">
        <loading v-if="loading"/>
        <content-card title="สร้างคูปอง" :hasButton="true">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <path d="M3,10.0500091 L3,8 C3,7.44771525 3.44771525,7 4,7 L9,7 L9,9 C9,9.55228475 9.44771525,10 10,10 C10.5522847,10 11,9.55228475 11,9 L11,7 L21,7 C21.5522847,7 22,7.44771525 22,8 L22,10.0500091 C20.8588798,10.2816442 20,11.290521 20,12.5 C20,13.709479 20.8588798,14.7183558 22,14.9499909 L22,17 C22,17.5522847 21.5522847,18 21,18 L11,18 L11,16 C11,15.4477153 10.5522847,15 10,15 C9.44771525,15 9,15.4477153 9,16 L9,18 L4,18 C3.44771525,18 3,17.5522847 3,17 L3,14.9499909 C4.14112016,14.7183558 5,13.709479 5,12.5 C5,11.290521 4.14112016,10.2816442 3,10.0500091 Z M10,11 C9.44771525,11 9,11.4477153 9,12 L9,13 C9,13.5522847 9.44771525,14 10,14 C10.5522847,14 11,13.5522847 11,13 L11,12 C11,11.4477153 10.5522847,11 10,11 Z" fill="#000000" opacity="0.3" transform="translate(12.500000, 12.500000) rotate(-45.000000) translate(-12.500000, -12.500000) "/>
                    </g>
                </svg>
            </template>
            <template v-slot:right>
                <button type="button" class="btn btn-outline-secondary" @click="showTip">Tips</button>
            </template>
            <div class="card-body mb-0">
                <div class="row  align-items-center">
                    <div class="col-lg-2 col-xl-4">
                        <div class="form-group mb-0">
                            <label class="font-weight-medium"><span class="star">*</span>รูปภาพคูปอง</label>
                            <upload-image-preview
                                size="1"
                                v-model="formData.img_url"
                                previewWidth="200px"
                                previewHeight="200px"
                                @remove="formData.img_url = ''"
                            />
                            <div v-if="formDirty && !formData.img_url" class="text-danger font-size-sm">*This field require</div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-3 d-flex flex-column align-items-center ">
                        <!-- <span>Image dimension must be X:X ratio.</span> -->
                        <span>ขนาดแนะนำ 343 * 144 px</span>
                        <span>ขนาดไฟล์ไม่เกิน 1MB .png / .jpg</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-sm-12">
                        <div class="form-group mb-0">
                            <label class="font-weight-medium"><span class="star">*</span>ชื่อ</label>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="ระบุชื่อ ..."
                                v-model="formData.name"
                                :class="{'is-invalid': formDirty && !formData.name, 'is-valid': formDirty && formData.name }"
                            />
                            <div v-if="formDirty && !formData.name" class="invalid-feedback">*This field require</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <div class="form-group mb-4">
                            <label class="font-weight-medium"><span class="star">*</span>คะแนนที่ใช้แลก</label>
                            <input
                                type="tel"
                                maxLength="10"
                                class="form-control"
                                placeholder="0"
                                v-model="formData.point_cost"
                                :class="{'is-invalid': formDirty && !formData.point_cost, 'is-valid': formDirty && formData.point_cost }"
                                @keypress="onNumberInput"
                            />
                            <div v-if="formDirty && !formData.point_cost" class="invalid-feedback">*This field require</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-sm-12">
                        <div class="form-group">
                            <label class="font-weight-medium"><span class="star">*</span>สิทธิ์การแลกทั้งหมด</label>
                            <input
                                type="tel"
                                maxLength="10"
                                class="form-control"
                                placeholder="ระบุจำนวนสิทธิ์การแลกทั้งหมด ..."
                                v-model="formData.limit_all"
                                :class="{'is-invalid': formDirty && !formData.limit_all, 'is-valid': formDirty && formData.limit_all }"
                                @keypress="onNumberInput"
                            />
                            <div v-if="formDirty && !formData.limit_all" class="invalid-feedback">*This field require</div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="font-weight-medium">จำกัดจำนวนการแลกคูปอง</label>
                    <div class="row align-items-center">
                        <div class="col-lg-2 col-sm-12 offset-md-1">
                            <label class="checkbox">
                                <input type="checkbox" v-model="formData.is_redeem_per_user"/> สิทธิ์การแลกต่อสมาชิก
                                <span></span>
                            </label>
                        </div>
                        <div class="col-lg-6 col-sm-12">
                            <div class="input-group">
                                <input
                                    onKeyUp="if(this.value<0){this.value='0';}"
                                    type="number"
                                    class="form-control"
                                    placeholder="0"
                                    v-model="formData.limit_person"
                                    :disabled="!formData.is_redeem_per_user"
                                    :class="{'is-invalid': formDirty && formData.is_redeem_per_user && !formData.limit_person ||
                                        formDirty && formData.is_redeem_per_user && formData.limit_person == 0}"
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">สิทธิ์ต่อสมาชิก</span>
                                </div>
                            </div>
                            <div v-if="formDirty && formData.is_redeem_per_user && !formData.limit_person ||
                                formDirty && formData.is_redeem_per_user && formData.limit_person == 0" class="text-danger font-size-sm">*This field require</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-sm-12">
                        <div class="form-group mb-0">
                            <label class="font-weight-medium"><span class="star">*</span>วันที่</label>
                            <datepicker-range
                                v-model="formData.dates"
                                :required="true"
                                :formDirty="formDirty"
                                fotmat="DD/MM/YYYY HH:mm"
                                placeholder="ระบุวันที่เริ่มต้น - สิ้นสุด"
                            />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-sm-12">
                        <div class="form-group mb-0">
                            <label class="font-weight-medium"><span class="star">*</span>รายละเอียดเงื่อนไข</label>
                            <texteditor v-model="formData.detail"/>
                            <div v-if="formDirty && !formData.detail" class="text-danger font-size-sm">*This field require</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <div class="form-group mb-0">
                            <label class="font-weight-medium">สาขาที่สามารถใช้ได้</label>
                            <texteditor v-model="formData.branch"/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-sm-12">
                        <div class="form-group">
                            <label class="font-weight-medium">สถานะ</label>
                            <div class="ml-10">
                                <radio
                                    :list="enableOptions"
                                    v-model="formData.is_active"
                                    name="enableOptions"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template v-slot:footer>
                <button type="button" class="btn btn-primary px-12" @click="onSubmit" :disabled="!validate.is_valid || loading">บันทึก</button>
            </template>
        </content-card>
        <!-- Modal-->
        <div class="modal fade" id="tips" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header py-3 px-6">
                        <h5 class="modal-title text-dark-75 font-weight-medium" id="tips">Tips</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <i aria-hidden="true" class="ki ki-close"></i>
                        </button>
                    </div>
                    <div class="modal-body" style="max-height: 660px;">
                        <img class="w-100" src="@/assets/media/project-images/tips/สร้างคูปอง-01.png" alt="tips">
                    </div>
                </div>
            </div>
        </div>
   </fluid-content>
</template>

<script>
import UploadImagePreview from '@/components/UploadImagePreview'

import { requestCouponShow, requestCouponCreate, requestCouponUpdate } from "@/api/coupon"

export default {
    components: {
        UploadImagePreview,
    },
    data() {
        const profile = $cookies.get("ADMIN_PROFILE_DATA")
        return {
            userProfile : profile || {},
            formDirty: false,
            loading: false,
            formData: {
                id: 0,
                img_url: '',
                name: '',
                point_cost: null,
                limit_person: 1,
                limit_all: null,
                start_date: '',
                end_date: '',
                detail: '',
                branch: '',
                is_active: '1',
                is_redeem_per_user: false,
                dates: [],
            },
            validate: {
                is_valid: false,
            },
            enableOptions: [
                {
                    value: '1',
                    label: 'ทำงาน (Enable)',
                },
                {
                    value: '0',
                    label: 'ไม่ทำงาน (Disabled)',
                },
            ]
        }
    },
    watch: {
        'formData': {
            handler(val){
                if (!val.img_url ||
                    !val.name ||
                    !val.point_cost ||
                    !val.detail ||
                    val.dates.length === 0 ||
                    !val.limit_all ||
                    val.is_redeem_per_user && !val.limit_person ||
                    val.is_redeem_per_user && val.limit_person == 0
                ) {
                    this.validate.is_valid = false
                }
                else {
                    this.validate.is_valid = true
                }
            },
            deep: true
        },
    },
    mounted() {
        if(this.userProfile.role_id === '1') {
            if( this.$router.history.current.query.id ) {
                this.onLoad(this.$router.history.current.query.id)
            }
        }
        else {
            this.$router.push('/404')
        }
    },
    methods: {
        async onLoad(id) {
            this.loading = true
            await requestCouponShow({
                bot_id: this.userProfile.bot_id,
                id ,
                cbSuccess : res => {
                    this.formData = {
                        ...res.data.data,
                        limit_person: res.data.data.limit_person !== -1 ? res.data.data.limit_person : 1,
                        is_redeem_per_user: res.data.data.limit_person > 0 ? true : false,
                        limit_all: res.data.data.limit_all !== -1 ? res.data.data.limit_all : 0,
                        dates: [res.data.data.start_date, res.data.data.end_date],
                        
                    }
                    this.loading = false
                } ,
                cbError : (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        } ,
        async onSubmit() {
            this.loading = true
            this.formDirty = true
            if (!this.formData.img_url ||
                !this.formData.name ||
                !this.formData.point_cost ||
                this.formData.dates.length === 0 ||
                !this.formData.detail ||
                !this.formData.limit_all ||
                this.formData.is_redeem_per_user && !this.formData.limit_person ||
                this.formData.is_redeem_per_user && this.formData.limit_person == 0) {
                this.loading = false
                this.$toast.error('กรุณาระบุข้อมูล')
                return false
            }
            else {
                if( this.formData.id === 0 ) {
                    await requestCouponCreate({
                        bot_id: this.userProfile.bot_id,
                        formData : {
                            img_url: this.formData.img_url,
                            name: this.formData.name,
                            point_cost: this.formData.point_cost,
                            limit_person: this.formData.is_redeem_per_user ? this.formData.limit_person : -1,
                            limit_all: this.formData.limit_all,
                            start_date: this.formData.dates[0],
                            end_date: this.formData.dates[1],
                            detail: this.formData.detail,
                            branch: this.formData.branch,
                            is_active: this.formData.is_active,
                        } ,
                        cbSuccess : res => {
                            this.formData.id = res.data.id
                            this.$toast.success("บันทึกสำเร็จ!")
                            this.onLoad(res.data.id)
                            this.$router.push(`/loyalty/coupons`)
                            this.loading = false
                            this.formDirty = false
                        } ,
                        cbError : (e , msg) => {
                            this.loading = false
                            this.$toast.error('ระบบขัดข้อง')
                            // this.$router.push("/error")
                        }
                    })
                }
                else {
                    this.loading = false
                    const id = this.$router.history.current.query.id
                    await requestCouponUpdate({
                        bot_id: this.userProfile.bot_id,
                        id,
                        formData : {
                            img_url: this.formData.img_url,
                            name: this.formData.name,
                            point_cost: this.formData.point_cost,
                            limit_person: this.formData.is_redeem_per_user ? this.formData.limit_person : -1,
                            limit_all: this.formData.limit_all,
                            start_date: this.formData.dates[0],
                            end_date: this.formData.dates[1],
                            detail: this.formData.detail,
                            branch: this.formData.branch,
                            is_active: this.formData.is_active,
                        } ,
                        cbSuccess : res => {
                            this.formData.id = res.data.id
                            this.$toast.success("อัพเดทเรียบร้อย!")
                            this.onLoad(res.data.id)
                            this.loading = false
                            this.formDirty = false
                        } ,
                        cbError : (e , msg) => {
                            this.loading = false
                            this.$toast.error('ระบบขัดข้อง')
                            // this.$router.push("/error")
                        }
                    })
                }
            }
        },
        onNumberInput(event) {
            const key = window.event ? event.keyCode : event.which
            if (event.keyCode === 8 || event.keyCode === 46) {
		        return true
            }
            else if ( key < 48 || key > 57 ) {
               event.preventDefault()
            }
            else {
                return true
		    }
        },
        showTip() {
            $('#tips').modal('show')
        },
    },
}
</script>