<template>
    <ckeditor :editor="editor" :value="value" :config="editorConfig" @input="onEditorInput"></ckeditor>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export default {
    props: ['value'],
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'Undo', 'Redo', ]
            }
        }
    },
    methods: {
        onEditorInput (value) {
            this.$emit('input', value)
        }
    }
}
</script>