<template>
    <fluid-content currentPage="Loyalty" subMenu="หน้าหลัก" :hasButton="true">
        <loading v-if="loading"/>
        <content-card title="ข้อมูลร้านค้าของท่าน" :hasButton="true">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "/>
                        <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"/>
                    </g>
                </svg>
            </template>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-lg-6">
                        <div class="form-group mb-0">
                            <label class="font-weight-medium">ลิงค์สำหรับลูกค้า เข้าหน้าสะสมคะแนน</label>
                            <clipboard :value="formData.shop_url" placeholder="Shop URL"/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-lg-3 text-center">
                        <vue-qrcode
                            v-if="formData.shop_url"
                            :value="formData.shop_url"
                            :width="250"
                            errorCorrectionLevel="M"
                        />
                    </div>
                </div>
                <div class="mt-8">
                    <label class="font-weight-medium mb-4">โปสเตอร์ของคุณ</label>
                    <div class="row">
                        <div class="col-sm-12 col-lg-3 offset-md-1">
                            <div class="w-100 text-right">
                                <img class="w-100" :src="formData.poster_img_url" alt="">
                                <a type="button" class="btn btn-outline-primary mt-4" :href="formData.poster_download_url" target="_blank">
                                    Download image
                                </a>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-3 offset-md-1">
                            <div class="w-100 text-right">
                                <img class="w-100" :src="formData.poster_img_url_2" alt="">
                                <a type="button" class="btn btn-outline-primary mt-4" :href="formData.poster_download_url_2" target="_blank">
                                    Download image
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template v-slot:footer>
                <button type="button" class="btn btn-primary px-12" @click="onSubmit" :disabled="loading">ถัดไป</button>
            </template>
        </content-card>
    </fluid-content>
</template>

<script>
import VueQrcode from 'vue-qrcode'

import UploadImagePreview from '@/components/UploadImagePreview'

import { requestShopInfoShow } from "@/api/shop"
import { requestAdminShowByMid } from '@/api/admin'

export default {
    components: {
        UploadImagePreview,
        VueQrcode,
    },
    data() {
        const profile = $cookies.get("ADMIN_PROFILE_DATA")
        return {
            userProfile : profile || {},
            loading: false,
            formDirty: false,
            formData: {
                img_url: '',
                shop_name: '',
                shop_url: '',
                poster_download_url: '',
                poster_img_url: '',
            }
        }
    },
    async mounted() {
        await this.onLoad()
    },
    methods: {
        async onLoad(id) {
            this.loading = true
            await requestShopInfoShow({
                bot_id: this.userProfile.bot_id,
                id ,
                cbSuccess : res => {
                    this.formData = res.data.data
                    this.loading = false
                } ,
                cbError : (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        } ,
        async onSubmit() {
            delete this.userProfile.is_new
            await requestAdminShowByMid({
                bot_id: this.userProfile.bot_id,
                id: this.userProfile.mid,
                cbSuccess: res => {
                    this.$cookies.set('ADMIN_PROFILE_DATA', JSON.stringify({
                        ...this.userProfile,
                        role_id: res.data.data.role_id,
                        name: res.data.data.name,
                    }), "3h")

                    if(res.data.data.role_id === '1') {
                        this.$router.push(`/loyalty/home`)
                    }
                    else if (res.data.data.role_id === '2') {
                        this.$router.push(`/loyalty/point`)
                    }
                    else if (res.data.data.role_id === '3') {
                        this.$router.push(`/loyalty/report/user`)
                    }
                } ,
                cbError: (e , msg) => {
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        },
    }
}
</script>