<template>
    <div class="wrap">
        <div class="title">{{title}}</div>
        <div class="d-flex">
            <div class="item">
                <div class="mb-2">{{ data.female.percent }} %</div>
                <div class="mb-6">{{ (data.female.number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</div>
                <div>
                    <img src="@/assets/media/report/female.svg" alt="">
                </div>
            </div>
            <div class="item">
                <div class="mb-2">{{ data.male.percent }} %</div>
                <div class="mb-6">{{ (data.male.number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</div>
                <div>
                    <img src="@/assets/media/report/male.svg" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['title', 'data'],
}
</script>

<style scoped>
.wrap {
    width: 100%;
}
.title {
    font-weight: 400;
    color: #464E5F;
    font-size: 20px !important;
    text-align: center;
    margin-bottom: 20px;
}
.item {
    width: 50%;
    text-align: center;
    height: 258px;
    font-size: 14px;
    font-weight: 500;
}
</style>