<template>
    <div>
        <div class="input-group date mb-2">
            <input
                type="text"
                class="form-control"
                :placeholder="placeholder ? placeholder : 'selete start date'"
                ref="datepicker"
                :value="value"
                :class="{'is-invalid': formDirty && required && !value, 'is-valid': formDirty && required && value }"
            />
            <div class="input-group-append">
                <span class="input-group-text">
                    <i class="la la-calendar"></i>
                </span>
            </div>
            <div v-if="formDirty && required && !value" class="invalid-feedback">*This field require</div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["value", "format", "formDirty", "placeholder", "required"],
        mounted() {
            setTimeout(() => {
                const ele = $(this.$refs.datepicker).datepicker({
                    rtl: KTUtil.isRTL(),
                    format: this.format,
                    orientation: "bottom left",
                    todayHighlight: true,
                    templates: KTUtil.isRTL() ? {
                        leftArrow: '<i class="la la-angle-right"></i>',
                        rightArrow: '<i class="la la-angle-left"></i>'
                    } : {
                        leftArrow: '<i class="la la-angle-left"></i>',
                        rightArrow: '<i class="la la-angle-right"></i>'
                    },
                });
                ele.on("changeDate", data => {
                    this.$emit("input", data.currentTarget.value)
                });
            }, 1000);
        }
    }
</script>
