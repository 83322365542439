<template>
    <div class="input-group timepicker">
        <input
            ref="timepicker"
            class="form-control"
            readonly="readonly"
            placeholder="Select time"
            type="text"
            :value="value"
            :class="{'is-invalid': formDirty && !value, 'is-valid': formDirty && value }"
        />
        <div class="input-group-append">
            <span class="input-group-text">
                <i class="la la-clock-o"></i>
            </span>
        </div>
        <div v-if="formDirty && !value" class="invalid-feedback">*This field require</div>
    </div>
</template>

<script>
export default {
    props: ["value", "formDirty"],
    mounted () {
        setTimeout(() => {
            const ele = $(this.$refs.timepicker).timepicker({
                minuteStep: 1,
                defaultTime: '',
                snapToStep: true,
                showMeridian: false,
            })
            ele.on('changeTime.timepicker', data => {
                this.$emit("input", data.time.value);
            })
        }, 1000);
       
    }
}
</script>