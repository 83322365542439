<template>
   <fluid-content currentPage="Loyalty" subMenu="สร้างรายการ">
        <loading v-if="loading"/>
        <content-card title="สร้างรายการ">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <path d="M4,9.67471899 L10.880262,13.6470401 C10.9543486,13.689814 11.0320333,13.7207107 11.1111111,13.740321 L11.1111111,21.4444444 L4.49070127,17.526473 C4.18655139,17.3464765 4,17.0193034 4,16.6658832 L4,9.67471899 Z M20,9.56911707 L20,16.6658832 C20,17.0193034 19.8134486,17.3464765 19.5092987,17.526473 L12.8888889,21.4444444 L12.8888889,13.6728275 C12.9050191,13.6647696 12.9210067,13.6561758 12.9368301,13.6470401 L20,9.56911707 Z" fill="#000000"/>
                        <path d="M4.21611835,7.74669402 C4.30015839,7.64056877 4.40623188,7.55087574 4.5299008,7.48500698 L11.5299008,3.75665466 C11.8237589,3.60013944 12.1762411,3.60013944 12.4700992,3.75665466 L19.4700992,7.48500698 C19.5654307,7.53578262 19.6503066,7.60071528 19.7226939,7.67641889 L12.0479413,12.1074394 C11.9974761,12.1365754 11.9509488,12.1699127 11.9085461,12.2067543 C11.8661433,12.1699127 11.819616,12.1365754 11.7691509,12.1074394 L4.21611835,7.74669402 Z" fill="#000000" opacity="0.3"/>
                    </g>
                </svg>
            </template>
            <div class="p-8">
                <div class="mb-7 w-100">
                    <add-link to="/loyalty/items/form" label="เพิ่มรายการสินค้า" />
                </div>
                <div class="mb-7">
                    <data-table
                        minWidth="900px"
                        :headers="headers"
                        :data="tableData"
                        :filter="formData"
                        @change="onTableChange"
                    >
                        
                        <template v-slot:item.index="{ item }">
                            <span>
                                {{(tableData.rows.map(function(x) {return x.id; }).indexOf(item.id) + 1) + formData.offset}}
                            </span>
                        </template>
                        <template v-slot:item.is_active="{ item }">
                            <switch-toggle :value="parseInt(item.is_active)" @input="(value) => setItemActive(item.id, value)"/>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <div class="d-flex">
                                <router-link :to="`/loyalty/items/form?id=${item.id}`" class="btn btn-info btn-sm mr-2">
                                    <i class="far fa-edit mr-1"></i>
                                    Edit
                                </router-link>
                                <button type="button" class="btn btn-danger btn-sm" @click="deleteStaffModalClick(item.id)">
                                    <i class="far fa-trash-alt mr-1"></i>
                                    Delete
                                </button>
                            </div>
                        </template>
                        <template v-slot:item.seq_action="{ item }">
                            <button
                                type="button"
                                class="mr-4 btn btn-icon btn-outline-success btn-pill"
                                @click="onUpClick(item.id)"
                                :disabled="tableData.rows.map(function(x) {return x.id; }).indexOf(item.id) === 0"
                            >
                                <i class="ki ki-bold-arrow-up"></i>
                            </button>
                            <button
                                type="button"
                                class="btn btn-icon btn-outline-success btn-pill"
                                @click="onDownClick(item.id)"
                                :disabled="tableData.rows.map(function(x) {return x.id; }).indexOf(item.id) + 1 === tableData.rows.length"
                            >
                                <i class="ki ki-bold-arrow-down"></i>
                            </button>
                        </template>
                    </data-table>
                </div>
            </div>
        </content-card>
        <!-- Modal Confirm -->
        <div class="modal fade" id="confirmDelete" tabindex="-1" role="dialog" aria-labelledby="confirmDelete" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <p class="text-dark-75 font-size-h3 font-weight-bolder">Are you sure?</p>
                        <p class="text-dark-50 font-size-sm">You won't be able to revert this!</p>
                    </div>
                    <div class="modal-footer p-1">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">cancel</button>
                        <button type="button" class="btn btn-primary" @click="deleteStaff">Yes, delete it!</button>
                    </div>
                </div>
            </div>
        </div>
   </fluid-content>
</template>

<script>
import { requestItemsList, requestItemsDelete, requestItemsUpdate, requestItemsUpdateSeq } from "@/api/items"

export default {
    data() {
        const profile = $cookies.get("ADMIN_PROFILE_DATA")
        return {
            userProfile : profile || {},
            loading: false,
            activeId: 0,
            formData: {
                sort: "",
                order: "asc",
                offset: 0,
                limit: 10,
                filter: {},
            },
            tableData: {},
            headers: [
                {
                    text: '#',
                    align: 'center',
                    value: 'index',
                    width: 50,
                    sortable: false,
                },
                {
                    text: 'รายการ',
                    align: 'start',
                    value: 'name',
                    width: 250,
                },
                {
                    text: 'สถานะ',
                    value: 'is_active',
                    width: 130,
                },
                {
                    text: 'การจัดการ',
                    value: 'actions',
                    sortable: false,
                    width: 100,
                },
                {
                    text: '',
                    align: 'center',
                    value: 'seq_action',
                    width: 50,
                    sortable: false,
                },
            ],
        }
    },
    async mounted() {
        if(this.userProfile.role_id === '1') {
            await this.getAll()
        }
        else {
            this.$router.push('/404')
        }
    },
    methods: {
        onTableChange(offset, limit) {
            this.formData.offset = offset
            this.formData.limit = limit
            this.getAll()
        },
        async onDownClick(id) {
            this.loading = true
            await requestItemsUpdateSeq({
                bot_id: this.userProfile.bot_id,
                id,
                formData : {
                    move: 'down',
                } ,
                cbSuccess : res => {
                    this.$toast.success("อัพเดทเรียบร้อย!")
                    this.getAll()
                    this.loading = false
                } ,
                cbError : (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        },
        async onUpClick(id) {
            this.loading = true
            await requestItemsUpdateSeq({
                bot_id: this.userProfile.bot_id,
                id,
                formData : {
                    move: 'up',
                } ,
                cbSuccess : res => {
                    this.$toast.success("อัพเดทเรียบร้อย!")
                    this.getAll()
                    this.loading = false
                } ,
                cbError : (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        },
        async getAll() {
            this.loading = true
            await requestItemsList({
                bot_id: this.userProfile.bot_id,
                formData: this.formData,
                cbSuccess: res => {
                    this.tableData = res.data
                    this.loading = false
                } ,
                cbError: (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        },
        deleteStaffModalClick(id) {
            this.activeId = id
            $('#confirmDelete').modal('show')
        },
        async deleteStaff() {
            await requestItemsDelete({
                bot_id: this.userProfile.bot_id,
                id : this.activeId,
                cbSuccess : res => {
                    this.$toast.success("Delete Success!")
                    $('#confirmDelete').modal('hide')
                    this.getAll()
                    this.loading = false
                } ,
                cbError : (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        },
        async setItemActive(id, value) {
            this.loading = true
            await requestItemsUpdate({
                bot_id: this.userProfile.bot_id,
                id,
                formData : {
                    is_active: value ? '1' : '0',
                } ,
                cbSuccess : res => {
                    this.$toast.success("อัพเดทเรียบร้อย!")
                    this.getAll()
                    this.loading = false
                } ,
                cbError : (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        }
    },
}
</script>