<template>
    <no-nav :hideFooter="true" :hideBarDetail="true" :hideBrand="true">
        <div class="wrap">
            <button type="button" class="btn btn-lg btn-primary mb-30 px-12" @click="login">Log in with LINE ccount</button>
            <div >
                <img class="logo" alt="Pic" src="https://cdn.yellow-idea.com/images/line-modular/5214c420-3b05-11eb-a542-036b9cbd1e78.jpeg"/>
            </div>
            <span class="font-size-sm">Copyright @2020 Yellow Idea Co., Ltd All rights reserved.</span>
        </div>
    </no-nav>
</template>

<script>
export default {
    methods: {
        login() {
            window.location.href = `${process.env.VUE_APP_API_END_POINT}/cms/api/line/login?url=${process.env.VUE_APP_REDIRECT_URL}/&is_cms=1`
        }
    }
}
</script>

<style scoped>
.wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
}
.logo {
    width: 130px;
    margin-bottom: 24px;
}
</style>