<template>
   <fluid-content currentPage="Loyalty" subMenu="สร้างคูปอง">
        <loading v-if="loading"/>
        <content-card title="สร้างคูปอง">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <path d="M3,10.0500091 L3,8 C3,7.44771525 3.44771525,7 4,7 L9,7 L9,9 C9,9.55228475 9.44771525,10 10,10 C10.5522847,10 11,9.55228475 11,9 L11,7 L21,7 C21.5522847,7 22,7.44771525 22,8 L22,10.0500091 C20.8588798,10.2816442 20,11.290521 20,12.5 C20,13.709479 20.8588798,14.7183558 22,14.9499909 L22,17 C22,17.5522847 21.5522847,18 21,18 L11,18 L11,16 C11,15.4477153 10.5522847,15 10,15 C9.44771525,15 9,15.4477153 9,16 L9,18 L4,18 C3.44771525,18 3,17.5522847 3,17 L3,14.9499909 C4.14112016,14.7183558 5,13.709479 5,12.5 C5,11.290521 4.14112016,10.2816442 3,10.0500091 Z M10,11 C9.44771525,11 9,11.4477153 9,12 L9,13 C9,13.5522847 9.44771525,14 10,14 C10.5522847,14 11,13.5522847 11,13 L11,12 C11,11.4477153 10.5522847,11 10,11 Z" fill="#000000" opacity="0.3" transform="translate(12.500000, 12.500000) rotate(-45.000000) translate(-12.500000, -12.500000) "/>
                    </g>
                </svg>
            </template>
            <template v-slot:right>
                <button type="button" class="btn btn-outline-secondary" @click="showTip">Tips</button>
            </template>
            <div class="p-8">
                <div class="mb-7 w-40">
                    <add-link to="/loyalty/coupons/form" label="สร้างคูปอง" />
                </div>
                <div class="mb-7">
                    <data-table
                        :headers="headers"
                        :data="tableData"
                        :filter="formData"
                        @change="onTableChange"
                    >
                        <template v-slot:item.index="{ item }">
                            <span>
                                {{(tableData.rows.map(function(x) {return x.id; }).indexOf(item.id) + 1) + formData.offset}}
                            </span>
                        </template>
                        <template v-slot:item.img_url="{ item }">
                            <div class="symbol">
                                <img alt="Pic" :src="item.img_url" @error="replaceByDefault"/>
                            </div>
                        </template>
                        <template v-slot:item.limit_all="{ item }">
                            <span>{{item.limit_all == -1 ? '-' : parseInt(item.limit_all).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span>
                        </template>
                        <template v-slot:item.usage_all="{ item }">
                            <span>{{parseInt(item.usage_all).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span>
                            <span class="text-muted font-size-xs">({{((item.usage_all * 100) / item.limit_all).toFixed(2)}}%)</span>
                        </template>
                        <template v-slot:item.point_cost="{ item }">
                            <span>{{parseInt(item.point_cost).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span>
                        </template>
                        <template v-slot:item.limit_person="{ item }">
                            <span v-if="item.limit_person == 1">จำกัด</span>
                            <span v-else>ไม่จำกัด</span>
                        </template>
                        <template v-slot:item.updated_at="{ item }">
                            <span>{{formatDate(item.updated_at)}}</span>
                        </template>
                        <template v-slot:item.is_active="{ item }">
                            <switch-toggle :value="parseInt(item.is_active)" @input="(value) => setItemActive(item.id, value)"/>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <div class="d-flex">
                                <router-link :to="`/loyalty/coupons/form?id=${item.id}`" class="btn btn-info btn-sm mr-2">
                                    <i class="far fa-edit mr-1"></i>
                                    Edit
                                </router-link>
                                <button type="button" class="btn btn-danger btn-sm" @click="deleteStaffModalClick(item.id)">
                                    <i class="far fa-trash-alt mr-1"></i>
                                    Delete
                                </button>
                            </div>
                        </template>
                        <template v-slot:item.seq_action="{ item }">
                            <button
                                type="button"
                                class="mr-4 btn btn-icon btn-outline-success btn-pill"
                                @click="onUpClick(item.id)"
                                :disabled="tableData.rows.map(function(x) {return x.id; }).indexOf(item.id) === 0"
                            >
                                <i class="ki ki-bold-arrow-up"></i>
                            </button>
                            <button
                                type="button"
                                class="btn btn-icon btn-outline-success btn-pill"
                                @click="onDownClick(item.id)"
                                :disabled="tableData.rows.map(function(x) {return x.id; }).indexOf(item.id) + 1 === tableData.rows.length"
                            >
                                <i class="ki ki-bold-arrow-down"></i>
                            </button>
                        </template>
                    </data-table>
                </div>
            </div>
        </content-card>
        <!-- Modal Confirm -->
        <div class="modal fade" id="confirmDelete" tabindex="-1" role="dialog" aria-labelledby="confirmDelete" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <p class="text-dark-75 font-size-h3 font-weight-bolder">Are you sure?</p>
                        <p class="text-dark-50 font-size-sm">You won't be able to revert this!</p>
                    </div>
                    <div class="modal-footer p-1">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">cancel</button>
                        <button type="button" class="btn btn-primary" @click="deleteStaff">Yes, delete it!</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal-->
        <div class="modal fade" id="tips" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header py-3 px-6">
                        <h5 class="modal-title text-dark-75 font-weight-medium" id="tips">Tips</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <i aria-hidden="true" class="ki ki-close"></i>
                        </button>
                    </div>
                    <div class="modal-body" style="max-height: 660px;">
                        <img class="w-100" src="@/assets/media/project-images/tips/สร้างคูปอง-01.png" alt="tips">
                    </div>
                </div>
            </div>
        </div>
   </fluid-content>
</template>

<script>
import moment from 'moment'

import { requestCouponList, requestCouponDelete, requestCouponUpdate, requestCouponUpdateSeq } from "@/api/coupon"

export default {
    data() {
        const profile = $cookies.get("ADMIN_PROFILE_DATA")
        return {
            userProfile : profile || {},
            loading: false,
            activeId: 0,
            formData: {
                sort: "",
                order: "asc",
                offset: 0,
                limit: 10,
                filter: {},
            },
            tableData: {},
            headers: [
                {
                    text: '#',
                    align: 'center',
                    value: 'index',
                    width: 50,
                    sortable: false,
                },
                {
                    text: 'รูป',
                    align: 'start',
                    value: 'img_url',
                    width: 100,
                    sortable: false,
                },
                {
                    text: 'ชื่อ',
                    align: 'start',
                    value: 'name',
                    width:  150,
                },
                {
                    text: 'จำนวนทั้งหมด',
                    align: 'start',
                    value: 'limit_all',
                    width: 140,
                },
                {
                    text: 'คะแนนที่ใช้แลก',
                    align: 'start',
                    value: 'point_cost',
                    width: 140,
                },
                {
                    text: 'จำกัดสิทธิ์ต่อสมาชิก',
                    value: 'limit_person',
                    width: 170,
                },
                {
                    text: 'วันที่เริ่ม',
                    value: 'start_date',
                    width: 150,
                },
                {
                    text: 'วันที่สิ้นสุด',
                    value: 'end_date',
                    width: 150,
                },
                {
                    text: 'แก้ไขล่าสุด',
                    value: 'updated_at',
                    width: 150,
                },
                {
                    text: 'สถานะ',
                    value: 'is_active',
                    width: 100,
                },
                {
                    text: 'การจัดการ',
                    value: 'actions',
                    sortable: false,
                    width: 220,
                },
                {
                    text: '',
                    align: 'center',
                    value: 'seq_action',
                    width: 150,
                    sortable: false,
                },
            ],
        }
    },
    async mounted() {
        if(this.userProfile.role_id === '1') {
            await this.getAll()
        }
        else {
            this.$router.push('/404')
        }
    },
    methods: {
        onTableChange(offset, limit) {
            this.formData.offset = offset
            this.formData.limit = limit
            this.getAll()
        },
        replaceByDefault(event) {
            event.target.src = this.defaultImg
        },
        async getAll() {
            this.loading = true
            await requestCouponList({
                bot_id: this.userProfile.bot_id,
                formData: this.formData,
                cbSuccess: res => {
                    this.tableData = res.data
                    this.loading = false
                } ,
                cbError: (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        },
        deleteStaffModalClick(id) {
            this.activeId = id
            $('#confirmDelete').modal('show')
        },
        async deleteStaff() {
            await requestCouponDelete({
                bot_id: this.userProfile.bot_id,
                id : this.activeId,
                cbSuccess : res => {
                    this.$toast.success("Delete Success!")
                    $('#confirmDelete').modal('hide')
                    this.getAll()
                    this.loading = false
                } ,
                cbError : (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        },
        async setItemActive(id, value) {
            this.loading = true
            await requestCouponUpdate({
                bot_id: this.userProfile.bot_id,
                id,
                formData : {
                    is_active: value ? '1' : '0',
                } ,
                cbSuccess : res => {
                    this.$toast.success("อัพเดทเรียบร้อย!")
                    this.getAll()
                    this.loading = false
                } ,
                cbError : (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        },
        async onDownClick(id) {
            this.loading = true
            await requestCouponUpdateSeq({
                bot_id: this.userProfile.bot_id,
                id,
                formData : {
                    move: 'down',
                } ,
                cbSuccess : res => {
                    this.$toast.success("อัพเดทเรียบร้อย!")
                    this.getAll()
                    this.loading = false
                } ,
                cbError : (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        },
        async onUpClick(id) {
            this.loading = true
            await requestCouponUpdateSeq({
                bot_id: this.userProfile.bot_id,
                id,
                formData : {
                    move: 'up',
                } ,
                cbSuccess : res => {
                    this.$toast.success("อัพเดทเรียบร้อย!")
                    this.getAll()
                    this.loading = false
                } ,
                cbError : (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        },
        formatDate(date, type) {
            const newDate = moment(date.substring(0, 10)).format("DD/MM/YYYY")
            const newTime = date.substring(11, 16)
            if(type === 'date') {
                return newDate
            }
            else if (type === 'time') {
                return newTime
            }
            else {
                return newDate + ' ' + newTime
            }
        },
        showTip() {
            $('#tips').modal('show')
        },
    },
}
</script>
