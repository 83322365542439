<template>
   <fluid-content currentPage="สรุปภาพรวม">
        <loading v-if="loading"/>
        <content-card title="สรุปภาพรวม">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <rect fill="#000000" x="4" y="4" width="7" height="7" rx="1.5"/>
                        <path d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z" fill="#000000" opacity="0.3"/>
                    </g>
                </svg>
            </template>
            <div class="p-8">
                <div class="row align-items-center mb-lg-2">
                    <div class="text-dark-75 font-weight-bolder font-size-lg pl-4 pr-4">ข้อมูลภาพรวม</div>
                    <div class="col-lg-4 col-sm-12">
                        <datepicker-range v-model="searchDatesOverAll" :required="false" />
                    </div>
                    <div class="col-8 col-lg-4 mt-0">
                        <button type="button" class="btn btn-icon btn-light-primary" @click="getOverallDetail">
                            <i class="la la-search"></i>
                        </button>
                        <button type="button" class="btn btn-light ml-2" @click="clearFilter('OverallDetail')">
                            Clear filter
                        </button>
                    </div>
                </div>
                <div class="row" v-if="overAll">
                    <div class="col-lg-3">
                        <div class="dashbord-card">
                            <div class="text-dark-50 font-weight-bolder font-size-lg mb-8">จำนวนผู้ใช้งาน</div>
                            <div class="text-dark-75 font-weight-bolder font-size-h1">{{(overAll.total_user).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</div>
                            <div class="icon icon_01">
                                <span class="svg-icon svg-icon-2x svg-icon-info">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24"/>
                                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fill-rule="nonzero"/>
                                        </g>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="dashbord-card">
                            <div class="text-dark-50 font-weight-bolder font-size-lg mb-8">จำนวนคนแลกคูปอง</div>
                            <div class="text-dark-75 font-weight-bolder font-size-h1">{{(overAll.total_coupon_redeem).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</div>
                            <div class="icon icon_02">
                                <span class="svg-icon svg-icon-2x svg-icon-warning">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <rect x="0" y="0" width="24" height="24"/>
                                            <path d="M4,9.67471899 L10.880262,13.6470401 C10.9543486,13.689814 11.0320333,13.7207107 11.1111111,13.740321 L11.1111111,21.4444444 L4.49070127,17.526473 C4.18655139,17.3464765 4,17.0193034 4,16.6658832 L4,9.67471899 Z M20,9.56911707 L20,16.6658832 C20,17.0193034 19.8134486,17.3464765 19.5092987,17.526473 L12.8888889,21.4444444 L12.8888889,13.6728275 C12.9050191,13.6647696 12.9210067,13.6561758 12.9368301,13.6470401 L20,9.56911707 Z" fill="#000000"/>
                                            <path d="M4.21611835,7.74669402 C4.30015839,7.64056877 4.40623188,7.55087574 4.5299008,7.48500698 L11.5299008,3.75665466 C11.8237589,3.60013944 12.1762411,3.60013944 12.4700992,3.75665466 L19.4700992,7.48500698 C19.5654307,7.53578262 19.6503066,7.60071528 19.7226939,7.67641889 L12.0479413,12.1074394 C11.9974761,12.1365754 11.9509488,12.1699127 11.9085461,12.2067543 C11.8661433,12.1699127 11.819616,12.1365754 11.7691509,12.1074394 L4.21611835,7.74669402 Z" fill="#000000" opacity="0.3"/>
                                        </g>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="dashbord-card">
                            <div class="text-dark-50 font-weight-bolder font-size-lg mb-8">คะแนนทั้งหมดที่แลก</div>
                            <div class="text-dark-75 font-weight-bolder font-size-h1">{{(overAll.total_point_usage).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</div>
                            <div class="icon icon_03">
                                <span class="svg-icon svg-icon-2x svg-icon-success">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <rect x="0" y="0" width="24" height="24"/>
                                            <path d="M4,6 L20,6 C20.5522847,6 21,6.44771525 21,7 L21,8 C21,8.55228475 20.5522847,9 20,9 L4,9 C3.44771525,9 3,8.55228475 3,8 L3,7 C3,6.44771525 3.44771525,6 4,6 Z M5,11 L10,11 C10.5522847,11 11,11.4477153 11,12 L11,19 C11,19.5522847 10.5522847,20 10,20 L5,20 C4.44771525,20 4,19.5522847 4,19 L4,12 C4,11.4477153 4.44771525,11 5,11 Z M14,11 L19,11 C19.5522847,11 20,11.4477153 20,12 L20,19 C20,19.5522847 19.5522847,20 19,20 L14,20 C13.4477153,20 13,19.5522847 13,19 L13,12 C13,11.4477153 13.4477153,11 14,11 Z" fill="#000000"/>
                                            <path d="M14.4452998,2.16794971 C14.9048285,1.86159725 15.5256978,1.98577112 15.8320503,2.4452998 C16.1384028,2.90482849 16.0142289,3.52569784 15.5547002,3.83205029 L12,6.20185043 L8.4452998,3.83205029 C7.98577112,3.52569784 7.86159725,2.90482849 8.16794971,2.4452998 C8.47430216,1.98577112 9.09517151,1.86159725 9.5547002,2.16794971 L12,3.79814957 L14.4452998,2.16794971 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                                        </g>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="dashbord-card">
                            <div class="text-dark-50 font-weight-bolder font-size-lg mb-8">ยอดขายรวม</div>
                            <div class="text-dark-75 font-weight-bolder font-size-h1">{{(overAll.total_sale_amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</div>
                            <div class="icon icon_04">
                                <span class="svg-icon svg-icon-2x svg-icon-danger">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <rect x="0" y="0" width="24" height="24"/>
                                            <path d="M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z" fill="#000000" opacity="0.3" transform="translate(11.500000, 12.000000) rotate(-345.000000) translate(-11.500000, -12.000000) "/>
                                            <path d="M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z M11.5,14 C12.6045695,14 13.5,13.1045695 13.5,12 C13.5,10.8954305 12.6045695,10 11.5,10 C10.3954305,10 9.5,10.8954305 9.5,12 C9.5,13.1045695 10.3954305,14 11.5,14 Z" fill="#000000"/>
                                        </g>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="separator separator-solid my-6"></div>
                <div class="row" v-if="pointData">
                    <div class="col-12">
                        <div class="dashbord-card">
                            <div class="row align-items-center mb-lg-2">
                                <div class="text-dark-75 font-weight-bolder font-size-lg pl-4 pr-4">ความเคลื่อนไหวของคะแนน</div>
                                <div class="col-lg-4 col-sm-12">
                                    <datepicker-range v-model="searchDatesPoint" :required="false" />
                                </div>
                                <div class="col-8 col-lg-4 mt-0">
                                    <button type="button" class="btn btn-icon btn-light-primary" @click="getPointDetail">
                                        <i class="la la-search"></i>
                                    </button>
                                    <button type="button" class="btn btn-light ml-2" @click="clearFilter('PointDetail')">
                                        Clear filter
                                    </button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-9 col-sm-12">
                                    <vertical-chart :options="options" :chartData="chartData"  v-if="!chartLoading"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="dashbord-card">
                            <div class="row align-items-center mb-lg-2">
                                <div class="text-dark-75 font-weight-bolder font-size-lg pl-4 pr-4">สินค้าขายดี 10 อันดับ</div>
                                <div class="col-lg-4 col-sm-12">
                                    <datepicker-range v-model="searchDatesItems" :required="false" />
                                </div>
                                <div class="col-8 col-lg-4 mt-0">
                                    <button type="button" class="btn btn-icon btn-light-primary" @click="getItemsDetail">
                                        <i class="la la-search"></i>
                                    </button>
                                    <button type="button" class="btn btn-light ml-2" @click="clearFilter('ItemsDetail')">
                                        Clear filter
                                    </button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-lg-5 responsive-table">
                                    <table class="table table-hover">
                                        <thead class="thead-light">
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">ชื่อ</th>
                                                <th scope="col">จำนวน</th>
                                                <th scope="col">ยอดรวม</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="bestSellerOne.length === 0">
                                            <tr>
                                                <td colspan="4" class="text-center">ไม่พบข้อมูล</td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr v-for="(item, index) in bestSellerOne" :key="index">
                                                <th scope="row">{{index + 1}}</th>
                                                <td><div class="text-ellipsis" style="width: 200px;">{{item.name}}</div></td>
                                                <td>{{parseInt(item.sale_count).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</td>
                                                <td>{{parseInt(item.sale_amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-sm-12 col-lg-5 offset-lg-1 responsive-table">
                                    <table class="table table-hover">
                                        <thead class="thead-light">
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">ชื่อ</th>
                                                <th scope="col">จำนวน</th>
                                                <th scope="col">ยอดรวม</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="bestSellerTwo.length === 0">
                                            <tr>
                                                <td colspan="4" class="text-center">ไม่พบข้อมูล</td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr v-for="(item, index) in bestSellerTwo" :key="index">
                                                <th scope="row">{{index + 6}}</th>
                                                <td><div class="text-ellipsis" style="width: 200px;">{{item.name}}</div></td>
                                                <td>{{parseInt(item.sale_count).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</td>
                                                <td>{{parseInt(item.sale_amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="dashbord-card">
                            <div class="row align-items-center mb-lg-2">
                                <div class="text-dark-75 font-weight-bolder font-size-lg pl-4 pr-4">คูปองยอดนิยม 10 อันดับ</div>
                                <div class="col-lg-4 col-sm-12">
                                    <datepicker-range v-model="searchDatesCoupon" :required="false" />
                                </div>
                                <div class="col-8 col-lg-4 mt-0">
                                    <button type="button" class="btn btn-icon btn-light-primary" @click="getCouponDetail">
                                        <i class="la la-search"></i>
                                    </button>
                                    <button type="button" class="btn btn-light ml-2" @click="clearFilter('CouponDetail')">
                                        Clear filter
                                    </button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-lg-5 responsive-table">
                                    <table class="table table-hover">
                                        <thead class="thead-light">
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">รูป</th>
                                                <th scope="col">ชื่อ</th>
                                                <th scope="col">จำนวน</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="couponPopularOne.length === 0">
                                            <tr>
                                                <td colspan="4" class="text-center">ไม่พบข้อมูล</td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr v-for="(item, index) in couponPopularOne" :key="index">
                                                <th scope="row" style="vertical-align: middle;">{{index + 1}}</th>
                                                <td>
                                                    <div class="symbol mr-3">
                                                        <img alt="Pic" :src="item.img_url" @error="replaceByDefault" style="object-fit: contain;"/>
                                                    </div>
                                                </td>
                                                <td style="vertical-align: middle;"><div class="text-ellipsis" style="width: 200px;">{{item.name}}</div></td>
                                                <td style="vertical-align: middle;">{{parseInt(item.count).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-sm-12 col-lg-5 offset-lg-1 responsive-table">
                                    <table class="table table-hover">
                                        <thead class="thead-light">
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">ชื่อ</th>
                                                <th scope="col">จำนวน</th>
                                                <th scope="col">ยอดรวม</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="couponPopularTwo.length === 0">
                                            <tr>
                                                <td colspan="4" class="text-center">ไม่พบข้อมูล</td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr v-for="(item, index) in couponPopularTwo" :key="index">
                                                <th scope="row" style="vertical-align: middle;">{{index + 6}}</th>
                                                <td>
                                                    <div class="symbol mr-3">
                                                        <img alt="Pic" :src="item.img_url" @error="replaceByDefault" style="object-fit: contain;"/>
                                                    </div>
                                                </td>
                                                <td style="vertical-align: middle;"><div class="text-ellipsis" style="width: 200px;">{{item.name}}</div></td>
                                                <td style="vertical-align: middle;">{{parseInt(item.count).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </content-card>
   </fluid-content>
</template>

<script>
import VerticalChart from '@/components/charts/VerticalChart'

import { requestDashboardOverallDetail, requestDashboardPointDetail, requestDashboardItemsDetail, requestDashboardCouponDetail } from "@/api/dashboard"

export default {
    components: {
        VerticalChart,
    },
    computed: {
        bestSellerOne() {
            return this.best_seller.filter((item, index) => index < 5)
        },
        bestSellerTwo() {
            return this.best_seller.filter((item, index) => index > 4)
        },
        couponPopularOne() {
            return this.coupon_popular.filter((item, index) => index < 5)
        },
        couponPopularTwo() {
            return this.coupon_popular.filter((item, index) => index > 4)
        },
    },
    data() {
        const profile = $cookies.get("ADMIN_PROFILE_DATA")
        return {
            userProfile : profile || {},
            loading: false,
            defaultImg: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
            searchDatesOverAll: [],
            searchDatesPoint: [],
            searchDatesItems: [],
            searchDatesCoupon: [],
            chartLoading: false,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                tooltips: {
                    mode: 'index',
                }
            },
            overAll: null,
            pointData: null,
            chartData: {
                labels: [],
                datasets: [
                    {
                        label: 'พอยท์ที่ได้รับ',
                        backgroundColor: 'rgba(0, 126, 51, 0.7)',
                        borderColor: 'rgba(0, 126, 51, 0.7)',
                        borderWidth: 1,
                        data: []
                    },
                    {
                        label: 'พอยท์ที่ใช้ไป',
                        backgroundColor: 'rgba(204, 0, 0, 0.7)',
                        borderColor: 'rgba(204, 0, 0, 0.7)',
                        borderWidth: 1,
                        data: []
                    },
                ]
            },
            best_seller: [],
            coupon_popular: []
        }
    },
    async mounted() {
        if(this.userProfile.role_id === '1') {
            await this.getAll()
        }
        else {
            this.$router.push('/404')
        }
    },
    methods: {
        async getAll() {
            this.getOverallDetail()
            this.getPointDetail()
            this.getItemsDetail()
            this.getCouponDetail()
        },
        async getOverallDetail() {
            this.loading = true
            await requestDashboardOverallDetail({
                bot_id: this.userProfile.bot_id,
                formData: {
                    sort: "",
                    order: "asc",
                    offset: 0,
                    limit: 99999,
                    filter: {
                        start_date: this.searchDatesOverAll[0] || '',
                        end_date: this.searchDatesOverAll[1] || '',
                    }
                },
                cbSuccess: res => {
                    this.overAll = res.data.data
                    this.loading = false
                } ,
                cbError: (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        },
        async getPointDetail() {
            this.loading = true
            this.chartLoading = true
            await requestDashboardPointDetail({
                bot_id: this.userProfile.bot_id,
                formData: {
                    sort: "",
                    order: "asc",
                    offset: 0,
                    limit: 99999,
                    filter: {
                        start_date: this.searchDatesPoint[0] || '',
                        end_date: this.searchDatesPoint[1] || '',
                    }
                },
                cbSuccess: res => {
                    this.chartData.labels = res.data.rows.map(item => item.date)
                    this.chartData.datasets[0].data = res.data.rows.map(item => item.point_earned)
                    this.chartData.datasets[1].data = res.data.rows.map(item => item.point_spent)
                    this.pointData = res.data
                    this.loading = false
                    this.chartLoading = false
                } ,
                cbError: (e , msg) => {
                    this.loading = false
                    this.chartLoading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        },
        async getItemsDetail() {
            this.loading = true
            await requestDashboardItemsDetail({
                bot_id: this.userProfile.bot_id,
                formData: {
                    sort: "",
                    order: "asc",
                    offset: 0,
                    limit: 99999,
                    filter: {
                        start_date: this.searchDatesItems[0] || '',
                        end_date: this.searchDatesItems[1] || '',
                    }
                },
                cbSuccess: res => {
                    this.best_seller = res.data.rows
                    this.loading = false
                } ,
                cbError: (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        },
        async getCouponDetail() {
            this.loading = true
            await requestDashboardCouponDetail({
                bot_id: this.userProfile.bot_id,
                formData: {
                    sort: "",
                    order: "asc",
                    offset: 0,
                    limit: 99999,
                    filter: {
                        start_date: this.searchDatesCoupon[0] || '',
                        end_date: this.searchDatesCoupon[1] || '',
                    }
                },
                cbSuccess: res => {
                    this.coupon_popular = res.data.rows
                    this.loading = false
                } ,
                cbError: (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        },
        clearFilter(value) {
            if(value === 'OverallDetail') {
                this.searchDatesOverAll = []
                this.getOverallDetail()
            }
            else if(value === 'PointDetail') {
                this.searchDatesPoint = []
                this.getPointDetail()
            }
            else if(value === 'ItemsDetail') {
                this.searchDatesItems = []
                this.getItemsDetail()
            }
            else {
                this.searchDatesCoupon = []
                this.getCouponDetail()
            }
        },
        replaceByDefault(event) {
            event.target.src = this.defaultImg
        }
    },
}
</script>

<style scoped>
.dashbord-card {
    border-radius: 14px;
    box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.05);
    padding: 20px;
    position: relative;
}
.dashbord-card .icon {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 10px;
    border-radius: 100%;
}
.dashbord-card .icon.icon_01 {
    background-color: rgba(17, 94, 207, 0.3);
}
.dashbord-card .icon.icon_02 {
    background-color: rgba(255, 168, 0, 0.3);
}
.dashbord-card .icon.icon_03 {
    background-color: rgba(27, 197, 189, 0.3);
}
.dashbord-card .icon.icon_04 {
    background-color: rgba(246, 78, 96, 0.3);
}
.responsive-table {
    overflow: auto;
}
</style>