<template>
    <div class="header-fixed header-mobile-fixed subheader-fixed aside-enabled aside-fixed aside-minimize-hoverable">
        <div class="d-flex flex-column flex-root">
            <div class="d-flex flex-row flex-column-fluid page">
                <nav-bar :currentPage="currentPage" :subMenu="subMenu" />
                <div class="d-flex flex-column flex-row-fluid wrapper" :class="{'content-minimize': sidebar}" >
                    <header-bar v-if="!isMobile()" :hideBrand="true"/>
                    <header-mobile v-else/>
                    <div class="content d-flex flex-column flex-column-fluid pb-30 py-0">
						<div class="container px-0" :style="`padding-bottom: ${isMobile() ? '50px' : hasButton ? '88px' : '0px'};`">
                            <slot />
						</div>
					</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['currentPage', 'subMenu', 'hasButton'],
    computed: {
        sidebar() {
            return this.$store.state.sidebar;
        },
    },
    methods: {
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
}
</script>
<style>
.content-minimize {
    padding-left: 70px !important;
}
</style>