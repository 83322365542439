<template>
    <div class="input-group">
        <div class="form-control disabled" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ value ? value : placeholder }}</div>
        <div class="input-group-append">
            <span class="btn btn-secondary" @click="doCopy">
                <i class="la la-copy"></i>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['value', 'placeholder'],
    methods: {
        doCopy() {
            this.$copyText(this.value)
                .then((e) => {
                    this.$toast.success("Copied!")
                },(e) => {
                    alert('Can not copy')
                    this.$toast.success("Can not copy!")
                    console.log('Can not copy:', e)
                })
        }
    },
}
</script>