<template>
    <no-nav :hideFooter="true" :hideBarDetail="true" :hideBrand="true">
        <div class="wrap">
            <div style="width: 300px">
                <img class="logo w-100" alt="Pic" src="https://cdn.yellow-idea.com/images/line-modular/5214c420-3b05-11eb-a542-036b9cbd1e78.jpeg"/>
            </div>
            <button type="button" class="btn btn-lg btn-primary mb-30 mt-26 px-12 line-login" @click="login">
                <img src="@/assets/media/project-images/line_88.png" alt="">
                <span>Log in with LINE</span>
            </button>
            <div style="width: 80px">
                <img class="logo w-100" alt="Pic" src="@/assets/media/logos/yellow_logo_mini.png"/>
            </div>
            <span class="font-size-sm">Copyright @2020 Yellow Idea Co., Ltd All rights reserved.</span>
            <span class="font-size-sm"></span>
        </div>
    </no-nav>
</template>

<script>
export default {
    methods: {
        login() {
            window.location.href = `${process.env.VUE_APP_API_END_POINT}/cms/api/line/login?url=${process.env.VUE_APP_REDIRECT_URL}/&is_cms=1`
        }
    }
}
</script>

<style scoped>
.wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
}
.logo {
    width: 130px;
    margin-bottom: 24px;
}
.line-login {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    padding: 0 !important;
    background-color: #00C300;
}
.line-login:hover {
    background-color: #00E000;
}
.line-login:focus {
    background-color: #00B300;
}
.line-login img {
    width: 48px;
    border-right: 1px solid #00b300;
}
.line-login span {
    display: inline-block;
    padding: 0 30px;
}
</style>