import router from '@/router'

export default function isLoggedIn ({ next, store }){
    const profile = $cookies.get("ADMIN_PROFILE_DATA")
    if(!profile){
        window.location.href = `${process.env.VUE_APP_API_END_POINT}/cms/api/line/login?url=${process.env.VUE_APP_REDIRECT_URL}/&is_cms=1`
    }
    else if(profile && !profile.bot_id) {
        router.push('/account')
    }
    else {
        return next()
    }
   
}