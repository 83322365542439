<template>
    <fluid-content currentPage="จัดการพนักงาน">
        <loading v-if="loading"/>
        <content-card title="จัดการพนักงาน">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24"/>
                        <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                        <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fill-rule="nonzero"/>
                    </g>
                </svg>
            </template>
            <template v-slot:right>
                <button type="button" class="btn btn-outline-secondary" @click="showTip">Tips</button>
            </template>
            <div class="p-8">
                <div class="mb-7 w-100">
                    <add-link to="/loyalty/staff/form" label="เพิ่มสิทธิ์พนักงาน" />
                </div>
                <div class="mb-7">
                    <data-table
                        :headers="headers"
                        :data="tableData"
                        :filter="formData"
                        @change="onTableChange"
                    >
                        <template v-slot:item.index="{ item }">
                            <span>
                                {{(tableData.rows.map(function(x) {return x.id; }).indexOf(item.id) + 1) + formData.offset}}
                            </span>
                        </template>
                        <template v-slot:item.line_img_url="{ item }">
                            <div class="symbol">
                                <img alt="Pic" :src="item.line_img_url || defaultImg" @error="replaceByDefault"/>
                            </div>
                        </template>
                        <template v-slot:item.line_display_name="{ item }">
                            <span>{{item.line_display_name || '-'}}</span>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <div class="d-flex">
                                <router-link :to="`/loyalty/staff/form?id=${item.id}`" class="btn btn-info btn-sm mr-2">
                                    <i class="far fa-edit mr-1"></i>
                                    Edit
                                </router-link>
                                <button type="button" class="btn btn-danger btn-sm" @click="deleteStaffModalClick(item.id)">
                                    <i class="far fa-trash-alt mr-1"></i>
                                    Delete
                                </button>
                            </div>
                        </template>
                    </data-table>
                </div>
            </div>
        </content-card>
        <!-- Modal Confirm -->
        <div class="modal fade" id="confirmDelete" tabindex="-1" role="dialog" aria-labelledby="confirmDelete" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <p class="text-dark-75 font-size-h3 font-weight-bolder">Are you sure?</p>
                        <p class="text-dark-50 font-size-sm">You won't be able to revert this!</p>
                    </div>
                    <div class="modal-footer p-1">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">cancel</button>
                        <button type="button" class="btn btn-primary" @click="deleteStaff">Yes, delete it!</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal-->
        <div class="modal fade" id="tips" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header py-3 px-6">
                        <h5 class="modal-title text-dark-75 font-weight-medium" id="tips">Tips</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <i aria-hidden="true" class="ki ki-close"></i>
                        </button>
                    </div>
                    <div class="modal-body" style="max-height: 660px;">
                        <img class="w-100" src="@/assets/media/project-images/tips/จัดการพนักงาน-01.png" alt="tips">
                    </div>
                </div>
            </div>
        </div>
    </fluid-content>
</template>

<script>
import { requestStaffList, requestStaffDelete } from "@/api/staff"

export default {
    data() {
        const profile = $cookies.get("ADMIN_PROFILE_DATA")
        return {
            userProfile : profile || {},
            loading: false,
            defaultImg: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
            activeId: 0,
            formData: {
                sort: "",
                order: "asc",
                offset: 0,
                limit: 10,
                filter: {},
            },
            tableData: {},
            headers: [
                {
                    text: '#',
                    align: 'center',
                    value: 'index',
                    width: 50,
                    sortable: false,
                },
                {
                    text: 'รูป LINE',
                    align: 'start',
                    value: 'line_img_url',
                    width: 100,
                    sortable: false,
                },
                {
                    text: 'ชื่อ LINE',
                    value: 'line_display_name',
                    width: 150,
                },
                {
                    text: 'ชื่อพนักงาน',
                    value: 'first_name',
                    width: 150,
                },
                {
                    text: 'นามสกุลพนักงาน',
                    value: 'last_name',
                    width: 150,
                },
                {
                    text: 'สาขา',
                    value: 'branch',
                    width: 150,
                },
                {
                    text: 'การจัดการ',
                    value: 'actions',
                    sortable: false,
                    width: 200,
                },
            ],
        }
    },
    async mounted() {
        if(this.userProfile.role_id === '1') {
            await this.getAll()
        }
        else {
            this.$router.push('/404')
        }
    },
    methods: {
        onTableChange(offset, limit) {
            this.formData.offset = offset
            this.formData.limit = limit
            this.getAll()
        },
        replaceByDefault(event) {
            event.target.src = this.defaultImg
        },
        async getAll() {
            this.loading = true
            await requestStaffList({
                bot_id: this.userProfile.bot_id,
                formData: this.formData,
                cbSuccess: res => {
                    this.tableData = res.data
                    this.loading = false
                } ,
                cbError: (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        },
        deleteStaffModalClick(id) {
            this.activeId = id
            $('#confirmDelete').modal('show')
        },
        async deleteStaff() {
            await requestStaffDelete({
                bot_id: this.userProfile.bot_id,
                id : this.activeId,
                cbSuccess : res => {
                    this.$toast.success("Delete Success!")
                    $('#confirmDelete').modal('hide')
                    this.getAll()
                    this.loading = false
                } ,
                cbError : (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        },
        showTip() {
            $('#tips').modal('show')
        },
    },
}
</script>