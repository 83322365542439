import Vue from 'vue'

import FluidContent from '@/components/layouts/FluidContent'
import NoNav from '@/components/layouts/NoNav'
import ContentCard from '@/components/ContentCard'
import SearchInput from '@/components/SearchInput'
import DatepickerRange from '@/components/DatepickerRange'
import Datepicker from '@/components/Datepicker'
import Timepicker from '@/components/Timepicker'
import Colorpicker from '@/components/Colorpicker'
import AddLink from '@/components/AddLink'
import Tab from '@/components/Tab'
import DataTable from '@/components/DataTable'
import NavBar from '@/components/Nav'
import NavGroup from '@/components/NavGroup'
import NavItem from '@/components/NavItem'
import HeaderBar from '@/components/Header'
import HeaderModile from '@/components/HeaderModile'
import Clipboard from '@/components/Clipboard'
import Radio from '@/components/Radio'
import UploadImage from '@/components/UploadImage'
import Texteditor from '@/components/Texteditor'
import SwitchToggle from '@/components/SwitchToggle'
import SelectOption from '@/components/SelectOption'
import ColumnChart from '@/components/charts/ColumnChart'
import PieChart from '@/components/charts/PieChart'
import GenderChart from '@/components/charts/Gender'
import Loading from '@/components/Loading'

Vue.component('fluid-content', FluidContent)
Vue.component('no-nav', NoNav)
Vue.component('content-card', ContentCard)
Vue.component('search-input', SearchInput)
Vue.component('datepicker-range', DatepickerRange)
Vue.component('datepicker', Datepicker)
Vue.component('timepicker', Timepicker)
Vue.component('colorpicker', Colorpicker)
Vue.component('add-link', AddLink)
Vue.component('tab', Tab)
Vue.component('data-table', DataTable)
Vue.component('nav-bar', NavBar)
Vue.component('nav-group', NavGroup)
Vue.component('nav-item', NavItem)
Vue.component('header-bar', HeaderBar)
Vue.component('header-mobile', HeaderModile)
Vue.component('clipboard', Clipboard)
Vue.component('radio', Radio)
Vue.component('upload-image', UploadImage)
Vue.component('texteditor', Texteditor)
Vue.component('switch-toggle', SwitchToggle)
Vue.component('select-option', SelectOption)
Vue.component('column-chart', ColumnChart)
Vue.component('pie-chart', PieChart)
Vue.component('gender-chart', GenderChart)
Vue.component('loading', Loading)