<template>
    <fluid-content currentPage="จัดการสิทธิ์ผู้ดูแลระบบ">
        <loading v-if="loading"/>
        <content-card title="จัดการสิทธิ์ผู้ดูแลระบบ">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24"/>
                        <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                        <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fill-rule="nonzero"/>
                    </g>
                </svg>
            </template>
            <template v-slot:right>
                <button type="button" class="btn btn-outline-secondary" @click="showTip">Tips</button>
            </template>
            <div class="p-8">
                <div class="mb-7 w-100">
                    <add-link to="/loyalty/admin/form" label="เพิ่มสิทธิ์ผู้ดูแลระบบ" />
                </div>
                <div class="mb-7">
                    <data-table
                        :headers="headers"
                        :data="tableData"
                        :filter="formData"
                        @change="onTableChange"
                    >
                        <template v-slot:item.index="{ item }">
                            <span>
                                {{(tableData.rows.map(function(x) {return x.id; }).indexOf(item.id) + 1) + formData.offset}}
                            </span>
                        </template>
                        <template v-slot:item.line_img_url="{ item }">
                            <div class="symbol">
                                <img alt="Pic" :src="item.line_img_url || defaultImg" @error="replaceByDefault"/>
                            </div>
                        </template>
                        <template v-slot:item.line_display_name="{ item }">
                            <span>{{item.line_display_name || '-'}}</span>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <div class="d-flex" v-if="item.mid !== userProfile.mid">
                                <button type="button" class="btn btn-danger btn-sm" @click="deleteStaffModalClick(item.id)">
                                    <i class="far fa-trash-alt mr-1"></i>
                                    Delete
                                </button>
                            </div>
                        </template>
                        <template v-slot:item.role_id="{ item }">
                            <span v-if="item.role_id === '1'">แอดมิน</span>
                            <span v-else-if="item.role_id === '2'">ให้คะแนนสมาชิก</span>
                            <span v-else>รายงาน</span>
                        </template>
                    </data-table>
                </div>
            </div>
        </content-card>
        <!-- Modal Confirm -->
        <div class="modal fade" id="confirmDelete" tabindex="-1" role="dialog" aria-labelledby="confirmDelete" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <p class="text-dark-75 font-size-h3 font-weight-bolder">Are you sure?</p>
                        <p class="text-dark-50 font-size-sm">You won't be able to revert this!</p>
                    </div>
                    <div class="modal-footer p-1">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">cancel</button>
                        <button type="button" class="btn btn-primary" @click="deleteStaff">Yes, delete it!</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal-->
        <div class="modal fade" id="tips" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header py-3 px-6">
                        <h5 class="modal-title text-dark-75 font-weight-medium" id="tips">Tips</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <i aria-hidden="true" class="ki ki-close"></i>
                        </button>
                    </div>
                    <div class="modal-body" style="max-height: 660px;">
                        <img class="w-100" src="@/assets/media/project-images/tips/จัดการสิทธิ์ผู้ดูแลระบบ-01.png" alt="tips">
                    </div>
                </div>
            </div>
        </div>
    </fluid-content>
</template>

<script>
import { requestAdminList, requestAdminDelete } from "@/api/admin"

export default {
    data() {
        const profile = $cookies.get("ADMIN_PROFILE_DATA")
        return {
            userProfile : profile || {},
            loading: false,
            defaultImg: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
            activeId: 0,
            formData: {
                sort: "",
                order: "asc",
                offset: 0,
                limit: 10,
                filter: {},
            },
            tableData: {},
            headers: [
                {
                    text: '#',
                    align: 'center',
                    value: 'index',
                    width: 50,
                    sortable: false,
                },
                {
                    text: 'รูป LINE',
                    align: 'start',
                    value: 'line_img_url',
                    width: 100,
                    sortable: false,
                },
                {
                    text: 'ชื่อ LINE',
                    value: 'line_display_name',
                    width: 200,
                },
                {
                    text: 'ชื่อพนักงาน',
                    value: 'name',
                    width: 200,
                },
                {
                    text: 'ประเภทสิทธิ์',
                    value: 'role_id',
                    width: 200,
                },
                {
                    text: 'การจัดการ',
                    value: 'actions',
                    sortable: false,
                    width: 130,
                },
            ],
        }
    },
    async mounted() {
        if(this.userProfile.role_id === '1') {
            await this.getAll()
        }
        else {
            this.$router.push('/404')
        }
    },
    methods: {
        onTableChange(offset, limit) {
            this.formData.offset = offset
            this.formData.limit = limit
            this.getAll()
        },
        replaceByDefault(event) {
            event.target.src = this.defaultImg
        },
        async getAll() {
            this.loading = true
            await requestAdminList({
                bot_id: this.userProfile.bot_id,
                formData: this.formData,
                cbSuccess: res => {
                    this.tableData = res.data
                    this.loading = false
                } ,
                cbError: (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        },
        deleteStaffModalClick(id) {
            this.activeId = id
            $('#confirmDelete').modal('show')
        },
        async deleteStaff() {
            await requestAdminDelete({
                bot_id: this.userProfile.bot_id,
                id : this.activeId,
                cbSuccess : res => {
                    this.$toast.success("Delete Success!")
                    $('#confirmDelete').modal('hide')
                    this.getAll()
                    this.loading = false
                } ,
                cbError : (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        },
        showTip() {
            $('#tips').modal('show')
        },
    },
}
</script>
