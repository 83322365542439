<template>
    <no-nav :hideFooter="true" :hideBarDetail="true" :hideBrand="true">
        <div class="wrap">
            <div class="text-dark font-size-h1 font-weight-bolder">Link Error</div>
            <!-- <div class="text-dark font-size-h1 font-weight-bolder">Please try again later.</div> -->
            <div class="mt-20">
                <img class="logo" alt="Pic" src="https://cdn.yellow-idea.com/images/line-modular/5214c420-3b05-11eb-a542-036b9cbd1e78.jpeg"/>
            </div>
            <span class="font-size-sm">Copyright @2020 Yellow Idea Co., Ltd All rights reserved.</span>
        </div>
    </no-nav>
</template>

<script>
    export default {
    }
</script>

<style scoped>
.wrap {
    color: #7c7a7a;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.logo {
    width: 130px;
    margin-bottom: 24px;
}
</style>
