<template>
    <div class="wrap" :style="`width: ${previewWidth}; height: ${previewHeight}`" v-if="value !== undefined">
        <input
            type="file"
            @change="onFileChange"
            id="uploader"
            accept="image/png, image/jpeg"
        />

        <div class="card card-custom overlay" v-if="url">
            <div class="card-body p-0">
                <div class="overlay-wrapper ">
                    <img :src="url" alt="" class="w-100 rounded"/>
                </div>
                <div class="overlay-layer">
                    <button type="button" class="btn btn-clean btn-icon mr-2 upload-button">
                        <input type="file" @change="onFileChange" />
                        <i class="far fa-edit icon-lg text-success"></i>
                    </button>
                    <button @click="onImageRemoveClick" type="button" class="btn btn-clean btn-icon">
                        <i class="fas fa-trash-alt icon-lg text-success"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-center preview-default pt-7 pb-7" v-else>
            <img :src="defaultImage ? defaultImage : require('@/assets/media/default-image.png')" alt="default preview">
        </div>
    </div>
</template>

<script>
import { requestUploadImage } from '@/api/apis'

export default {
    props: ['width', 'height', 'size', 'value', 'previewWidth', 'previewHeight', 'defaultImage'],
    data() {
        return {
            url: null,
        }
    },
    mounted() {
        if(this.value !== undefined) {
            this.url = this.value
        }
    },
    updated() {
        if(this.value !== undefined) {
            this.url = this.value
        }
    },
    methods: {
        onFileChange(e) {
            const file = e.target.files[0];
            if(file.size > (parseInt(this.size) * 1000000)) {
                this.$toast.error(`Maximum upload file size is ${this.size} MB`)
                return
            }
            let reader = new FileReader()
            reader.onload = async (event) => {
                await requestUploadImage({
                    formData : {
                        img_url: event.target.result,
                    },
                    cbSuccess : res => {
                        this.$emit('input', res.data.img_url)
                    } ,
                    cbError : (_e , msg) => {
                        console.log('error:', msg)
                        // this.$router.push("/error")
                    }
                })
            }
            reader.readAsDataURL(file)
        },
        onImageRemoveClick() {
            this.url = ''
            document.getElementById('uploader').value = ''
            this.$emit('remove')
        }
    },
}
</script>

<style scoped>
.wrap {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid #C4C4C4;
    cursor: pointer;
}
.wrap:hover {
    box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.1);
}

.upload-button input,
.wrap input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    width: 100%;
}
.upload-button input[type=file],
.upload-button input[type=file]::-webkit-file-upload-button,
.wrap input[type=file],
.wrap input[type=file]::-webkit-file-upload-button {
    cursor: pointer; 
}
.preview-default {
    width: 100%;
    height: 100%;
    padding: 12px;
}
.preview-default img {
    width: auto;
    height: 100%;
}
.card {
    width: 100%;
    height: 100%;
}
.overlay-wrapper {
    width: 100%;
    height: 100%;
    padding: 6px;
}
.overlay-wrapper img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.overlay-layer {
    background-color: rgba(0, 0, 0, 0.5);
}
.upload-button {
    position: relative;
    overflow: hidden;
}
</style>