import queryString from 'query-string'

import router from '@/router'

import { requestAdminInvite } from '@/api/admin'

export default async function  inviteAdmin ({ next, store }){
    const inviteData = queryString.parse(location.search)

    if(inviteData.code) {
        await requestAdminInvite({
            bot_id: inviteData.bot_id,
            formData: {
                url: inviteData.code
            },
            cbSuccess: res => {
                if(res.data.data.result) {
                    window.location.href = `${process.env.VUE_APP_API_END_POINT}/cms/api/line/login?url=${process.env.VUE_APP_REDIRECT_URL}/&flag=invite&code=${res.data.data.id}&bot_id=${inviteData.bot_id}`
                }
                else {
                    router.push('/link-error')
                }
            } ,
            cbError: () => {
                this.$toast.error('ระบบขัดข้อง')
                // this.$router.push("/error")
            }
        })
    }
    else {
        router.push('/error')
    }    
}