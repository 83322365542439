<template>
    <div :id="element"></div>
</template>

<script>
var Highcharts = require('highcharts')

const primary = '#6993FF'
const success = '#1BC5BD'
const info = '#115ECF'
const warning = '#FFA800'
const danger = '#F64E60'
export default {
    props: ['title', 'series', 'categories', 'element', 'seriesName'],
    computed: {
        options() {
            return {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie',
                    width: 500,
                    height: 380,
                },
                credits: {
                    enabled: false,
                },
                title: {
                    text: this.title
                },
                tooltip: {
                    pointFormat: ': <b>{point.percentage:.1f}%</b>'
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                        }
                    }
                },
                series: [{
                    name: this.title,
                    colorByPoint: true,
                    data: this.series
                }]
            }
        }
    },
    mounted() {
        this.target = Highcharts.chart(this.$el, this.options)
    },
    beforeDestroy() {
        this.target.destroy()
    }
}
</script>