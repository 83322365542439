<template>
    <div class="radio-list" :id="name">
        <label class="radio" v-for="(item, index) in list" :key="index">
            <input
                type="radio"
                :value="item.value"
                :name="name"
                :checked="item.value === value ? true : false"
                @change="$emit('change')"
            /> {{ item.label }}
            <span></span>
        </label>
    </div>
</template>

<script>
export default {
    props: ['name', 'value', 'list'],
    mounted() {
        $(`input[name="${this.name}"]`).change(() => {
            const value = $(`input[name="${this.name}"]:checked`).val()
            this.$emit('input', value)
        })
    },
}
</script>