var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fluid-content',{attrs:{"currentPage":"จัดการสิทธิ์ผู้ดูแลระบบ"}},[(_vm.loading)?_c('loading'):_vm._e(),_c('content-card',{attrs:{"title":"จัดการสิทธิ์ผู้ดูแลระบบ"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","width":"24px","height":"24px","viewBox":"0 0 24 24","version":"1.1"}},[_c('g',{attrs:{"stroke":"none","stroke-width":"1","fill":"none","fill-rule":"evenodd"}},[_c('polygon',{attrs:{"points":"0 0 24 0 24 24 0 24"}}),_c('path',{attrs:{"d":"M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z","fill":"#000000","fill-rule":"nonzero","opacity":"0.3"}}),_c('path',{attrs:{"d":"M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z","fill":"#000000","fill-rule":"nonzero"}})])])]},proxy:true},{key:"right",fn:function(){return [_c('button',{staticClass:"btn btn-outline-secondary",attrs:{"type":"button"},on:{"click":_vm.showTip}},[_vm._v("Tips")])]},proxy:true}])},[_c('div',{staticClass:"p-8"},[_c('div',{staticClass:"mb-7 w-100"},[_c('add-link',{attrs:{"to":"/loyalty/admin/form","label":"เพิ่มสิทธิ์ผู้ดูแลระบบ"}})],1),_c('div',{staticClass:"mb-7"},[_c('data-table',{attrs:{"headers":_vm.headers,"data":_vm.tableData,"filter":_vm.formData},on:{"change":_vm.onTableChange},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s((_vm.tableData.rows.map(function(x) {return x.id; }).indexOf(item.id) + 1) + _vm.formData.offset)+" ")])]}},{key:"item.line_img_url",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"symbol"},[_c('img',{attrs:{"alt":"Pic","src":item.line_img_url || _vm.defaultImg},on:{"error":_vm.replaceByDefault}})])]}},{key:"item.line_display_name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.line_display_name || '-'))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.mid !== _vm.userProfile.mid)?_c('div',{staticClass:"d-flex"},[_c('button',{staticClass:"btn btn-danger btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteStaffModalClick(item.id)}}},[_c('i',{staticClass:"far fa-trash-alt mr-1"}),_vm._v(" Delete ")])]):_vm._e()]}},{key:"item.role_id",fn:function(ref){
var item = ref.item;
return [(item.role_id === '1')?_c('span',[_vm._v("แอดมิน")]):(item.role_id === '2')?_c('span',[_vm._v("ให้คะแนนสมาชิก")]):_c('span',[_vm._v("รายงาน")])]}}])})],1)])]),_c('div',{staticClass:"modal fade",attrs:{"id":"confirmDelete","tabindex":"-1","role":"dialog","aria-labelledby":"confirmDelete","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered modal-sm",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-body"},[_c('p',{staticClass:"text-dark-75 font-size-h3 font-weight-bolder"},[_vm._v("Are you sure?")]),_c('p',{staticClass:"text-dark-50 font-size-sm"},[_vm._v("You won't be able to revert this!")])]),_c('div',{staticClass:"modal-footer p-1"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v("cancel")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.deleteStaff}},[_vm._v("Yes, delete it!")])])])])]),_c('div',{staticClass:"modal fade",attrs:{"id":"tips","tabindex":"-1","role":"dialog","aria-labelledby":"staticBackdrop","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header py-3 px-6"},[_c('h5',{staticClass:"modal-title text-dark-75 font-weight-medium",attrs:{"id":"tips"}},[_vm._v("Tips")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('i',{staticClass:"ki ki-close",attrs:{"aria-hidden":"true"}})])]),_c('div',{staticClass:"modal-body",staticStyle:{"max-height":"660px"}},[_c('img',{staticClass:"w-100",attrs:{"src":require("@/assets/media/project-images/tips/จัดการสิทธิ์ผู้ดูแลระบบ-01.png"),"alt":"tips"}})])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }