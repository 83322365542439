import Vue from 'vue'
import { Plugin } from 'vue-fragment'
import CKEditor from '@ckeditor/ckeditor5-vue'
import VJsoneditor from 'v-jsoneditor'
import Toast from 'vue-toastification'
import VueCookies from 'vue-cookies'
import VueClipboard from 'vue-clipboard2'
import 'vue-toastification/dist/index.css'
import vuetify from '@/plugins/vuetify'
import 'vuetify/dist/vuetify.min.css'

import App from './App.vue'
import router from './router'
import store from './store'
import '@/components/global'


import "@/styles/index.scss";

const optionsToast = {
    timeout: 2000,
}

Vue.config.productionTip = false
Vue.use(Plugin)
Vue.use(CKEditor)
Vue.use(VJsoneditor)
Vue.use(VueClipboard)
Vue.use(Toast, optionsToast)
Vue.use(VueCookies)

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware

    const context = {
        to,
        from,
        next,
        store
    }
    return middleware[0]({
        ...context
    })
})


new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
