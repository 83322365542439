<template>
    <div class="color-input-wrap">
        <div class="btn-group" role="group" aria-label="Basic example">
            <div class="btn btn-outline-secondary btn-xs" style="max-width: 40px;">
                <div class="color-display" :style="`background-color: ${value}`"></div>
            </div>
            <div class="btn btn-outline-secondary btn-xs px-2">
                <span>{{ value }}</span>
                <i class="fas fa-angle-down ml-2"></i>
            </div>
        </div>
        <input
            type="color"
            :value="value"
            @change="onSelectColor"
        />
    </div>
</template>

<script>
export default {
    props: ['value'],
    methods: {
        onSelectColor(e) {
            this.$emit('input', e.target.value)
        },
    },
    mounted() {
        
    },
}
</script>