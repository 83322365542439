<template>
    <no-nav :hideFooter="true" :hideBarDetail="true" :hideBrand="true">
        <div class="wrap">
            <div class="text-dark font-size-h1 font-weight-bolder">ไม่พบหน้าที่คุณค้นหา</div>
        </div>
    </no-nav>
</template>

<script>
    export default {
        name : "NotFound"
    }
</script>

<style scoped>
.wrap {
    color: #7c7a7a;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.logo {
    width: 130px;
    margin-bottom: 24px;
}
</style>
