<template>
    <div class="header-mobile-wrap">
        <div class="header">
            <div>
                <button type="button" @click.stop="toggleSidebarMobile">
                    <i class="la la-navicon icon-3x"></i>
                </button>
            </div>
            <div>
                <router-link to="/loyalty/home" class="d-flex align-items-center">
                    <div class="d-flex flex-column text-right mr-5">
                        <span class="font-size-lg text-dark-75 mb-1">{{userProfile.display_name}}</span>
                    </div>
                    <div class="symbol symbol-circle ">
                        <img alt="Pic" :src="userProfile.display_image ? userProfile.display_image : 'https://cdn.yellow-idea.com/images/line-modular/5214c420-3b05-11eb-a542-036b9cbd1e78.jpeg'"/>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        const profile = $cookies.get("ADMIN_PROFILE_DATA")
        return {
            userProfile : profile || {},
        }
    },
    methods: {
        toggleSidebarMobile() {
            this.$store.commit("TOGGLE_SIDEBAR_MOBILE" , true)
        }
    },
}
</script>
<style scoped>
.header-mobile-wrap {
    box-shadow: 3px 2px 4px rgba(0, 0, 0, 0.2) !important;
    height: 60px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    background-color: #FFF;
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 0 12px;
}
</style>
