<template>
	<div class="d-flex align-items-center">
		<div
			class="upload-image-preview"
			:class="{'preview-large': previewSize === 'large'}"
			v-if="value"
		>
			<img :src="value">
		</div>
		<div class="uppy">
			<button v-if="!value" class="uppy-btn btn btn-light-success btn-xs btn-bold" ref="uppyBtn">{{ label ? label : 'Add' }}</button>
			<div v-else class="btn btn-light-danger btn-xs btn-bold" @click="onRemoveClick">Remove</div>
			<div class="uppy-dashboard" ref="uppyDashboard"></div>
			<div class="uppy-progress" ref="uppyProgress"></div>
		</div>
	</div>
</template>

<script>
import { requestUploadImage } from '@/api/apis'

export default {
	props: ['maxFileSize', 'maxNumberOfFiles', 'fileType', 'label', 'value', 'previewSize'],
	data() {
		return {
			loading: false,
		}
	},
    mounted() {
		this.init()
	},
	updated() {
		if(!this.value) {
			this.init()
		}
	},
	methods: {
		onRemoveClick() {
			this.$emit("input", '')
		},
		init() {
			const XHRUpload = Uppy.XHRUpload
			const Dashboard = Uppy.Dashboard;
			const Webcam = Uppy.Webcam;
			
			
			var options = {
				proudlyDisplayPoweredByUppy: false,
				target: this.$refs.uppyDashboard,
				inline: false,
				replaceTargetContent: true,
				showProgressDetails: true,
				note: 'No filetype restrictions.',
				height: 470,
				metaFields: [],
				browserBackButtonClose: true,
				trigger: this.$refs.uppyBtn,
				closeModalOnClickOutside: true,
				hideUploadButton: true,
				hideRetryButton: true,
				hidePauseResumeButton: true,
				hideProgressAfterFinish: true,
				hideCancelButton: true,

			}

			var uppyDashboard = Uppy.Core({
				autoProceed: true,
				restrictions: {
					maxFileSize: this.maxFileSize,
					maxNumberOfFiles: this.maxNumberOfFiles,
					minNumberOfFiles: 1,
					allowedFileTypes: this.fileType
				}
			});

			uppyDashboard.use(Dashboard, options);
			uppyDashboard.use(Webcam, { target: Dashboard });

			uppyDashboard.on('file-removed', (file, reason) => {
				this.$emit("input", '')
			})

			uppyDashboard.on('file-added', file => {
				this.loading = true
				let reader = new FileReader()
				reader.onload = async (event) => {
					await requestUploadImage({
						formData : {
							img_url: event.target.result,
						},
						cbSuccess : res => {
							this.$emit('input', res.data.img_url)
						} ,
						cbError : (_e , msg) => {
							console.log('error:', msg)
							// this.$router.push("/error")
						}
					})
				};
				reader.readAsDataURL(file.data)
			})
		},
	}
}
</script>

<style scoped>
	.upload-image-preview {
        margin-right: 8px;
        width: 56px;
        height: 26px;
	}
	.upload-image-preview.preview-large {
		width: 130px;
        height: 110px;
	}
    .upload-image-preview img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
</style>