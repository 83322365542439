<template>
    <div>
        <span class="switch switch-primary switch-sm" :class="{'disable': !value}">
            <label class="mb-0">
                <input type="checkbox" name="select" :checked="value" @change="onSwitchChange" />
                <span></span>
            </label>
        </span>
    </div>
</template>

<script>
export default {
    props: ['value'],
    methods: {
        onSwitchChange(e) {
            this.$emit('input', e.target.checked)
        }
    }
}
</script>

<style scoped>
.switch.disable span::before{
    background-color: rgba(0, 0, 0, 0.2) !important;
}
</style>