<template>
    <select
        class="selectpicker"
        :title="placeholder"
        v-model="selected"
        :value="value"
        :class="{'is-invalid': formDirty && !value, 'is-valid': formDirty && value }"
        :style="`width: ${width ? width : 'auto'}`"
        :disabled="disabled"
    >
        <option v-for="(option, index) in options" :key="index+option.value" :value="option.value">{{ option.label }}</option>
    </select>
</template>

<script>
export default {
    props: ['options', 'placeholder', 'value', 'formDirty', 'width', 'disabled'],
    computed: {
        selected: {
            get() {return this.value},
            set(v) {this.$emit('input', v)}
        }
    },
    mounted() {
        $('.selectpicker').selectpicker()
    },
    updated() {
        $('.selectpicker').selectpicker("refresh")
    }
}
</script>

<style>
.dropdown.bootstrap-select {
    width: 100% !important;
}
.dropdown-menu {
    max-height: 200px;
}
</style>