<template>
    <fluid-content currentPage="จัดการร้านค้า">
        <loading v-if="loading"/>
        <content-card title="จัดการร้านค้า">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <path d="M12,4.56204994 L7.76822128,9.6401844 C7.4146572,10.0644613 6.7840925,10.1217854 6.3598156,9.76822128 C5.9355387,9.4146572 5.87821464,8.7840925 6.23177872,8.3598156 L11.2317787,2.3598156 C11.6315738,1.88006147 12.3684262,1.88006147 12.7682213,2.3598156 L17.7682213,8.3598156 C18.1217854,8.7840925 18.0644613,9.4146572 17.6401844,9.76822128 C17.2159075,10.1217854 16.5853428,10.0644613 16.2317787,9.6401844 L12,4.56204994 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                        <path d="M3.28077641,9 L20.7192236,9 C21.2715083,9 21.7192236,9.44771525 21.7192236,10 C21.7192236,10.0817618 21.7091962,10.163215 21.6893661,10.2425356 L19.5680983,18.7276069 C19.234223,20.0631079 18.0342737,21 16.6576708,21 L7.34232922,21 C5.96572629,21 4.76577697,20.0631079 4.43190172,18.7276069 L2.31063391,10.2425356 C2.17668518,9.70674072 2.50244587,9.16380623 3.03824078,9.0298575 C3.11756139,9.01002735 3.1990146,9 3.28077641,9 Z M12,12 C11.4477153,12 11,12.4477153 11,13 L11,17 C11,17.5522847 11.4477153,18 12,18 C12.5522847,18 13,17.5522847 13,17 L13,13 C13,12.4477153 12.5522847,12 12,12 Z M6.96472382,12.1362967 C6.43125772,12.2792385 6.11467523,12.8275755 6.25761704,13.3610416 L7.29289322,17.2247449 C7.43583503,17.758211 7.98417199,18.0747935 8.51763809,17.9318517 C9.05110419,17.7889098 9.36768668,17.2405729 9.22474487,16.7071068 L8.18946869,12.8434035 C8.04652688,12.3099374 7.49818992,11.9933549 6.96472382,12.1362967 Z M17.0352762,12.1362967 C16.5018101,11.9933549 15.9534731,12.3099374 15.8105313,12.8434035 L14.7752551,16.7071068 C14.6323133,17.2405729 14.9488958,17.7889098 15.4823619,17.9318517 C16.015828,18.0747935 16.564165,17.758211 16.7071068,17.2247449 L17.742383,13.3610416 C17.8853248,12.8275755 17.5687423,12.2792385 17.0352762,12.1362967 Z" fill="#000000"/>
                    </g>
                </svg>
            </template>
            <template v-slot:right>
                <button type="button" class="btn btn-outline-secondary" @click="showTip">Tips</button>
            </template>
            <div class="p-8">
                <tab
                    navStyleClass="nav-tabs nav-tabs-line nav-bolder nav-tabs-line-2x mb-6"
                    :items="tabMenus"
                    v-model="tabActive"
                />
                <div class="mb-7 w-100">
                    <add-link to="/loyalty/shop/banner/form" label="สร้างแบนเนอร์" />
                </div>
                <div class="mb-7">
                    <data-table
                        :headers="headers"
                        :data="tableData"
                        :filter="formData"
                        @change="onTableChange"
                    >
                        <template v-slot:item.index="{ item }">
                            <span>
                                {{(tableData.rows.map(function(x) {return x.id; }).indexOf(item.id) + 1)  + formData.offset}}
                            </span>
                        </template>
                        <template v-slot:item.is_active="{ item }">
                            <switch-toggle :value="parseInt(item.is_active)" @input="(value) => setItemActive(item.id, value)"/>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <div class="d-flex">
                                <router-link :to="`/loyalty/shop/banner/form?id=${item.id}`" class="btn btn-info btn-sm mr-2">
                                    <i class="far fa-edit mr-1"></i>
                                    Edit
                                </router-link>
                                <button type="button" class="btn btn-danger btn-sm" @click="deleteStaffModalClick(item.id)">
                                    <i class="far fa-trash-alt mr-1"></i>
                                    Delete
                                </button>
                            </div>
                        </template>
                        <template v-slot:item.seq_action="{ item }">
                            <button
                                type="button"
                                class="mr-4 btn btn-icon btn-outline-success btn-pill"
                                @click="onUpClick(item.id)"
                                :disabled="tableData.rows.map(function(x) {return x.id; }).indexOf(item.id) === 0"
                            >
                                <i class="ki ki-bold-arrow-up"></i>
                            </button>
                            <button
                                type="button"
                                class="btn btn-icon btn-outline-success btn-pill"
                                @click="onDownClick(item.id)"
                                :disabled="tableData.rows.map(function(x) {return x.id; }).indexOf(item.id) + 1 === tableData.rows.length"
                            >
                                <i class="ki ki-bold-arrow-down"></i>
                            </button>
                        </template>
                    </data-table>
                </div>
            </div>
        </content-card>
        <!-- Modal Confirm -->
        <div class="modal fade" id="confirmDelete" tabindex="-1" role="dialog" aria-labelledby="confirmDelete" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <p class="text-dark-75 font-size-h3 font-weight-bolder">Are you sure?</p>
                        <p class="text-dark-50 font-size-sm">You won't be able to revert this!</p>
                    </div>
                    <div class="modal-footer p-1">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">cancel</button>
                        <button type="button" class="btn btn-primary" @click="deleteStaff">Yes, delete it!</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal-->
        <div class="modal fade" id="tips" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header py-3 px-6">
                        <h5 class="modal-title text-dark-75 font-weight-medium" id="tips">Tips</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <i aria-hidden="true" class="ki ki-close"></i>
                        </button>
                    </div>
                    <div class="modal-body" style="max-height: 660px;">
                        <img class="w-100" src="@/assets/media/project-images/tips/แบนเนอร์-01.png" alt="tips">
                    </div>
                </div>
            </div>
        </div>
    </fluid-content>
</template>

<script>
import { requestBannerList, requestBannerDelete, requestBannerUpdateSeq, requestBannerUpdate } from "@/api/shop"

export default {
    data() {
        const profile = $cookies.get("ADMIN_PROFILE_DATA")
        return {
            userProfile : profile || {},
            loading: false,
            activeId: 0,
            formData: {
                sort: "",
                order: "asc",
                offset: 0,
                limit: 10,
                filter: {},
            },
            tableData: {},
            headers: [
                {
                    text: '#',
                    align: 'center',
                    value: 'index',
                    width: 50,
                    sortable: false,
                },
                {
                    text: 'รายการ',
                    align: 'start',
                    value: 'name',
                    width: 250,
                },
                {
                    text: 'สถานะ',
                    value: 'is_active',
                    width: 130,
                },
                {
                    text: 'การจัดการ',
                    value: 'actions',
                    sortable: false,
                    width: 130,
                },
                {
                    text: '',
                    align: 'center',
                    value: 'seq_action',
                    width: 50,
                    sortable: false,
                },
            ],
            tabActive : "แบนเนอร์" ,
            tabMenus: [
                {
                    name: 'ข้อมูลร้านค้า',
                    link: '/loyalty/shop',
                },
                {
                    name: 'แบนเนอร์',
                    link: '/loyalty/shop/banner',
                },
                {
                    name: 'คะแนน',
                    link: '/loyalty/shop/point'
                }
            ],
        }
    },
    async mounted() {
        if(this.userProfile.role_id === '1') {
            await this.getAll()
        }
        else {
            this.$router.push('/404')
        }
    },
    methods: {
        onTableChange(offset, limit) {
            this.formData.offset = offset
            this.formData.limit = limit
            this.getAll()
        },
        async onDownClick(id) {
            this.loading = true
            await requestBannerUpdateSeq({
                bot_id: this.userProfile.bot_id,
                id,
                formData : {
                    move: 'down',
                } ,
                cbSuccess : res => {
                    this.$toast.success("อัพเดทเรียบร้อย!")
                    this.getAll()
                    this.loading = false
                } ,
                cbError : (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        },
        async onUpClick(id) {
            this.loading = true
            await requestBannerUpdateSeq({
                bot_id: this.userProfile.bot_id,
                id,
                formData : {
                    move: 'up',
                } ,
                cbSuccess : res => {
                    this.$toast.success("อัพเดทเรียบร้อย!")
                    this.getAll()
                    this.loading = false
                } ,
                cbError : (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        },
        async getAll() {
            this.loading = true
            await requestBannerList({
                bot_id: this.userProfile.bot_id,
                formData: this.formData,
                cbSuccess: res => {
                    this.tableData = res.data
                    this.loading = false
                } ,
                cbError: (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        },
        deleteStaffModalClick(id) {
            this.activeId = id
            $('#confirmDelete').modal('show')
        },
        async deleteStaff() {
            await requestBannerDelete({
                bot_id: this.userProfile.bot_id,
                id : this.activeId,
                cbSuccess : res => {
                    this.$toast.success("Delete Success!")
                    $('#confirmDelete').modal('hide')
                    this.getAll()
                    this.loading = false
                } ,
                cbError : (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        },
        async setItemActive(id, value) {
            this.loading = true
            await requestBannerUpdate({
                bot_id: this.userProfile.bot_id,
                id,
                formData : {
                    is_active: value ? '1' : '0',
                } ,
                cbSuccess : res => {
                    this.$toast.success("อัพเดทเรียบร้อย!")
                    this.getAll()
                    this.loading = false
                } ,
                cbError : (e , msg) => {
                    this.loading = false
                    this.$toast.error('ระบบขัดข้อง')
                    // this.$router.push("/error")
                }
            })
        },
        showTip() {
            $('#tips').modal('show')
        },
    },
}
</script>