import axios from "axios"
import { getToken } from "../helper"

export const service = axios.create({
    baseURL: process.env.VUE_APP_API_END_POINT,
    // timeout : 10000
})

service.interceptors.request.use(
    config => {
        const profile = $cookies.get("ADMIN_PROFILE_DATA")
        config.headers['YR-mid'] =  profile ? profile.mid : ''
        config.headers['YR-role'] =  'admin'
        config.headers['YR-token'] =  getToken(config.url, config.data)
        return config
    } ,
    error => {
        console.log(error)
        return Promise.reject(error)
    }
)

export const serviceNodeRed = axios.create({
    baseURL: process.env.VUE_APP_API_NODE_RED,
    timeout : 5000
})

serviceNodeRed.interceptors.request.use(
    config => {
        return config
    } ,
    error => {
        console.log(error)
        return Promise.reject(error)
    }
)