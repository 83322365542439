import { serviceNodeRed, service } from "./request-index"

const requestAll = async(instance , { cbSuccess , cbError }) => {
    try {
        const res = await instance
        if( res.status === 200 ) {
            cbSuccess(res)
        } else {
            cbError(res , "Error !")
        }
    } catch( e ) {
        console.log(e)
        cbError(null , e)
    }
}

export const requestUserReport = ({ bot_id, formData, cbSuccess , cbError }) => requestAll(
    service.post(`/cms/api/v1/${bot_id}/report/user`, formData) ,
    { cbSuccess , cbError }
)

export const requestUserReportExport = ({ bot_id, formData, cbSuccess , cbError }) => requestAll(
    service.post(`/cms/api/v1/${bot_id}/report/user/export`, formData) ,
    { cbSuccess , cbError }
)

export const requestCouponHistoryReport = ({ bot_id, formData, cbSuccess , cbError }) => requestAll(
    service.post(`/cms/api/v1/${bot_id}/report/point-usage`, formData) ,
    { cbSuccess , cbError }
)

export const requestCouponHistoryReportExport = ({ bot_id, formData, cbSuccess , cbError }) => requestAll(
    service.post(`/cms/api/v1/${bot_id}/report/point-usage/export`, formData) ,
    { cbSuccess , cbError }
)

export const requestCouponReport = ({ bot_id, formData, cbSuccess , cbError }) => requestAll(
    service.post(`/cms/api/v1/${bot_id}/report/coupon`, formData) ,
    { cbSuccess , cbError }
)

export const requestCouponReportExport = ({ bot_id, formData, cbSuccess , cbError }) => requestAll(
    service.post(`/cms/api/v1/${bot_id}/report/coupon/export`, formData) ,
    { cbSuccess , cbError }
)

export const requestPointReport = ({ bot_id, formData, cbSuccess , cbError }) => requestAll(
    service.post(`/cms/api/v1/${bot_id}/report/user-point`, formData) ,
    { cbSuccess , cbError }
)

export const requestPointReportExport = ({ bot_id, formData, cbSuccess , cbError }) => requestAll(
    service.post(`/cms/api/v1/${bot_id}/report/user-point/export`, formData) ,
    { cbSuccess , cbError }
)