import { service } from "./request-index"

const requestAll = async(instance , { cbSuccess , cbError }) => {
    try {
        const res = await instance
        if( res.status === 200 ) {
            cbSuccess(res)
        } else {
            cbError(res , "Error !")
        }
    } catch( e ) {
        console.log(e)
        cbError(null , e)
    }
}

export const requestUserPointList = ({bot_id, formData, cbSuccess , cbError }) => requestAll(
    service.post(`/cms/api/v1/${bot_id}/user-point/list`, formData) ,
    { cbSuccess , cbError }
)

export const requestUserPointCreate = ({bot_id, formData, cbSuccess , cbError }) => requestAll(
    service.post(`/cms/api/v1/${bot_id}/user-point`, formData) ,
    { cbSuccess , cbError }
)

