<template>
    <div class="responsive-table">
        <div class="table-wrap" :style="`min-width: ${minWidth ? minWidth : '1000px'};`">
            <v-data-table
                :headers="headers"
                :items="data.rows"
                :page.sync="paginate.page"
                :items-per-page="filter.limit"
                hide-default-footer
                @page-count="paginate.pageCount = $event"
                :mobile-breakpoint="0"
            >
                <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
                    <slot :name="name" v-bind="slotData" />
                </template>
            </v-data-table>
        </div>
        <div class="d-flex align-items-center justify-content-between" :style="`min-width: ${minWidth ? minWidth : '1000px'};`">
            <v-pagination
                v-model="paginate.page"
                :length="paginationLength"
            />
            <div class="d-flex align-items-center">
                <div style="min-width: 100px; margin-right: 28px;">Rows per page:</div>
                <v-select
                    @change="onItemsPerPageChange"
                    :items="items"
                    v-model="paginate.itemsPerPage"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['headers', 'data', 'filter', 'minWidth'],
    data() {
        return {
            items: [5, 10, 20, 50, 100],
            paginate: {
                page: 1,
                itemsPerPage: 10,
                pageCount: 0,
                offset: 0,
            },
        }
    },
    computed: {
        paginationLength() {
            if(this.data.total) {
                const result = Math.ceil(this.data.total / this.filter.limit)
                return result
            }
        }
    },
    watch: {
        'paginate.page': function() {
            const offset = (this.paginate.page - 1) * this.paginate.itemsPerPage
            this.paginate.offset = offset
            this.$emit('change', offset, this.paginate.itemsPerPage)
        },
    },
    methods: {
        onItemsPerPageChange() {
            if(this.paginate.page !== 1) {
                this.paginate.page = 1
            }
            else {
                this.$emit('change', this.paginate.offset, this.paginate.itemsPerPage)
            }
        }
    },
}
</script>

<style>
.table-wrap .symbol img {
    object-fit: contain;
}
.responsive-table {
    overflow: auto;
}
.table-wrap tbody>tr {
    vertical-align: top !important;
}
.table-wrap tbody>tr>td {
    padding: 18px 16px !important;
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .responsive-table {
        overflow: hidden;
    }
}
</style>