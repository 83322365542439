<template>
    <div ref="columnChart" class="w-100" />
</template>

<script>
const primary = '#6993FF'
const success = '#1BC5BD'
const info = '#115ECF'
const warning = '#FFA800'
const danger = '#F64E60'
export default {
    props: ['title', 'series', 'categories', 'yText'],
    computed: {
        options() {
            return {
                title: {
                    text: this.title,
                    align: 'center',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize:  '20px',
                        fontWeight:  'bold',
                        fontFamily:  undefined,
                        color:  '#263238'
                    },
                },
                series: this.series,
                chart: {
                    type: 'bar',
                    height: 300,
                    width: "100%",
                    toolbar: {
                        show: false,
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                    },
                },
                dataLabels: {
                    enabled: true,
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: this.categories,
                },
                yaxis: {
                    title: {
                        text: this.yText,
                        style: {
                            fontSize:  '12px',
                            fontWeight:  'normal',
                            fontFamily:  undefined,
                            color:  '#263238'
                        },
                    }
                },
                fill: {
                    opacity: 1
                },
                colors: [primary, success, warning]
            }
        }
    },
    mounted() {
        const apexChart = this.$refs.columnChart
        const chart = new ApexCharts(apexChart, this.options)
		chart.render()
    }
}
</script>