import { service, serviceNodeRed } from "./request-index"

const requestAll = async(instance , { cbSuccess , cbError }) => {
    try {
        const res = await instance
        if( res.status === 200 ) {
            cbSuccess(res)
        } else {
            cbError(res , "Error !")
        }
    } catch( e ) {
        console.log(e)
        cbError(null , e)
    }
}

export const requestUploadImage = ({ formData, cbSuccess , cbError }) => requestAll(
    service.post(`/cms/api/upload`, formData) ,
    { cbSuccess , cbError }
)

export const requestOAList = ({ id, cbSuccess , cbError }) => requestAll(
    service.get(`/cms/api/v1/oa/${id}`) ,
    { cbSuccess , cbError }
)

export const requestUserSearch = ({ bot_id, formData, cbSuccess , cbError }) => requestAll(
    service.post(`/cms/api/v1/${bot_id}/user/search`, formData) ,
    { cbSuccess , cbError }
)

export const requestReceiptVerify = ({ bot_id, formData, cbSuccess , cbError }) => requestAll(
    service.post(`/cms/api/v1/${bot_id}/receipt/verify`, formData) ,
    { cbSuccess , cbError }
)
